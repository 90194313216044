import {useState, useEffect} from 'react';
import {ConsoleLogger} from '../../../logger/ConsoleLogger';
import InternalQuoteHelper from '../../../services/InternalQuoteService';
import Loader from '../../shared/Loader';

const TabsStaff = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [loader, setLoader] = useState(false);
    const [linkName, setLinkName] = useState('Account');
    const [editPermssion, setEditPermission] = useState(false);

    const navClick = (link) => {
        setLinkName(link);
        props.handleNav(link);
    }

    console.log("TabsStaff.js");

    useEffect(() => {
		setLoader(true);
		InternalQuoteHelper.permissionCheck().then(response => {
			logger.info(`Response from permissionCheck: ` + JSON.stringify(response.data));
			if (Number(response.data.super) === 1) {
				setEditPermission(true);
			}
			if (Number(response.data.system) === 1) {
				setEditPermission(true);
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`Error getting permissionCheck: ${error}`);
			setLoader(false);
		})
	}, [])

    return (
        <div className="tab-nav cf">
            {loader ? <Loader /> : null}
            <a href className={`tab-opt ${linkName === 'Account' ? 'active' : ''} `} onClick={() => { navClick('Account') }}>
                <span>Account</span>
            </a>
            {editPermssion ?
            <>
            <a href className={`tab-opt ${linkName === 'AddUser' ? 'active' : ''} `} onClick={() => { navClick('AddUser') }}>
                <span>Add User</span>
            </a>
            <a href className={`tab-opt ${linkName === 'EditUser' ? 'active' : ''} `} onClick={() => { navClick('EditUser') }}>
                <span>Edit User</span>
            </a>
            </>
            : null}
        </div>
    );
}

export default TabsStaff;
