import axios from "./axios/customAxios";

const getAccountListData = async (isInvoice) => {
    const accountsURL = `${process.env.REACT_APP_API_URL}/getAccounts.php?isInvoice=${isInvoice}`;
    return await axios.get(accountsURL);
}

const getBillingDisputeList = async () => {
    const billingURL = `${process.env.REACT_APP_API_URL}/getBillingDisputes.php`;
    return await axios.get(billingURL);
}

const AccountListHelper = {
    getAccountListData,
    getBillingDisputeList
}

export default AccountListHelper;
