import { useState } from "react";
import style from "../Employee.module.css";
import CancelModal from "./CancelModal";
import { SelectComponent } from "../../components/SelectComponent";
import { InputComponent } from "../../components/InputComponent";
import StaffManagementService from "../../../services/StaffManagementService";
import { ConsoleLogger } from "../../../logger/ConsoleLogger";
import Loader from "../../shared/Loader";

const AddUser = () => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });;
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [cancelWindow, setCancelWindow] = useState(false);
    const [formValues, setFormValues] = useState({
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        status: "Active", // Default status could be set as required
        webAccess: {
            webUserAdmin: "0",
            troubleTickets: "0",
            quote: "0",
            order: "0",
            invoiceSystem: "0",
            generalTools: "0",
            staffOrder: "0",
            bulkAV: "0",
            profile: "0"
        },
        roleType: "0"
    });

    const webAccessOptions = [
        { label: 'No Access', value: 0 },
        { label: 'Read Only', value: 1 },
        { label: 'Write Access', value: 2 },
        { label: 'Full Access', value: 3 }
    ];

    const addCallback = (e) => {
        const { name, value } = e.target;
        if (name in formValues.webAccess) {
            setFormValues({
                ...formValues,
                webAccess: { ...formValues.webAccess, [name]: value }
            });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }
    };

    const submitAction = () => {
        const validation = ValidationRule();
        if (Object.keys(validation).length === 0) {
            const submitJSON = {
                action: "add",
                userId: formValues.userId,
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                email: formValues.email,
                phoneNumber: formValues.phoneNumber,
                status: formValues.status,
                webAccess: {
                    webUserAdmin: formValues.webAccess.webUserAdmin,
                    troubleTickets: formValues.webAccess.troubleTickets,
                    quote: formValues.webAccess.quote,
                    order: formValues.webAccess.order,
                    invoiceSystem: formValues.webAccess.invoiceSystem,
                    generalTools: formValues.webAccess.generalTools,
                    staffOrder: formValues.webAccess.staffOrder,
                    bulkAV: formValues.webAccess.bulkAV,
                    profile: formValues.webAccess.profile
                },
                roleType: formValues.roleType
            };

            console.log("Submitting JSON for Add User: ", JSON.stringify(submitJSON, null, 2));
            setLoader(true);
            StaffManagementService.getUserData(submitJSON)
                .then(response => {
                    if (response.data.result === "FAIL") {
                        const errorMessage = response.data.error || 'Failed to update user information.';
                        logger.error("Update failed: " + errorMessage);
                        setErrorMsg(errorMessage);
                    } else {
                        logger.info("Update successful: " + JSON.stringify(response.data));
                        const successMessage = response.data.update || 'User updated successfully!';
                        setSuccessMsg(successMessage);
                    }
                    setLoader(false);
                })
                .catch(error => {
                    console.error("Error adding user:", error);
                    setLoader(false);
                });
        }
    };

    const ValidationRule = () => {
        const error = {};
        if (!formValues.userId) {
            error.userId = "Employee ID is Required";
        }
        if (!formValues.firstName) {
            error.firstName = "First Name is Required";
        }
        if (!formValues.lastName) {
            error.lastName = "Last Name is Required";
        }
        if (!formValues.email) {
            error.email = "Email is Required";
        }
        if (!formValues.phoneNumber) {
            error.phoneNumber = "Phone Number is Required";
        }
        if (!formValues.status) {
            error.status = "Status is Required";
        }
        Object.keys(formValues.webAccess).forEach(key => {
            if (!formValues.webAccess[key]) {
                error[key] = `${key.replace(/([A-Z])/g, ' $1')} is Required`;
            }
        });
        setFormErrors(error);
        return error;
    };

    const cancelAction = () => {
        setCancelWindow(true);
    };

    const cancelEmit = (action) => {
        if (action === 'Ok') {
            setCancelWindow(false);
            setFormValues({
                userId: "",
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
                status: "Active",
                webAccess: {
                    webUserAdmin: "0",
                    troubleTickets: "0",
                    quote: "0",
                    order: "0",
                    invoiceSystem: "0",
                    generalTools: "0",
                    staffOrder: "0",
                    bulkAV: "0",
                    profile: "0"
                },
                roleType: "0"
            });
            setFormErrors({});
        } else {
            setCancelWindow(false);
        }
    };

    return (
        <div className="addUserPage">
            {loader ? <Loader /> : null}
            {errorMsg?.length > 0 ? <p className="text-danger text-center">{errorMsg}</p> : null}
            {successMsg.length ? <p className={`text-center text-success`}>{successMsg}</p> : null}
            <p className="text-secondary text-right mb-5"><span className={style.mandatory}>*</span> Denotes a required field</p>
            <div className="row">
                <div className="col-md-5 col-sm-12">
                    <InputComponent label='Employee Id' name="userId" className="w-100" value={formValues.userId} error={formErrors.userId} callback={addCallback} />
                    <div className="d-flex justify-content-between">
                        <InputComponent label='First Name' name="firstName" className={style.w45} value={formValues.firstName} error={formErrors.firstName} callback={addCallback} />
                        <InputComponent label='Last Name' name="lastName" className={style.w45} value={formValues.lastName} error={formErrors.lastName} callback={addCallback} />
                    </div>
                    <InputComponent label='Email' name="email" className="w-100" value={formValues.email} error={formErrors.email} callback={addCallback} />
                    <InputComponent label='Phone Number' name="phoneNumber" className="w-100" value={formValues.phoneNumber} error={formErrors.phoneNumber} callback={addCallback} />

                    <div className="my-5">
                        <label className="mr-5">Status <span className={` ${style.mandatory} `}>*</span></label>
                        <input type="radio" name="status" id="active" value='Active' checked={formValues.status === 'Active'} onChange={addCallback}></input>
                        <label className="mr-4 text-secondary" htmlFor="active">Active</label>

                        <input type="radio" name="status" id="inActive" value='InActive' checked={formValues.status === 'InActive'} onChange={addCallback}></input>
                        <label className="text-secondary" htmlFor="inActive">InActive</label>
                        <p className={style.errorMsg}>{formErrors.status}</p>
                    </div>
                </div>

                <div className="col-md-7 col-sm-12">
                    <label className="d-block w-100 text-center mb-5">Web Access</label>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Web User Administration" name="webUserAdmin" className={style.w45} value={formValues.webAccess.webUserAdmin} options={webAccessOptions} error={formErrors.webUserAdmin} callback={addCallback} />
                        <SelectComponent label="Trouble Tickets" name="troubleTickets" className={style.w45} value={formValues.webAccess.troubleTickets} options={webAccessOptions} error={formErrors.troubleTickets} callback={addCallback} />
                    </div>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Transport Quote" name="quote" className={style.w45} value={formValues.webAccess.quote} options={webAccessOptions} error={formErrors.quote} callback={addCallback} />
                        <SelectComponent label="Transport Order" name="order" className={style.w45} value={formValues.webAccess.order} options={webAccessOptions} error={formErrors.order} callback={addCallback} />
                    </div>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Invoice System" name="invoiceSystem" className={style.w45} value={formValues.webAccess.invoiceSystem} options={webAccessOptions} error={formErrors.invoiceSystem} callback={addCallback} />
                        <SelectComponent label="General Tools" name="generalTools" className={style.w45} value={formValues.webAccess.generalTools} options={webAccessOptions} error={formErrors.generalTools} callback={addCallback} />
                    </div>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Staff Order" name="staffOrder" className={style.w45} value={formValues.webAccess.staffOrder} options={webAccessOptions} error={formErrors.staffOrder} callback={addCallback} />
                        <SelectComponent label="Bulk AV" name="bulkAV" className={style.w45} value={formValues.webAccess.bulkAV} options={webAccessOptions} error={formErrors.bulkAV} callback={addCallback} />
                    </div>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Profile" name="profile" className={style.w45} value={formValues.webAccess.profile} options={webAccessOptions} error={formErrors.profile} callback={addCallback} />
                        <SelectComponent label="Role Type" name="roleType" className={style.w45} value={formValues.roleType} options={webAccessOptions} error={formErrors.roleType} callback={addCallback} />
                    </div>
                </div>
            </div>

            <div className="text-center my-5">
                <button type="button" className={`ico-button lhs light-button ml-3 ${style.cancelBtn}`} onClick={cancelAction}>
                    Cancel
                </button>

                <button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn}`} onClick={submitAction}>
                    Submit
                </button>
            </div>
            {cancelWindow ? <CancelModal cancelEmit={cancelEmit} /> : null}
        </div>
    );
};

export default AddUser;