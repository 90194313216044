import style from './OrderCreate.module.css';
import React, { useEffect, useState } from "react";
import OrderHelper from '../../services/OrderService';
import { connect, useSelector } from "react-redux";


import {
	setOrderACustomerName,
	setOrderADemarcation,
	setOrderALocationNotes,
	setOrderAFiberType,
	setOrderAHandoff,
	setOrderALconEmail,
	setOrderALconName,
	setOrderALconPhone,
	setOrderZCustomerName,
	setOrderZDemarcation,
	setOrderZLocationNotes,
	setOrderZFiberType,
	setOrderZHandoff,
	setOrderZLconEmail,
	setOrderZLconName,
	setOrderZLconPhone
} from '../../redux/actions/orderCreateActions';
import Loader from '../shared/Loader';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const OrderSiteInfo = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const selectedQuotedData = useSelector(state => state.orderCreate.quotedData);
	const selectedOrderSiteInfoA = useSelector(state => state.orderCreate.locA);
	const selectedOrderSiteInfoZ = useSelector(state => state.orderCreate.locZ);
	const selectedOrderServiceInfo = useSelector(state => state.orderCreate.serviceInfo);
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const [orderHandoffOptions, setOrderHandoffOptions] = useState([]);
	const [orderHandoffOptionsZUni, setOrderHandoffOptionsZUni] = useState([]);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		getOrderHandoffOptions();
		getOrderHandoffOptionsZUni();
		setDemarcationPoint();
		if (!selectedOrderSiteInfoA.a_cp_name || !selectedOrderSiteInfoZ.z_cp_name) {
			props.updateOrderACustomerName(selectedQuotedData.bus_name);
			props.updateOrderZCustomerName(selectedQuotedData.bus_name);
		}
	}, []);


	const getOrderHandoffOptions = () => {
		setLoader(true);
		OrderHelper.getOrderHandoffs(selectedQuotedData.product_id, selectedQuotedData.circuit_id, selectedOrderServiceInfo.bandwidth, selectedQuotedData.num_routes, selectedQuotedData.ref_id, selectedQuotedData?.modemId).then(resp => {
			logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
			setOrderHandoffOptions(resp.data);
			//autoselect option if only 1 handoff type
			if (resp.data?.length === 1) {
				props.updateOrderAHandoff(resp.data[0].id);
			}
			setLoader(false);
		}).catch(error => {
			logger.error("Getting Order Handoff Options: " + error);
			setHasError(error);
			setLoader(false);
		})
	}

	const getOrderHandoffOptionsZUni = () => {
		// The handoffs for E-Line and E-Access services at Location Z are dependent on the UNI
		if(selectedQuotedData?.product_id === "19" || selectedQuotedData?.product_id === "20"){
			setLoader(true);
			OrderHelper.getOrderHandoffs(selectedQuotedData.product_id, selectedQuotedData.circuit_id, selectedOrderServiceInfo.bandwidth, selectedQuotedData.num_routes, selectedQuotedData.ref_id, selectedQuotedData?.modemId, "z").then(resp => {
				logger.info(encodeURIComponent(resp.data).replace(/%20/g, " "));
				setOrderHandoffOptionsZUni(resp.data);
				//autoselect option if only 1 handoff type
				if (resp.data?.length === 1) {
					props.updateOrderAHandoff(resp.data[0].id);
				}
				setLoader(false);
			}).catch(error => {
				logger.error("Getting Order Handoff Location Z Options: " + error);
				setHasError(error);
				setLoader(false);
			})
		}
	}

	const setDemarcationPoint = () => {
		switch (selectedQuotedData.product_id) {
			case "10":
				//Windstream Patch Panel
				props.updateOrderADemarcation("1");
				props.updateOrderZDemarcation("1");
				break;
			/*ELINE, EACCESS, MPLS TAIL, MPLS INTERNET*/
			case "19":
			case "20":
			case "6":
			case "13":
			case "11":
				//MPOE
				props.updateOrderADemarcation("3");
				props.updateOrderZDemarcation("3");
				break;
			case "7":
				//Windstream Edge Router Port
				props.updateOrderADemarcation("4");
				props.updateOrderZDemarcation("4");
				break;
			case "1":
			case "2":
			case "3":
				//Windstream CFA to be Provided
				props.updateOrderADemarcation("5");
				props.updateOrderZDemarcation("5");
				break
			case "35":
				//WIN CFA
				props.updateOrderADemarcation("0");
				props.updateOrderZDemarcation("0");
				break;
			default:
				break;
		}

	}

	const inputHandler = (ev) => {
		switch (ev.target.id) {
			case "a_cp_name":
				props.updateOrderACustomerName(ev.target.value);
				break;
			case "a_lcon_name":
				props.updateOrderALconName(ev.target.value);
				break;
			case "a_lcon_phone":
				props.updateOrderALconPhone(ev.target.value);
				break;
			case "a_lcon_email":
				props.updateOrderALconEmail(ev.target.value);
				break;
			case "a_handoff":
				props.updateOrderAHandoff(ev.target.value);
				break;
			case "a_dem_pt":
				props.updateOrderADemarcation(ev.target.value);
				break;
			case "a_remarks":
				props.updateOrderALocationNotes(ev.target.value);
				break;
			case "a_fiber_conn":
				props.updateOrderAFiberType(ev.target.value);
				break;
			case "z_cp_name":
				props.updateOrderZCustomerName(ev.target.value);
				break;
			case "z_lcon_name":
				props.updateOrderZLconName(ev.target.value);
				break;
			case "z_lcon_phone":
				props.updateOrderZLconPhone(ev.target.value);
				break;
			case "z_lcon_email":
				props.updateOrderZLconEmail(ev.target.value);
				break;
			case "z_handoff":
				props.updateOrderZHandoff(ev.target.value);
				break;
			case "z_dem_pt":
				props.updateOrderZDemarcation(ev.target.value);
				break;
			case "z_remarks":
				props.updateOrderZLocationNotes(ev.target.value);
				break;
			case "z_fiber_conn":
				props.updateOrderZFiberType(ev.target.value);
				break;
			default:
				break;
		}
	}
	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<><div className={style.section1}>
				<div className={`sub-hdr alt0 ${style.subHdrAlter} `}>
					<div className="nib">
						<div className="the-notch">
							<div className="notch-fg">
								<svg>
									<path
										d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
								</svg>
							</div>
						</div>
					</div>
					<h2 className={` ${style.sectionTitle} m-0 `}>Location A</h2>
				</div>

				<div className="sub-section">
					<div className={` row ${style.rowGrp}`}>
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Order Name<span className={style.mandatory}>*</span>
								<span className={`ml-2 ${style.infoTip}  `}>
									<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
									<p className={style.contentHover}>
										Order Name will be displayed in billing and portal
									</p>
								</span>
							</label>
							<input type='text' className={style.inputFld} id='a_cp_name' name='a_cp_name' maxLength='48' onChange={inputHandler} value={selectedOrderSiteInfoA.a_cp_name}></input>
						</div>
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Onsite Name (LCON)<span className={style.mandatory}>*</span></label>
							<input type='text' className={style.inputFld} id='a_lcon_name' name='a_lcon_name' maxLength='25' onChange={inputHandler} value={selectedOrderSiteInfoA.a_lcon_name}></input>
						</div>
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Onsite Number<span className={style.mandatory}>*</span></label>
							<input type='text' className={style.inputFld} id='a_lcon_phone' name='a_lcon_phone' maxLength='10' onChange={inputHandler} value={selectedOrderSiteInfoA.a_lcon_phone}></input>
						</div>
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Onsite Email<span className={style.mandatory}>*</span></label>
							<input type='text' className={style.inputFld} id='a_lcon_email' name='a_lcon_email' onChange={inputHandler} value={selectedOrderSiteInfoA.a_lcon_email}></input>
						</div>
						{/*Colaction or DIA PREM BROADBAND DOESN'T need handoffs*/}
						{selectedQuotedData.product_id === "15" || selectedQuotedData.circuit_id === "14" ?
							null :
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Handoff<span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id='a_handoff' name='a_handoff' onChange={inputHandler} value={selectedOrderSiteInfoA.a_handoff}>
									<option></option>
									{orderHandoffOptions.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}
								</select>
							</div>
						}
						{/*Fiber Connector Types for Waves (POP to POP, POP to PREM, PREM to PREM) and for certian handoff selections*/}
						{Number(selectedQuotedData.product_id) < 4 || (selectedQuotedData.circuit_id === "6" && (selectedOrderSiteInfoA.a_handoff === "6" || selectedOrderSiteInfoA.a_handoff === "7" || selectedOrderSiteInfoA.a_handoff === "8")) ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Fiber Connector Types<span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id='a_fiber_conn' name='a_fiber_conn' onChange={inputHandler} value={selectedOrderSiteInfoA.a_fiber_conn}>
									<option></option>
									<option value="FC">FC</option>
									<option value="LC">LC</option>
									<option value="SC">SC</option>
								</select>
							</div> : null
						}
						{/*Renewals don't need these fields*/}
						{selectedQuotedData?.q_cpt_activity !== "R" ?
							<>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Customer Address<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} id='a_cust_add' name='a_cust_add' value={selectedQuotedData.locA} disabled='disabled'></input>
								</div>
								{/*Colocation, EACCESS NNI, Wholesale Broadband don't have a Demarcation Point*/}
								{selectedQuotedData.product_id === "15" || selectedQuotedData.product_id === "22" || selectedQuotedData.product_id === "23" ?
									null :
									<>
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>Demarcation Point<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='a_dem_pt' name='a_dem_pt' onChange={inputHandler} value={selectedOrderSiteInfoA.a_dem_pt}>
												<option disabled></option>
												{/*DIA POP*/}
												{selectedQuotedData.product_id === "10" ?
													<option value="1">Windstream Patch Panel</option> : null}
												{/*ELINE, EACCESS, MPLS TAIL, MPLS INTERNET*/}
												{selectedQuotedData.product_id === "19" || selectedQuotedData.product_id === "20" || selectedQuotedData.product_id === "6" || selectedQuotedData.product_id === "13" || selectedQuotedData.product_id === "11" ?
													<option value="3">MPOE</option> : null}
												{/*MPLS NNI*/}
												{selectedQuotedData.product_id === "7" ?
													<option value="4"> Windstream Edge Router Port</option> : null}
												{/*WAVE (POP TO POP, POP TO PREM, PREM TO PREM*/}
												{Number(selectedQuotedData.product_id) < 4 ?
													<option value="5">Windstream CFA to be Provided</option> : null}
												{/*MANAGED SPECTRUM*/}
												{selectedQuotedData.product_id === "35" ?
													<option value="0">WIN CFA</option> : null}
											</select>
										</div>
									</>
								}
								{isEmployee && selectedQuotedData.product_id !== "23" ?
									<div className={`col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>Location A Notes</label>
										<textarea className={style.textAreaFld} id="a_remarks" name="a_remarks" onChange={inputHandler}>{selectedOrderSiteInfoA.a_remarks}</textarea>
									</div> : null
								}
							</> : null
						}
					</div>
				</div>
			</div>
				<div className={style.dividers}></div>
				{selectedQuotedData.locZ === "" ?
					null :
					<>
						<div className={style.section1}>
							<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
								<div className="nib">
									<div className="the-notch">
										<div className="notch-fg">
											<svg>
												<path
													d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
											</svg>
										</div>
									</div>
								</div>
								<h2 className={` ${style.sectionTitle} m-0 `}>Location Z</h2>
							</div>

							<div className="sub-section">
								<div className={` row ${style.rowGrp}`}>
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>Location Z Name<span className={style.mandatory}>*</span></label>
										<input type='text' className={style.inputFld} id='z_cp_name' name='z_cp_name' maxLength='48' onChange={inputHandler} value={selectedOrderSiteInfoZ.z_cp_name}></input>
									</div>
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>Onsite Name (LCON)<span className={style.mandatory}>*</span></label>
										<input type='text' className={style.inputFld} id='z_lcon_name' name='z_lcon_name' maxLength='25' onChange={inputHandler} value={selectedOrderSiteInfoZ.z_lcon_name}></input>
									</div>
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>Onsite Number<span className={style.mandatory}>*</span></label>
										<input type='text' className={style.inputFld} id='z_lcon_phone' name='z_lcon_phone' maxLength='10' onChange={inputHandler} value={selectedOrderSiteInfoZ.z_lcon_phone}></input>
									</div>
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>Onsite Email<span className={style.mandatory}>*</span></label>
										<input type='text' className={style.inputFld} id='z_lcon_email' name='z_lcon_email' onChange={inputHandler} value={selectedOrderSiteInfoZ.z_lcon_email}></input>
									</div>
									<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
										<label className={style.labelText}>Handoff<span className={style.mandatory}>*</span></label>
										<select className={style.selectFld} id='z_handoff' name='z_handoff' onChange={inputHandler} value={selectedOrderSiteInfoZ.z_handoff}>
											<option></option>
											{/*E-LINE and E-ACCESS*/}
											{selectedQuotedData.product_id === "19" || selectedQuotedData.product_id === "20" ?
												<>
													{orderHandoffOptionsZUni.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}
												</>
												:
												<>
													{orderHandoffOptions.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}
												</>
											}
										</select>
									</div>
									{/*Fiber Connector Types only for Waves and for certian handoff selections*/}
									{Number(selectedQuotedData.product_id) < 4 || (selectedQuotedData.circuit_id === "6" && selectedOrderSiteInfoZ.z_handoff === "6" && selectedOrderSiteInfoZ.z_handoff === "7" && selectedOrderSiteInfoZ.z_handoff === "8") ?
										<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
											<label className={style.labelText}>Fiber Connector Types<span className={style.mandatory}>*</span></label>
											<select className={style.selectFld} id='z_fiber_conn' name='z_fiber_conn' onChange={inputHandler} value={selectedOrderSiteInfoZ.z_fiber_conn}>
												<option></option>
												<option value="FC">FC</option>
												<option value="LC">LC</option>
												<option value="SC">SC</option>
											</select>
										</div> : null
									}
									{/*Renewals don't need these fields*/}
									{selectedQuotedData?.q_cpt_activity !== "R" ?
										<>
											<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
												<label className={style.labelText}>Customer Address<span className={style.mandatory}>*</span></label>
												<input type='text' className={style.inputFld} id='z_cust_add' name='z_cust_add' value={selectedQuotedData.locZ} disabled='disabled'></input>
											</div>
											<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
												<label className={style.labelText}>Demarcation Point<span className={style.mandatory}>*</span></label>
												<select className={style.selectFld} id='z_dem_pt' name='z_dem_pt' onChange={inputHandler} value={selectedOrderSiteInfoZ.z_dem_pt}>
													<option disabled></option>
													{selectedQuotedData.product_id === "10" ?
														<option value="1">Windstream Patch Panel</option> : null}
													{/*ELINE, EACCESS, MPLS TAIL, MPLS INTERNET*/}
													{selectedQuotedData.product_id === "19" || selectedQuotedData.product_id === "20" || selectedQuotedData.product_id === "6" || selectedQuotedData.product_id === "13" ?
														<option value="3">MPOE</option> : null}
													{/*WAVE (POP TO POP, POP TO PREM, PREM TO PREM*/}
													{Number(selectedQuotedData.product_id) < 4 ?
														<option value="5">Windstream CFA to be Provided</option> : null}
													{/*MANAGED SPECTRUM*/}
													{selectedQuotedData.product_id === "35" ?
														<option value="0">WIN CFA</option> : null}
												</select>
											</div>
											{isEmployee ?
												<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
													<label className={style.labelText}>Location Z Notes</label>
													<textarea className={style.textAreaFld} id="z_remarks" name="z_remarks" onChange={inputHandler}>{selectedOrderSiteInfoZ.z_remarks}</textarea>
												</div> : null
											}
										</> : null
									}
								</div>
							</div>
						</div>
						<div className={style.dividers}></div> </>}</>)}
		</>
	)
}

const mapDispatchToProps = {
	updateOrderACustomerName: setOrderACustomerName,
	updateOrderALconName: setOrderALconName,
	updateOrderALconPhone: setOrderALconPhone,
	updateOrderALconEmail: setOrderALconEmail,
	updateOrderAHandoff: setOrderAHandoff,
	updateOrderADemarcation: setOrderADemarcation,
	updateOrderALocationNotes: setOrderALocationNotes,
	updateOrderAFiberType: setOrderAFiberType,
	updateOrderZCustomerName: setOrderZCustomerName,
	updateOrderZLconName: setOrderZLconName,
	updateOrderZLconPhone: setOrderZLconPhone,
	updateOrderZLconEmail: setOrderZLconEmail,
	updateOrderZHandoff: setOrderZHandoff,
	updateOrderZDemarcation: setOrderZDemarcation,
	updateOrderZLocationNotes: setOrderZLocationNotes,
	updateOrderZFiberType: setOrderZFiberType
}


export default connect(null, mapDispatchToProps)(OrderSiteInfo);
