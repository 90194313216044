import {FETCH_OS_FAILURE, FETCH_OS_REQUEST, FETCH_OS_SUCCESS} from './types/orderSummaryType';
import Api from '../../services/Api';

const fetchOrderSummaryRequest = () => {
    return {
        type: FETCH_OS_REQUEST
    }
}

const fetchOrderSummarySuccess = (qs) => {
    return {
        type: FETCH_OS_SUCCESS,
        payload: qs
    }
}

const fetchOrderSummaryFailure = error => {
    return {
        type: FETCH_OS_FAILURE,
        payload: error
    }
}

export const fetchOrderSummary = () => {
    return (dispatch) => {
        dispatch(fetchOrderSummaryRequest)
        Api.fetchOrdered()
            .then((response) => {
                dispatch(fetchOrderSummarySuccess(response.data))
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchOrderSummaryFailure(errorMsg));
            });
    }
}

