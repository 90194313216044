import { useEffect, useState } from "react";
import StaffManagementService from "../../../services/StaffManagementService";
import { ConsoleLogger } from "../../../logger/ConsoleLogger";
import CancelModal from "./CancelModal";
import style from "../Employee.module.css";
import Loader from "../../shared/Loader";

const Input = ({ inputEmit, label, disabled, value, name }) => {
    const inputHandle = (e) => {
        inputEmit(e);
    };
    return (
        <div className="my-3">
            <label className="labelText"> {label}</label>
            <input
                type="text"
                name={name}
                className={`${style.inputFldText} w-100`}
                value={value}
                disabled={disabled}
                onChange={inputHandle}
            />
        </div>
    );
};

const Account = ({ formValues, setFormValues }) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [loader, setLoader] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
    const eid = localStorage.getItem("eid");

    const getJSON = {
        action: "get",
        uid: isEmployee ? eid : null,
    };

    console.log("Account.js");
    useEffect(() => {
        setLoader(true);
        StaffManagementService.getUserData(getJSON)
            .then((resp) => {
                logger.info("UserData returned: " + JSON.stringify(resp.data));
                setFormValues(resp.data);
                setLoader(false);
            })
            .catch((error) => {
                logger.error("StaffManagementService ERROR: " + error);
                setLoader(false);
                setErrorMsg(error.message || 'An error occurred');
            });
    }, []);

    return (
        <div className="staffPage">
            {loader ? <Loader /> : null}
            {errorMsg?.length > 0 ? <p className="text-danger text-center">{errorMsg}</p> : null}
            <div className={`sub-hdr alt0 mt-0`}>
                <h2 className={`m-0`}>User Information</h2>
            </div>
            <div className="row my-3 px-3">
                <div className="col-12 col-md-4">
                    <Input label="Login" name="login" disabled={true} value={formValues.userId || ''} />
                    <Input label="First Name" name="firstName" disabled={true} value={formValues.firstName || ''} />
                    <Input label="Last Name" name="lastName" disabled={true} value={formValues.lastName || ''} />
                </div>
                <div className="col-12 col-md-4">
                    <Input label="Email" name="email" disabled={true} value={formValues.email || ''}  />
                    <Input label="Role" name="role" disabled={true} value={formValues.roleName || ''} />
                </div>
            </div>
        </div>
    );
};

export default Account;