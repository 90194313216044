import axios from "./axios/customAxios";

const localURL = "iconnect-local.windstreamwholesale.com";
const devURL = "iconnect-dev.windstreamwholesale.com";

const getPOPList = async () => {
  const popURL = `${process.env.REACT_APP_API_URL}/POPs/popInfo.php?action=getPOPs`;
  return await axios.get(popURL);
}

const getPOPDetails = async (uid) => {
  const popURL = `${process.env.REACT_APP_API_URL}/POPs/popInfo.php?action=getPOPDetails&uid=${uid}`;
  return await axios.get(popURL);
}

const submitPopDetails = async (data, isClone) => {
	let popURL = "";
	if(window.location.hostname === localURL || window.location.hostname === devURL){
    if(isClone) {
      popURL = `${process.env.REACT_APP_API_URL}/POPs/clonePOP.php?data=${JSON.stringify(data)}`
    } else {
		  popURL = `${process.env.REACT_APP_API_URL}/POPs/updatePopDetails.php?data=${JSON.stringify(data)}`;
    }
		return await axios.get(popURL);
	} else {
    if(isClone) {
      popURL = `${process.env.REACT_APP_API_URL}/POPs/clonePOP.php`;
    } else {
		  popURL = `${process.env.REACT_APP_API_URL}/POPs/updatePopDetails.php`;
    }
		return await axios.post(popURL, JSON.stringify(data));
	}
}

/* maybe want to create a locations service and move this into there or keep separated for different tools . To be discussed ? */
const getStates = async () => {
  const stateURL = `${process.env.REACT_APP_API_URL}/POPs/popInfo.php?action=getStates`;
  return await axios.get(stateURL);
}

const POPServices = {
  getPOPList,
  getPOPDetails,
  submitPopDetails,
  getStates
}

export default POPServices;
