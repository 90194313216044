import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import POPServices from "../../services/POPServices";
import Loader from "../shared/Loader";
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import { Link } from "react-router-dom";
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import { Checkbox, FormControlLabel } from "@mui/material";
import style from "./POPViewer.module.css";
import XLSXExport from "../../utils/XLSXExport"; // Importing the export utility
import CustomToolbar from '../components/tables/CustomToolbar';

const POPViewer = () => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [popData, setPopData] = useState([]);
    const [exportData, setExportData] = useState([]); // This is with address broken up
    const [loader, setLoader] = useState(false);
    const [errorMSG, setErrorMSG] = useState('');
    const location = useLocation();
    const successMessage = location.state?.successMessage;
    const [pageSize, setPageSize] = useState(25);
    const [showAll, setShowAll] = useState(false); // State to toggle active/all records
    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    useEffect(() => {
        setLoader(true);
        POPServices.getPOPList().then((resp) => {
            setPopData(resp.data.popData);
            setExportData(resp.data.exportData);
            setLoader(false);
        })
            .catch(error => {
                setLoader(false);
                setErrorMSG("Error Getting POPs");
                logger.error("Error Getting POPs: " + error);
            });
    }, []);

    const columns = [
        {
            field: 'cswc',
            headerName: 'Building CLLI',
            flex: 1,
            renderCell: (params) => formatCellLink(params),
        },
        {
            field: 'address',
            headerName: 'Address',
            flex: 2
        },
        {
            field: 'provider',
            headerName: 'Provider',
            flex: 1
        },
        {
            field: 'icon',
            headerName: 'ICON Enabled',
            flex: 1
        },
        {
            field: 'wave',
            headerName: 'Wave Enabled',
            flex: 1
        },
        {
            field: 'colo',
            headerName: 'Colo Enabled',
            flex: 1
        },
        {
            field: 'eline_enet',
            headerName: 'Eline Enabled',
            flex: 1
        },
        // Conditionally include the 'Active' column
        ...(showAll ? [{
            field: 'status',
            headerName: 'Active',
            flex: 1,
        }] : [])
    ];

    function formatCellLink(params) {
        const uid = params.row.uid;
        const cswc = params.row.cswc;
        return (
            <Link to={`/POPEditor/${uid}`} style={{ color: "#753BBD" }}>
                {cswc}
            </Link>
        );
    }

    // Filter data based on the checkbox state
    const filteredData = showAll ? popData : popData.filter(row => row.status === 'Active');
    const exportFilteredData = showAll ? exportData : exportData.filter(row => row.status === 'Active');

    const handleExport = (format) => {
        const headers = ["UID", "Building CLLI", "Address", "Location Designator 2", "Location Value 2", "Location Designator 3", "Location Value 3", "City", "State", "Zip", "Provider", "Lata", "Latitude", "Longitude", "XC Required", "ICON Enabled", "Wave Enabled", "Colo Enabled", "Eline Enabled", "Active"];
        const fileName = `POP_Report_${new Date().toLocaleDateString('en-US').replaceAll('/', '-')}`;
        const columnRange = ''; // Modify if you want to limit columns in the export

        if (format === 'csv') {
            // Pass the fileTypeExtension parameter to the exportToExcel function to handle CSV export.
            XLSXExport.exportToExcel(headers, exportFilteredData, fileName, columnRange, 'csv');
        } else {
            // Export to Excel by default
            XLSXExport.exportToExcel(headers, exportFilteredData, fileName, columnRange, '');
        }
    };

    return (
        <div className="midsection qm-upload abs z1">
            {loader ? <Loader /> : null}
            <div className="rel">
                <div className="wrap">
                    <h1 className="page-title">{"POP Viewer"}</h1>
                    {errorMSG?.length ? (
                        <div>
                            <p className="text-danger text-center">{errorMSG}</p>
                        </div>
                    ) : null}
                    {successMessage ? (
                        <div>
                            <p className="text-success text-center">{successMessage}</p>
                        </div>
                    ) : null}
                    <div className="section">
                        <div className={`content ${style.contentQueryPage}`}>
                            <form>
                                <div className={`sub-hdr alt ${style.subHeader}`}>
                                    <div className="nib">
                                        <div className="the-notch">
                                            <div className="notch-fg">
                                                <svg>
                                                    <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className={style.sectionTitle}>{showAll ? "All POPs" : "Active POPs"}</h2>
                                    <div className={style.flexContainer} style={{ display: 'flex', alignItems: 'flex-start', gap: '20px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showAll}
                                                    onChange={() => setShowAll(!showAll)}
                                                    color="primary"
                                                />
                                            }
                                            label="Show all active and inactive POPs"
                                        />
                                        <div>
                                            <button className="ico-button light-button" onClick={(e) => { e.preventDefault(); handleExport('xlsx'); }}>
                                                <span><strong>Export to Excel</strong></span>
                                                <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
                                                    <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
                                                        transform="translate(-1 -1)">
                                                    </path>
                                                </svg>
                                            </button>
                                            <button className="ico-button light-button" onClick={(e) => { e.preventDefault(); handleExport('csv'); }}>
                                                <span><strong>Export to CSV</strong></span>
                                                <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
                                                    <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
                                                        transform="translate(-1 -1)">
                                                    </path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <StripedDataGrid
                                    autoHeight
                                    theme={CustomTheme}
                                    rows={filteredData}
                                    getRowId={(row) => row.uid}
                                    columns={columns}
                                    filterModel={filterModel}
                                    onFilterModelChange={(model) => setFilterModel(model)}
                                    pageSize={pageSize}
                                    rowsPerPageOptions={[]}
                                    resultsPerPageOptions={[]}
                                    pageSizeOptions={[]}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    slots={{
                                        pagination: TablePagination,
                                        toolbar: CustomToolbar
                                    }}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 25
                                            },
                                        },
                                    }}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default POPViewer;