import axios from "./axios/customAxios";

const getProfiles = async () => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=profiles`;
	return await axios.get(profileURL);
}

const getSelectedProfile = async () => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=selectedProfile`;
	return await axios.get(profileURL);
}

//Service call to load the Accounts Tab
const getCampAcansAccount = async () => {
	let profileUrls = [
		`${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=campAccounts`,
		`${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=acnas`
	];
	return await Promise.all(profileUrls.map(url => axios.get(url)));
}

//Service call to load any new camp accounts that have been added
const getCAMPAccount = async () => {
	const campAccountURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=campAccounts`;
	return await axios.get(campAccountURL);
}

//Service call to load any new acnas that have been added or deleted
const getACNAS = async () => {
	let acanURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=acnas`;
	return await axios.get(acanURL);
}

const getCustomerProducts = async () => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=products`;
	return await axios.get(profileURL);
}

const getNNIS = async () => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=nnis`;
	return await axios.get(profileURL);
}

const getAPI = async () => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=api`;
	return await axios.get(profileURL);
}

const getAeCamSeOptions = async () => {
	let profileUrls = [
		`${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=ae`,
		`${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=cam`,
		`${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=se`
	];

	return await Promise.all(profileUrls.map(url => axios.get(url)));
}

const getNNIOptions = async (id, action, nni_type) => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=availNNIs&id=${id}&action=${action}&nni_type=${nni_type}`;
	return await axios.get(profileURL);
}

const getAssignedNNIOptions = async (id, action) => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=assignedNNIs&id=${id}&action=${action}`;
	return await axios.get(profileURL);
}


const getNNIAddress = async (nni, nni_type) => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=nniAddress&nni=${nni}&nni_type=${nni_type}`;
	return await axios.get(profileURL);
}

const getProfileHistory = async () => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/getProfileData.php?type=history`;
	return await axios.get(profileURL);
}


const submitProfile = async (data) => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/profileSubmit.php?data=${encodeURIComponent(JSON.stringify(data))}`;
	return await axios.get(profileURL);
}

const submitAccount = async (data) => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/accountSubmit.php?data=${JSON.stringify(data)}`;
	return await axios.get(profileURL);
}

const submitAcna = async (data) => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/acnaSubmit.php?data=${JSON.stringify(data)}`;
	return await axios.get(profileURL);
}

const submitNNIData = async (data) => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/nniSubmit.php?data=${JSON.stringify(data)}`;
	return await axios.get(profileURL);
}

const submitAPIData = async (data) => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/apiSubmit.php?data=${JSON.stringify(data)}`;
	return await axios.get(profileURL);
}

const submitProducts = async (data) => {
	const profileURL = `${process.env.REACT_APP_API_URL}/profile/profileProductSubmit.php?data=${encodeURIComponent(JSON.stringify(data))}`;
	return await axios.get(profileURL);
}

const updateCAMPAccount = (data) => {
	const campURL = `${process.env.REACT_APP_API_URL}/profile/updateCampAccount.php?data=${encodeURIComponent(JSON.stringify(data))}`;
	return axios.get(campURL);
}

const searchAccount = (data) => {
	const campURL = `${process.env.REACT_APP_API_URL}/profile/searchCampAccount.php?data=${encodeURIComponent(JSON.stringify(data))}`;
	return axios.get(campURL);
}

const moveAccount = (data) => {
	const campURL = `${process.env.REACT_APP_API_URL}/profile/moveCampAccount.php?data=${encodeURIComponent(JSON.stringify(data))}`;
	return axios.get(campURL);
}

const submitNNICircuitData = (data) => {
	const campURL = `${process.env.REACT_APP_API_URL}/profile/nniSubmit.php?data=${encodeURIComponent(JSON.stringify(data))}`;
	return axios.get(campURL);
}

const ProfileService = {
	getProfiles,
	getSelectedProfile,
	getCampAcansAccount,
	getCustomerProducts,
	getNNIS,
	getAPI,
	getAeCamSeOptions,
	getCAMPAccount,
	getACNAS,
	getNNIOptions,
	getAssignedNNIOptions,
	getNNIAddress,
	submitProfile,
	submitAccount,
	submitAcna,
	submitNNIData,
	submitAPIData,
	submitProducts,
	updateCAMPAccount,
	searchAccount,
	moveAccount,
	getProfileHistory,
	submitNNICircuitData
}

export default ProfileService;
