import React from 'react';
import style from "../quote/QuoteSummary.module.css";
import ToggleSwitch from '../components/ToggleSwitch';

const ReportOptions = ({
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  limit,
  setLimit,
  product,
  setProduct,
  excludeApiQuotes,
  setExcludeApiQuotes,
  reloadSummary
}) => {

  const handleDateUpdate = (event) => {
    const { id, value } = event.target;
    if (id === "dateFrom") setDateFrom(value);
    if (id === "dateTo") setDateTo(value);
  };

  const handleLimitUpdate = (event) => setLimit(event.target.value);
  const handleProductUpdate = (event) => setProduct(event.target.value);

  return (
    <div className={`row ${style.reportOptionWrap}`}>
      <div className={`col-md-4 ${style.reportFilter}`}>
        <h4>Results</h4>
        <label className={style.labelText}>Date From</label>
        <input
          type='date'
          min="2015-01-01"
          className={style.dateFld}
          id='dateFrom'
          name='dateFrom'
          onChange={handleDateUpdate}
          value={dateFrom}
        />
        <label className={style.labelText}>Date To</label>
        <input
          type='date'
          min="2015-01-01"
          className={style.dateFld}
          id='dateTo'
          name='dateTo'
          onChange={handleDateUpdate}
          value={dateTo}
        />
      </div>
      <div className={`col-md-4 ${style.reportFilter}`}>
        <h4>Product(s)</h4>
        <div className={style.paramsList}>
          <select
            className={`form-control ${style.selectFld}`}
            name="product"
            value={product}
            onChange={handleProductUpdate}
          >
            <option value='all'>All Products</option>
            <option value='1'>Wave</option>
            <option value='10'>DIA POP</option>
            <option value='11'>DIA Prem</option>
            <option value='23'>Wholesale Broadband</option>
            <option value='6'>MPLS AGG Tail</option>
            <option value='13'>MPLS + Internet</option>
            <option value='7'>MPLS NNI</option>
            <option value='19'>E-Line</option>
            <option value='20'>E-Access</option>
            <option value='22'>E-Access NNI</option>
            <option value='15'>Colocation</option>
            <option value='29'>OfficeSuite White Label</option>
            <option value='32'>Dark Fiber</option>
          </select>
        </div>
        <h4>Preferences</h4>
        <div className={style.paramsList}>
          <ToggleSwitch
            label='Exclude API Quotes'
            state={excludeApiQuotes}
            toggleEmit={() => setExcludeApiQuotes(!excludeApiQuotes)}
          />
        </div>
      </div>
      <div className={`col-md-4 ${style.reportFilter}`}>
        <h4>Limit</h4>
        <div className={style.paramsList}>
          <select
            className={`form-control ${style.selectFld}`}
            name="limit"
            value={limit}
            onChange={handleLimitUpdate}
          >
            <option value='100'>100 Quotes</option>
            <option value='500'>500 Quotes</option>
            <option value='1000'>1000 Quotes</option>
            <option value='5000'>5000 Quotes</option>
            <option value='all'>All Quotes</option>
          </select>
        </div>
        <div className='col-md-6 text-right'>
          <button className={`light-button ${style.applyBtn}`} onClick={reloadSummary}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportOptions;