import style from './OrderCreate.module.css';
import {useEffect, useState, useMemo} from "react";
import OrderHelper from '../../services/OrderService';
import {connect, useSelector} from "react-redux";
import {
	setOrderASN,
	setOrderBGP,
	setOrderBGPComm,
	setOrderBGPPrefix,
	setOrderJurisdictionalTraffic,
	setOrderMPLSCircuit,
	setOrderMplsRoute,
	setOrderMplsStaticIp,
	setOrderNetworkName,
	setOrderNewVlan,
	setOrderNumberOfNNI,
	setOrderNNI,
	setOrderOwnIP,
	setOrderPrefix,
	setOrderQOS,
	setOrderRemoteAsn,
	setOrderRemoveOverride,
	setOrderRemoveVRFOverrride,
	setOrderVLAN,
	setOrderWanIP,
} from '../../redux/actions/orderCreateActions';
import Loader from '../shared/Loader';
import {ConsoleLogger} from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";

const qosPolicyOptions = [
	{ "id": 1, "name": "Q-5rt-75mc-20sd" },
	{ "id": 2, "name": "Q-5rt-75bc-20sd" },
	{ "id": 3, "name": "Q-5rt-95sd" },
	{ "id": 4, "name": "Q-10rt-90sd" },
	{ "id": 5, "name": "Q-10rt-60mc-30sd" },
	{ "id": 6, "name": "Q-10rt-60bc-30sd" },
	{ "id": 7, "name": "Q-10r-50mc-30bc-10sd" },
	{ "id": 8, "name": "Q-25rt-70mc-5sd" },
	{ "id": 9, "name": "Q-25rt-70bc-5sd" },
	{ "id": 10, "name": "Q-50rt-40mc-10sd" },
	{ "id": 11, "name": "Q-50rt-40bc-10sd" },
	{ "id": 12, "name": "Q-75rt-20mc-5sd" },
	{ "id": 13, "name": "Q-75rt-20bc-bsa" },
	{ "id": 14, "name": "Q-90rt-8mc-2sd" },
	{ "id": 15, "name": "Q-90rt-8bc-2sd" },
	{ "id": 16, "name": "Q-90rt-10sd (voip default)" },
	{ "id": 17, "name": "Q-25rt-40mc-30bc-5sd" },
	{ "id": 18, "name": "Q-50rt-25mc-20bc-5sd" },
	{ "id": 19, "name": "Q-75rt-10mc-10bc-5sd" },
	{ "id": 20, "name": "Q-50mc-50sd" },
	{ "id": 21, "name": "Q-50bc-50sd" },
	{ "id": 22, "name": "Q-50mc-25bc-25sd" },
	{ "id": 23, "name": "Q-100sdd (default)" }];

const OrderMpls = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }),[]);
	const [hasError, setHasError] = useState(null);
	const [nniOptions, setNNIOptions] = useState([]);
	const [circuitvrfOptions, setCircuitVRFOptions] = useState([]);
	const [loader, setLoader] = useState(false);
	const selectedOrderProductDetails = useSelector(state => state.orderCreate.productDetails);
	const selectedOrderVRFDetails = useSelector(state => state.orderCreate.vrfDetails);
	const selectedOrderMplsRouteDetails = useSelector(state => state.orderCreate.mplsRouteDetails);
	const selectedQuotedData = useSelector(state => state.orderCreate.quotedData);

	useEffect(() => {
		setLoader(true);
		OrderHelper.getOrderNNI().then(resp => {
			setNNIOptions(resp.data);
			setLoader(false);
		}).catch(error => {
			logger.error("Error getting NNI Locations: " + error);
			setHasError(error);
			setLoader(false);
		})
	}, [logger])

	useEffect(() => {
		if(selectedOrderVRFDetails?.nni[0]){
			for(let i=0; i<selectedOrderVRFDetails.vrf_conn; i++){
				circuitVrfMenuOptions(selectedOrderVRFDetails?.nni[i], i);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const circuitVrfMenuOptions = (nniLocation, index) => {
		setLoader(true);
		OrderHelper.getOrderCircuitVRF(nniLocation).then(response => {
			setCircuitVRFOptions((prevState) => ({ ...prevState, [index]: response.data}));
			setLoader(false);
		}).catch(error => {
			logger.error("Error get Circuit or VRF Options: " + error);
			setHasError(error);
			setLoader(false);
		})
	}

	const inputHandler = (ev) => {
		switch (ev.target.id) {
			case "vrf_conn":
				props.updateNumberOfNNI(ev.target.value);
				break;
			case "mpls_route":
				props.updateOrderMplsRoute(ev.target.value);
				break;
			case "own_ip":
				props.updateOrderOwnIP(ev.target.value);
				break;
			case "bgp_prefix":
				props.updateOrderBGPPrefix(ev.target.value);
				break;
			case "bgp_comm":
				props.updateOrderBGPComm(ev.target.value);
				break;
			case "remove_override":
				props.updateOrderRemoveOverride(ev.target.value);
				break;
			case "remote_asn":
				props.updateOrderRemoteAsn(ev.target.value);
				break;
			case "qos":
				props.updateOrderQOS(ev.target.value);
				break;
			case "wan_ip":
				props.updateOrderWanIp(ev.target.value);
				break;
			case "mpls_static_ip":
				props.updateOrderMplsStaticIP(ev.target.value);
				break;
			case "jurisdiction":
				props.updateOrderJurisdictionalTraffic(ev.target.value);
				break;
			default:
				break;
		}
	}

	const vrfDetailHandler = (event, index) => {
		switch(event.target.name){
			case "nni":
				props.updateOrderNNI(event.target.value, index);
				props.updateOrderMPLSCircuit("", index);
				circuitVrfMenuOptions(event.target.value, index);
				break;
			case "vlan":
				props.updateOrderVLAN(event.target.value, index);
				break;
			case "newVlan":
				props.updateOrderNewVlan(event.target.checked, index);
				if(event.target.checked){
					props.updateOrderASN("65000", index);
					props.updateOrderPrefix("50", index);
				}
				break;
			case "mpls_circuit":
				props.updateOrderMPLSCircuit(event.target.value, index);
				break;
			case "override":
				props.updateOrderRemoveVRFOverride(event.target.value, index);
				break;
			case "bgp":
				props.updateOrderBGP(event.target.value, index);
				break;
			case "asn":
				props.updateOrderASN(event.target.value, index);
				break;
			case "prefix":
				props.updateOrderPrefix(event.target.value, index);
				break;
			case "network_name":
				props.updateOrderNetworkName(event.target.value, index);
				break;
			default:
				break;
		}
	}

	return (
		<>
		{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<>
			<div className={style.section1}>
				<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
					<div className="nib">
						<div className="the-notch">
							<div className="notch-fg">
								<svg>
									<path
										d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
								</svg>
							</div>
						</div>
					</div>
					<h2 className={` ${style.sectionTitle} m-0 `}>NNI Locations</h2>
				</div>
			</div>

			<div className="sub-section">
				<div className={` row ${style.rowGrp}`}>
					<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
						<label className={style.labelText}>Number of Existing NNI Locations<span className={style.mandatory}>*</span></label>
						<select className={style.selectFld} id='vrf_conn' name='vrf_conn' onChange={inputHandler} value={selectedOrderVRFDetails.vrf_conn}>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
						</select>
					</div>
				</div>
			</div>
			<div className={style.dividers}></div>
			<div className={style.section1}>
				<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
					<div className="nib">
						<div className="the-notch">
							<div className="notch-fg">
								<svg>
									<path
										d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
								</svg>
							</div>
						</div>
					</div>
					<h2 className={` ${style.sectionTitle} m-0 `}>VRF Details</h2>
				</div>
			</div>

			{[...Array(Number(selectedOrderVRFDetails.vrf_conn))].map((item,idx) => {
				return (
					<>
				<div className="sub-section" key={idx}>
					<div className={` row ${style.rowGrp}`}>
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>NNI LOCATION {idx+1}<span className={style.mandatory}>*</span></label>
							<select className={style.selectFld} id={`nni${idx}`} name='nni' onChange={(event) => vrfDetailHandler(event, idx)} value={selectedOrderVRFDetails.nni[idx]}>
								<option></option>
								{nniOptions.map((obj) => {
									return <option value={obj.id}>{obj.name}</option>
								})}
							</select>
						</div>
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>VLAN<span className={style.mandatory}>*</span></label>
							<input type='text' className={style.inputFld} id={`vlan${idx}`} name='vlan' maxLength='4' onChange={(event) => vrfDetailHandler(event, idx)} value={selectedOrderProductDetails.ceVlan[idx]}></input>
						</div>
						{selectedOrderVRFDetails.nni[idx] !== "VRF" ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label htmlFor='newVlan' className={style.labelText}>Check if new VLAN</label>
								<input type='checkbox' id={`newVlan${idx}`} name='newVlan' className={style.inputFld} onChange={(event) => vrfDetailHandler(event, idx)} checked={selectedOrderProductDetails.newVlan[idx]}></input>
							</div> :
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Existing VLAN</label>
							</div>
						}
						{selectedOrderVRFDetails.nni[idx] ?
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Circuit or VRF<span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id={`mpls_circuit${idx}`} name='mpls_circuit' onChange={(event) => vrfDetailHandler(event, idx)} value={selectedOrderVRFDetails.mpls_circuit[idx]} >
									<option value="">-</option>
									{circuitvrfOptions[idx]?.map((obj) => {
										return <option value={obj.id}>{obj.name}</option>
									})}

								</select>
							</div>
							: null}

						{selectedOrderProductDetails.newVlan[idx] ?
							<>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Remove AS Override<span className={style.mandatory}>*</span></label>
									<select className={style.selectFld} id={`override${idx}`} name='override' onChange={(event) => vrfDetailHandler(event, idx)} value={selectedOrderVRFDetails.override[idx]}>
										<option value="">-</option>
										<option value="N">NO</option>
										<option value="Y">YES</option>
									</select>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Add BGP Community Strings<span className={style.mandatory}>*</span></label>
									<select className={style.selectFld} id={`bgp${idx}`} name='bgp' onChange={(event) => vrfDetailHandler(event, idx)} value={selectedOrderVRFDetails.bgp[idx]}>
										<option value="">-</option>
										<option value="N">NO</option>
										<option value="Y">YES</option>
									</select>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Remote ASN to configure<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} id={`asn${idx}`} name='asn' onChange={(event) => vrfDetailHandler(event, idx)} value={selectedOrderVRFDetails.asn[idx]}></input>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Increase BGP Prefixes<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} id={`prefix${idx}`} name='prefix' onChange={(event) => vrfDetailHandler(event, idx)} value={selectedOrderVRFDetails.prefix[idx]}></input>
								</div>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Network Name<span className={style.mandatory}>*</span></label>
									<input type='text' className={style.inputFld} id={`network_name${idx}`} name='network_name' maxLength='31' onChange={(event) => vrfDetailHandler(event, idx)} value={selectedOrderVRFDetails.network_name[idx]}></input>
								</div>
							</>
							: null}
					</div>
				</div>
				<div className={style.dividers}></div>
				</>
			)})}
			<div className={style.section1}>
				<div className={`sub-hdr alt2 ${style.subHdrAlter} `}>
					<div className="nib">
						<div className="the-notch">
							<div className="notch-fg">
								<svg>
									<path
										d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
								</svg>
							</div>
						</div>
					</div>
					<h2 className={` ${style.sectionTitle} m-1 `}>MPLS & Routing Detail </h2>
				</div>
			</div>
			<div className="sub-section">
				<div className={` row ${style.rowGrp}`}>
					<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
						<label className={style.labelText}>MPLS Routing<span className={style.mandatory}>*</span></label>
						<select className={style.selectFld} id='mpls_route' name='mpls_route' onChange={inputHandler} value={selectedOrderMplsRouteDetails.mpls_route} >
							<option value=""></option>
							<option value="BGP">BGP</option>
							<option value="Static">Static</option>
						</select>
					</div>
					<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
						<label className={style.labelText}>Assign Own WAN IP</label>
						<select className={style.selectFld} id='own_ip' name='own_ip' onChange={inputHandler} value={selectedOrderMplsRouteDetails.own_ip}>
							<option></option>
							<option value="N">NO</option>
							<option value="Y">YES</option>
						</select>
					</div>
					{selectedOrderMplsRouteDetails.mpls_route === "Static" ?
						null :
						<>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>BGP Prefixes Advertised<span className={style.mandatory}>*</span></label>
								<input type='text' className={style.inputFld} id='bgp_prefix' name='bgp_prefix' onChange={inputHandler} value={selectedOrderMplsRouteDetails.bgp_prefix}></input>
							</div>

							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Remove AS Override<span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id='remove_override' name='remove_override' onChange={inputHandler} value={selectedOrderMplsRouteDetails.remove_override}>
									<option value=""></option>
									<option value="N">NO</option>
									<option value="Y">YES</option>
								</select>
							</div>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Add BGP Community Strings<span className={style.mandatory}>*</span></label>
								<select className={style.selectFld} id='bgp_comm' name='bgp_comm' onChange={inputHandler} value={selectedOrderMplsRouteDetails.bgp_comm}>
									<option value=""></option>
									<option value="N">NO</option>
									<option value="Y">YES</option>
								</select>
							</div>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Remote ASN to configure<span className={style.mandatory}>*</span></label>
								<input type='text' className={style.inputFld} id='remote_asn' name='remote_asn' onChange={inputHandler} value={selectedOrderMplsRouteDetails.remote_asn}></input>
							</div>
						</>
					}
					<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
						<label className={style.labelText}>QOS Policy<span className={style.mandatory}>*</span></label>
						<select className={style.selectFld} id='qos' name='qos' onChange={inputHandler} value={selectedOrderMplsRouteDetails.qos}>
							<option></option>
							{qosPolicyOptions.map((obj) => {
								return <option value={obj.id}>{obj.name}</option>
							})}
						</select>
					</div>
					{selectedOrderMplsRouteDetails.own_ip === "Y" ?
						<>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>Customer WAN IP Subnet<span className={style.mandatory}>*</span></label>
								<input type='text' className={style.inputFld} placeholder="ex: 192.168.1.128/25" id='wan_ip' name='wan_ip' onChange={inputHandler} value={selectedOrderMplsRouteDetails.wan_ip}></input>
							</div>
							<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
								<label className={style.labelText}>IP to Configure in WIN Edge Router<span className={style.mandatory}>*</span></label>
								<input type='text' className={style.inputFld} value="First Usable IP" disabled></input>
							</div>
						</>
						: null}

					{selectedOrderMplsRouteDetails.mpls_route === "Static" ?
						<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
							<label className={style.labelText}>Please list all static IP blocks to be routed to this location<span className={style.mandatory}>*</span></label>
							<input type='text' className={style.inputFld} id='mpls_static_ip' name='mpls_static_ip' onChange={inputHandler} value={selectedOrderMplsRouteDetails.mpls_static_ip}></input>
						</div>
						: null
					}
				</div>
			</div>
			<div className={style.dividers}></div>
			{selectedQuotedData.product_id === "6" ?
				<>
					<div className={style.section1}>
						<div className={`sub-hdr alt1 ${style.subHdrAlter} `}>
							<div className="nib">
								<div className="the-notch">
									<div className="notch-fg">
										<svg>
											<path
												d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
										</svg>
									</div>
								</div>
							</div>
							<h2 className={` ${style.sectionTitle} m-0 `}>Jurisdictional Traffic</h2>
						</div>
						<div className="sub-section">
							<div className={` row ${style.rowGrp}`}>
								<div className={` col-12 col-sm-6 col-md-4 col-lg-4 ${style.colGrp}`}>
									<label className={style.labelText}>Jurisdictional Traffic<span className={style.mandatory}>*</span></label>
									<div className="d-flex flex-row">
										<select className={style.selectFld} id='jurisdiction' name='jurisdiction' onChange={inputHandler} value={selectedOrderProductDetails.jurisdiction}>
											<option value="1">Intrastate</option>
											<option value="2">Interstate</option>
										</select>
										<div className={` ${style.w_10}`}>
											<div className={` ${style.infoTip}`}>
												<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
												<p className={style.contentHover}>
													FUSF exempt customers will default to an Interstate rate plan. Exemption certificates on file with Windstream will dictate monthly taxes and surchanges. For non-exempt customers please see your Windstream Account Executive to discuss executing a global Jurisdiction Traffic form.
												</p>
											</div>
										</div>
									</div>
									<a href='../../assets/files/Jurisdictional_Traffic_Certification.pdf' className={style.lableTextS} download>Download Jurisdictional Traffic Certification form</a>
								</div>
							</div>
						</div>
					</div>
					<div className={style.dividers}></div>
				</> :
				null}</>)}
		</>)


}


const mapDispatchToProps = {
	updateOrderNewVlan: setOrderNewVlan,
	updateNumberOfNNI: setOrderNumberOfNNI,
	updateOrderNNI: setOrderNNI,
	updateOrderVLAN: setOrderVLAN,
	updateOrderMPLSCircuit: setOrderMPLSCircuit,
	updateOrderRemoveVRFOverride: setOrderRemoveVRFOverrride,
	updateOrderBGP: setOrderBGP,
	updateOrderASN: setOrderASN,
	updateOrderPrefix: setOrderPrefix,
	updateOrderNetworkName: setOrderNetworkName,
	updateOrderMplsRoute: setOrderMplsRoute,
	updateOrderOwnIP: setOrderOwnIP,
	updateOrderBGPPrefix: setOrderBGPPrefix,
	updateOrderBGPComm: setOrderBGPComm,
	updateOrderRemoveOverride: setOrderRemoveOverride,
	updateOrderRemoteAsn: setOrderRemoteAsn,
	updateOrderQOS: setOrderQOS,
	updateOrderMplsStaticIP: setOrderMplsStaticIp,
	updateOrderWanIp: setOrderWanIP,
	updateOrderJurisdictionalTraffic: setOrderJurisdictionalTraffic
}


export default connect(null, mapDispatchToProps)(OrderMpls);
