import React, { useEffect, useState, useMemo } from "react";
import ViewportWarning from "../shared/ViewportWarning";
import TabsQuote from "./includes/TabsQuote";
import style from "./QuoteCreate.module.css";
import QuoteHelper from "../../services/QuoteService";
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import * as util from "util";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import CompanyDropdown from "../shared/CompanyDropdown";
import { useSelector } from 'react-redux';

const BulkQuoteCreate = (props) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [hasError, setHasError] = useState(null);
	const [loader, setLoader] = useState(false); // loader
	const [projectName, setProjectName] = useState('');
	const [contactArray, setContactArray] = useState([]); // build contact Array
	const [newContactFlag, setNewContactFlag] = useState(false);
	const [contact, setContact] = useState('');
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [email, setEmail] = useState('');
	const [productArray, setProductArray] = useState([]); // build product Array
	const [product, setProduct] = useState('');
	const [term1, setTerm1] = useState('');
	const [term2, setTerm2] = useState('');
	const [term3, setTerm3] = useState('');
	const [term4, setTerm4] = useState('');
	const [term5, setTerm5] = useState('');
	const [selectedFile, setSelectedFile] = useState('');
	const [fileName, setFileName] = useState('');
	const [validationErr, setValidationErr] = useState('');
	const [isSuccess, setIsSuccess] = useState('');
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);

	const formFields = [
		{ id: 1, labelName: "Project Name", code: "projectName", value: projectName, required: true },
		{ id: 2, labelName: "Contact", code: "contact", value: contact, required: true },
		{ id: 3, labelName: "Phone Number", code: "phoneNumber", value: phoneNumber, required: true },
		{ id: 4, labelName: "Email", code: "email", value: email, required: true },
		{ id: 5, labelName: "Product", code: "product", value: product, required: true },
		{ id: 6, labelName: "File Upload", code: "fileUpload", value: selectedFile, required: true },
	];

	useEffect(() => {
		//Populates Product Menu
		setLoader(true);
		QuoteHelper.getProductMenu("bulk").then(res => {
			setProductArray(res.data);
			setLoader(false);
		}).catch((error) => {
			logger.error("getProductMenu ERROR: " + error)
			setLoader(false);
			setHasError(error);
		});

		if (isEmployee) {
			if (selectedInternalMpid) {
				//Populates Contact Dropdown if user is Employee we need to wait for mpid
				setLoader(true);
				QuoteHelper.getQuoteContact().then(resp => {
					setContactArray(resp.data);
					setLoader(false);
				}).catch((error) => {
					logger.error("Get Contact Error: " + error);
					setLoader(false);
					setHasError(error);
				});
			}
		} else {
			//Populates Contact Dropdown
			setLoader(true);
			QuoteHelper.getQuoteContact().then(resp => {
				setContactArray(resp.data);
				setLoader(false);
			}).catch((error) => {
				logger.error("Get Contact Error: " + error);
				setLoader(false);
				setHasError(error);
			});
		}
	}, [isEmployee, logger, selectedInternalMpid]);

	const inputChange = (ev) => {
		switch (ev.target.id) {
			case "projectName":
				setProjectName(ev.target.value)
				break;
			case "name":
				setName(ev.target.value)
				break;
			case "phoneNumber":
				setPhoneNumber(ev.target.value)
				break;
			case "email":
				setEmail(ev.target.value)
				break;
			case "product":
				setProduct(ev.target.value)
				break;
			case "term1":
				setTerm1(ev.target.value)
				break;
			case "term2":
				setTerm2(ev.target.value)
				break;
			case "term3":
				setTerm3(ev.target.value)
				break;
			case "term4":
				setTerm4(ev.target.value)
				break;
			case "term5":
				setTerm5(ev.target.value)
				break;
			default:
				break;
		}

		// disable after three term have been checked
		let checkedBoxesCnt = document.querySelectorAll("input[type=checkbox]:checked").length;
		let maxAllowed = 3;
		let term1 = document.getElementById("term1");
		let term2 = document.getElementById("term2");
		let term3 = document.getElementById("term3");
		let term4 = document.getElementById("term4");
		let term5 = document.getElementById("term5");
		if (checkedBoxesCnt >= maxAllowed) {

			if (term1.checked === false) {
				term1.disabled = true;
			}
			if (term2.checked === false) {
				term2.disabled = true;
			}
			if (term3.checked === false) {
				term3.disabled = true;
			}
			if (term4.checked === false) {
				term4.disabled = true;
			}
			if (term5.checked === false) {
				term5.disabled = true;
			}
		} else {
			term1.disabled = false;
			term2.disabled = false;
			term3.disabled = false;
			term4.disabled = false;
			term5.disabled = false;
		}
	}

	const contactHandler = (ev) => {
		let cp_id = ev.target.value;
		if (cp_id === "new") {
			setNewContactFlag(true);
		} else {
			autoFillContact(cp_id);
		}
		setContact(cp_id);
	}

	const autoFillContact = (value) => {
		QuoteHelper.getQuoteContactInfo(value).then(resp => {
			setPhoneNumber(resp.data.phone);
			setEmail(resp.data.email);
		}).catch((error) => {
			logger.error("Get Contact info Error: " + error);
			setHasError(error);
		});
	}

	const handleFileSelect = (event) => {
		setSelectedFile(event.target.files[0]);
		setFileName(`File name: ${event.target.files[0].name}`);
	}

	const handleSubmit = (event) => {
		formFields[5].value = selectedFile;
		let errMsg = '';
		let validCheck = '';
		let findRequired = formFields.find(UF => (UF.value === '' || UF.value === undefined) && UF.required);
		let termError = '';
		if (!term1 && !term2 && !term3 && !term4 && !term5) {
			termError = "At least one term is required. ";
		}

		//if (!!findRequired || !termError) {
		if (!!findRequired) {
			logger.info(findRequired);
			validCheck = "error";
			errMsg = "Please enter the required fields. ";
			if (!projectName) errMsg += "Project Name is required. ";
			if (!contact) errMsg += "Name is required. ";
			if (contact === "new" && !name) errMsg += "Name is required. ";
			if (!email) errMsg += "Email is required. ";
			if (!phoneNumber) errMsg += "Phone number is required. ";
			if (!product) errMsg += "Product is required . ";
			if (termError !== '') errMsg += termError;
			if (!selectedFile) errMsg += "File Attachment is required. ";
			setIsSuccess(validCheck);
			setValidationErr(errMsg);
		} else if (termError !== '') {
			validCheck = "error";
			errMsg = termError;
			setIsSuccess(validCheck);
			setValidationErr(errMsg);
		} else {
			setLoader(true);
			let values = {
				"project_name": projectName,
				"contact": contact,
				"new_contact": name,
				"phone": phoneNumber,
				"email": email,
				"product": product,
				"one": term1,
				"two": term2,
				"three": term3,
				"four": term4,
				"five": term5
			}
			logger.info(JSON.stringify(values));
			event.preventDefault()
			const formData = new FormData();
			formData.append("userfile", selectedFile);
			logger.info(`FormData inspect: ${util.inspect(formData)}`);
			QuoteHelper.createBulkQuote(formData, values).then(resp => {
				logger.info(resp.data.result);
				if (resp.data.result === undefined || resp.data.result === "") {
					errMsg = "The result was empty.";
					setValidationErr(errMsg);
				}
				setLoader(false);
				if (resp.data.result === "SUCCESS") {
					validCheck = "success";
					errMsg = "Your Bulk Quote has been successfully submitted.";
					// clear fields
					setProjectName('');
					setContact('');
					setName('');
					setEmail('');
					setPhoneNumber('');
					setProduct('');
					document.getElementById("term1").checked = false;
					document.getElementById("term2").checked = false;
					document.getElementById("term3").checked = false;
					document.getElementById("term4").checked = false;
					document.getElementById("term5").checked = false;
					logger.info("Upload RESPONSE: " + resp.data.result);
					logger.info("setting Validation " + validCheck + " Message: " + errMsg);
					setIsSuccess(validCheck);
					setValidationErr(errMsg);
				} else if (resp.data.result === "ERROR") {
					validCheck = "error";
					errMsg = "Error:" + resp.data.error;
					logger.info("ERROR: " + resp.data.error);
					logger.info("setting Validation " + validCheck + " Message: " + errMsg);
					setIsSuccess(validCheck);
					setValidationErr(errMsg);
				}
			}).catch((error) => {
				setLoader(false);
				logger.error("Upload ERROR: " + error);
				validCheck = "error";
				errMsg = "Error... " + error;
				logger.error("setting Validation " + validCheck + " Message: " + errMsg);
				setHasError(error);
				setIsSuccess(validCheck);
				setValidationErr(errMsg);
			});

		}

		logger.info("formFields: " + JSON.stringify(formFields));
	}

	return (
		<div className="midsection qm-upload abs z1">
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			<div className="rel">
				<ViewportWarning />
				<div className="wrap">
					<h1 className="page-title">{isEmployee ? "Employee Bulk Tool" : "Bulk Quote"}</h1>
					{isEmployee ? <CompanyDropdown /> : null}
					<div className="section">
						{/* <TabsQuoteManager/> */}
						<TabsQuote />
						{!hasError && (<div className={`content ${style.contentQueryPage}`}>
							<form>
								<>
									<div className={`row `}>
										<div className="col-12">
											<p className={` ${style.lableTextS} ${style.textBlack} `}><span
												className={style.mandatory}>*</span> Denotes a required field</p>
										</div>
									</div>

									<div className={`sub-hdr alt ${style.subHeader} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={style.sectionTitle}>Project Details</h2>
									</div>


									<div className={`row ${style.rowGrp}`}>
										<div className="col-12 col-sm-4 col-md-3">
											<label className={style.lableTextS}>Project Name <span
												className={style.mandatory}>*</span> </label>
											<input type="text" className={style.inputFld} id="projectName"
												value={projectName} maxLength='63' onChange={inputChange}></input>
										</div>
									</div>

									<div className={`row ${style.rowGrp} `}>
										<div className="col-12 col-sm-4 col-md-3">
											<label className={style.labelSelectRequiredFld}> Name <span
												className={style.mandatory}>*</span></label>
											{newContactFlag ?
												<input type="text" id="name" className={style.inputFld}
													onChange={inputChange}></input>
												:
												<select
													className={` form-control  ${style.selectFld} ${style.requiredFld} `}
													id="contact" value={contact} onChange={contactHandler}>
													{contactArray.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}
												</select>
											}
										</div>

										<div className="col-12 col-sm-4 col-md-3">
											<label className={style.lableTextS}>Phone <span
												className={style.mandatory}>*</span></label>
											<input type="text" className={style.inputFld} maxLength="10"
												id="phoneNumber" onChange={inputChange} value={phoneNumber}></input>
										</div>
										<div className="col-12 col-sm-4 col-md-3">
											<label className={style.lableTextS}>Email <span
												className={style.mandatory}>*</span></label>
											<input type="text" className={style.inputFld} id="email"
												onChange={inputChange} value={email}></input>
										</div>

									</div>
									<div className={`row ${style.rowGrp}`}>
										<div className="col-6 col-sm-4 col-md-3">
											<label className={style.labelSelectRequiredFld}>Product <span
												className={style.mandatory}>*</span></label>
											<select className={` form-control  ${style.selectFld} `} id="product"
												value={product} onChange={inputChange}>
												{
													productArray?.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})
												}
											</select>
										</div>
									</div>

									<div className={style.dividers}></div>

									<div className={` sub-hdr alt1 ${style.subHeaer} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={style.sectionTitle}>Term</h2>
									</div>

									<div className={`row ${style.rowGrp} `}>
										<div className="col-12">
											<input type="checkbox" id="term1" name="term1" value="on"
												onChange={inputChange} />
											<label htmlFor="term1" className={` ${style.checkboxText} `}>1 Year</label>

											<input type="checkbox" id="term2" name="term2" value="on"
												onChange={inputChange} />
											<label htmlFor="term2" className={` ${style.checkboxText} `}>2 Year</label>

											<input type="checkbox" id="term3" name="term3" value="on"
												onChange={inputChange} />
											<label htmlFor="term3" className={` ${style.checkboxText} `}>3 Year</label>

											<input type="checkbox" id="term4" name="term4" value="on"
												onChange={inputChange} />
											<label htmlFor="term4" className={` ${style.checkboxText} `}>4 Year</label>

											<input type="checkbox" id="term5" name="term5" value="on"
												onChange={inputChange} />
											<label htmlFor="term5" className={` ${style.checkboxText} `}>5 Year</label>
										</div>
									</div>

									<div className={style.dividers}></div>

									<div className={` sub-hdr alt2 ${style.subHeader} `}>
										<div className="nib">
											<div className="the-notch">
												<div className="notch-fg">
													<svg>
														<path
															d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
													</svg>
												</div>
											</div>
										</div>
										<h2 className={style.sectionTitle}>File Attachment</h2>
									</div>

									<div className={`row ${style.rowGrp}`}>
										<div className="col-12 col-sm-6 col-md-4">
											<label className={` ${style.lableTextS} d-block mb-4`}>File Upload <span
												className={style.mandatory}> *</span></label>
											<label htmlFor="uploadFile" className={style.uploadLabel}>
												<div className={style.uploadLabelText}>Browse...</div>
											</label>
											<input type="file" className={style.uploadFile}
												onChange={handleFileSelect} />
											<div>{fileName}</div>
										</div>

										<div className="col-12 col-sm-6 col-md-8">
											<div className={style.uplodadFileList}>
												<p className={` ${style.lableTextS} text-uppercase mb-4`}>Bulk Upload
													Templates</p>
												<a href="../../assets/files/bulk/DIA_Prem_TemplateV9.xls"
													className={style.lableTextS} download>DIA_Prem_TemplateV9</a>
												<a href="../../assets/files/bulk/MPLS_AGG_Tail_TemplateV5.xls"
													className={style.lableTextS}>MPLS_AGG_Tail_TemplateV5</a>
												<a href="../../assets/files/bulk/MPLS_Internet_TemplateV5.xls"
													className={style.lableTextS}>MPLS_Internet_TemplateV5</a>
												<a href="../../assets/files/bulk/E-Access_EPL_TemplateV6.xls"
													className={style.lableTextS}>E-Access_EPL_TemplateV6</a>
												<a href="../../assets/files/bulk/E-Access_EVPL_TemplateV3.xls"
													className={style.lableTextS}>E-Access_EVPL_TemplateV3</a>
												<a href="../../assets/files/bulk/E-Line_EPL_TemplateV3.xls"
													className={style.lableTextS}>E-Line_EPL_TemplateV3</a>
												<a href="../../assets/files/bulk/E-Line_EVPL_TemplateV2.xls"
													className={style.lableTextS}>E-Line_EVPL_TemplateV2</a>
												{/*
												{isEmployee ?
													<a href="../../assets/files/bulk/Bulk_Renewal_Template.xls"
														className={style.lableTextS} download>Bulk Renewal Template</a>
													: null
												}
													*/}
											</div>
										</div>

									</div>

									<div className="text-center mt-5">
										<p className={isSuccess === "error" ? "text-danger" : "text-success"}>{validationErr}</p>
									</div>

									<div className={"row my-5 rel"}>
										<div className={"col-12 text-center"}>
											<button className={` ico-button lhs light-button ${style.nextBtn} `} type="button" onClick={handleSubmit}>
												Submit
												<svg className="ico light-ico ico-arrow">
													<path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path>
												</svg>
											</button>
										</div>
									</div>

								</>
							</form>

						</div>)}
					</div>
				</div>
			</div>
		</div>
	);
}
export default BulkQuoteCreate;
