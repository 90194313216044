import { useState, useEffect } from 'react';
import style from './OrderCreate.module.css';
import FileUpload from '../shared/FileUpload';
import Loader from '../shared/Loader';
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import OrderHelper from '../../services/OrderService';

const SalesEnrichment = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [loader, setLoader] = useState(false);
	const [errorMSG, setErrorMSG] = useState('');
	const [successMSG, setSuccessMSG] = useState('');
	const [docusignChecked, setDocusignChecked] = useState(false);
	const [leasenetId, setLeasenetId] = useState(props.orderData?.leasenetId);
	const [allFieldsFilled, setAllFieldsFilled] = useState(false); // State to check if all fields are filled

	const fieldLabels = {
		'serv_date': 'Service Date',
		'acna': 'ACNA',
		'orig_contact_id': 'Primary Authorizing Contact Name',
		'orig_phone': 'Primary Authorizing Contact Phone',
		'orig_email': 'Primary Authorizing Contact Email',
		'tech_contact_id': 'Data Technical Contact',
		'tech_phone': 'Data Technical Phone',
		'tech_email': 'Data Technical Email',
		'cust_pon': 'Customer PON',
		'leasenet_id': 'Leasenet ID',
		'a_cp_name': 'Order Name',
		'a_lcon_name': 'Loc A Onsite Name',
		'a_lcon_phone': 'Loc A Onsite Phone Number',
		'a_lcon_email': 'Loc A Onsite Email',
		'z_cp_name': 'Loc Z Name',
		'z_lcon_name': 'Loc Z Onsite Name',
		'z_lcon_phone': 'Loc Z Onsite Phone Number',
		'z_lcon_email': 'Loc Z Onsite Email'
	};

	useEffect(() => {
		// Check if all required fields in orderData are filled
		// Do not all Review complete unless they are populated in orderData
		if (props.product === "32") {
			const requiredFields = Object.keys(fieldLabels);
			const allFilled = requiredFields.every(field =>
				props?.orderData?.[field]
			);
			setAllFieldsFilled(allFilled);
		}
	}, [props.orderData, props.product]);

	const inputHandler = (ev) => {
		switch (ev.target.id) {
			case "leasenetId":
				setLeasenetId(ev.target.value);
				break;
			default:
				break;
		}
	}

	const handleDocusignCheck = () => {
		setDocusignChecked(!docusignChecked);
	}

	const cancelOrder = () => {
		logger.info("Cancel Order");
		submitAction("Cancel Order");
	}

	const reviewComplete = () => {
		logger.info("Review Complete");
		if (props.product === "32" && !allFieldsFilled) {
			setErrorMSG("Please fill in all required fields and update the order before proceeding.");
			return;
		}
		submitAction("Review Complete");
	}

	const submitAction = (action) => {
		if (!docusignChecked && action !== "Cancel Order") {
			setErrorMSG('Must verify Service Order has been signed by customer and is uploaded.');
			return;
		} else {
			setErrorMSG('');
		}

		let submitJSON = {
			"action": action ? action : "",
			"ref_id": props.refId,
			"leasenet_id": leasenetId,
			"status": props.orderData?.status_id,
			"uniti": props.orderData?.uniti
		}

		logger.info("Order Data: " + JSON.stringify(submitJSON));
		setLoader(true);
		OrderHelper.createOrder(submitJSON).then(response => {
			logger.info(encodeURIComponent(response.data).replace(/%20/g, " "));
			if (response.data.result === "SUCCESS") {
				setLoader(false);
				if (action === "Review Complete") {
					logger.info("Successfully submitted Review Complete.");
					setSuccessMSG("Review Complete was successful.");
					// Reload the page after a delay
					setTimeout(() => {
						window.location.reload(true);
					}, 3000);
				} else {
					logger.info("Order has been cancelled");
					setSuccessMSG("ORDER CANCELLED");
				}
			} else {
				setLoader(false);
				setErrorMSG(response.data.error);
			}
		}).catch(error => {
			setLoader(false);
			setErrorMSG("FAILED TO Access orderSubmit.php");
			logger.error("Sales Enrichment Error: " + error);
		})
	}

	const orderUpdate = () => {
		setErrorMSG("");
		setLoader(true);
		if (props.product === "32") {
			const requiredFields = Object.keys(fieldLabels);
			const missingFields = requiredFields.filter(field =>
				!(props?.orderData?.[field] || props.updatedOrderData?.[field])
			);

			if (missingFields.length > 0) {
				const missingLabels = missingFields.map(field => fieldLabels[field]);
				setErrorMSG(`The following fields are required: ${missingLabels.join(', ')}`);
				setLoader(false);
				return;
			}
		}

		OrderHelper.updateOrder(props.updatedOrderData, props.refId).then(response => {
			console.log(response.data);
			if (response.data.result === "SUCCESS") {
				setSuccessMSG(response.data.message);
				setErrorMSG(response.data.error);

				setTimeout(function () {
					window.location.reload(true);
					setLoader(false);
				}, 3000);
			} else {
				setErrorMSG(response.data.error);
				setLoader(false);
			}
		}).catch(error => {
			setLoader(false);
			setErrorMSG("Failed to Access orderEdit.php");
			logger.error(`updateOrder error ${error}`);
		})
	}

	return (
		<>
			{loader ? <Loader /> : null}
			<div className={`sub-hdr ${style.subHdrAlter} `}>
				<div className="nib">
					<div className="the-notch">
						<div className="notch-fg">
							<svg>
								<path
									d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
							</svg>
						</div>
					</div>
				</div>
				<div className="d-flex flex-row">
					<h2 className={` ${style.sectionTitle} m-0 `}>Sales Enrichment</h2>
					<div className={` ${style.w_10}`}>
						<div className={` ${style.infoTip}`}>
							<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
							<p className={style.contentHover}>
								A signed Service Order is expected to be attached (Customer Correspondence file type).
							</p>
						</div>
					</div>
				</div>
			</div>
			<p className='text-danger ml-3 mt-3'>{errorMSG}</p>
			<p className='text-success ml-3 mt-3'>{successMSG}</p>
			<FileUpload refId={props.refId} />
			<div className="sub-section">
				{props.orderData?.product_id === '15' ? (
					<>
						<div className="d-flex flex-row">
							<div className={` col-4 ${style.colGrp}`}>
								<label className={style.labelText}>Leasenet ID
									{props.orderData?.uniti ? <span className={style.mandatory}>*</span> : null}
								</label>
								<input type='text' className={style.inputFld} maxLength='15' id='leasenetId' name='leasenetId' onChange={inputHandler} value={leasenetId}></input>
							</div>
						</div>
					</>
				) : null}
				{props.product !== "32" || allFieldsFilled ? (
				<div className="d-flex flex-row">
					<div className={` col-12 ${style.colGrp}`}>
						<label htmlFor='docusign_check' className={style.docusignCheckLabel}> Customer Signature Received and attached, Docusign process complete.<span className={style.mandatory}>*</span> </label>
						<input type='checkbox' id='docusign_check' name='docusign_check' className={style.inputFld} onClick={handleDocusignCheck} />
					</div>
				</div>
				) : (
					null
				)}
				<div className={`row ${style.rowGrp}`}>
					<div className="col-4">
						{props.product !== "32" || allFieldsFilled ? (
							<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={reviewComplete}>
								Review Complete
							</button>
						) : (
							<p className='text-danger ml-3 mt-3'>Please fill in all required fields and update the order.</p>
						)}
					</div>
					<div className="col-4">
						<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={orderUpdate}>
							Update Order
						</button>
					</div>
					<div className="col-4">
						<button className={` ico-button lhs light-button ${style.nextBtn} ml-3`} onClick={cancelOrder}>
							Cancel Order
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default SalesEnrichment;