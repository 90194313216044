import axios from "./axios/customAxios";

const getUserData = async (data) => {
	const manageURL = `${process.env.REACT_APP_LOGIN_URL}/employeeManage.php`;
	return await axios.post(manageURL, JSON.stringify(data));
}

const StaffManagementService = {
	getUserData
}

export default StaffManagementService;