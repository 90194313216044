import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import style from "./History.module.css";
import ProfileService from "../../services/ProfileServices";
import Loader from "../shared/Loader";
import {
  profileHistory
} from '../../redux';

const History = (props) => {
  const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL });
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;
  const historyFromStore = useSelector(state => state.employeeProfile.profile.profileHistory) || [];
  const [loader, setLoader] = useState(!historyFromStore.length);
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const fetchData = () => {
    setLoader(true);
    ProfileService.getProfileHistory().then(resp => {
      logger.info("History (getProfileHistory): " + JSON.stringify(resp.data)); // Uncomment if logging is needed
      props.profileHistory(resp.data);
      setLoader(false);
    }).catch(err => {
      logger.error("Error Getting History: " + err);
      setLoader(false);
    });
  };

  useEffect(() => {
    if (!historyFromStore || historyFromStore.length === 0) {
      fetchData();
    }
  }, []);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = historyFromStore.slice(indexOfFirstEntry, indexOfLastEntry);

  return (
    <div className={`historyPage mt-5 ${style.historyPage}`}>
      {loader ? <Loader /> : null}
      <div className={`sub-hdr alt0 ${style.subHdrAlter}`}>
        <div className="nib">
          <div className="the-notch">
            <div className="notch-fg">
              <svg>
                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
              </svg>
            </div>
          </div>
        </div>
        <h2 className={` ${style.sectionTitle} m-0 `}>History</h2>
        <div className={style.refreshButton}>
          <button type="button" className={`light-button mr-3 px-3`} onClick={fetchData}>
            Refresh Data
          </button>
        </div>
      </div>
      <div className="sub-section">
        {currentEntries.map((entry, index) => (
          <div key={index}>
            <div className={`row ${style.rowGrp}`}>
              <div className='col-md-4'>
                <ul className={` ${style.productList}`}>
                  <li>Date and Time</li>
                  <li>{entry.moddate} {entry.modtime}</li>
                </ul>
              </div>
              <div className='col-md-4'>
                <ul className={` ${style.productList}`}>
                  <li>User</li>
                  <li>{entry.user}</li>
                </ul>
              </div>
            </div>
            <div className={`row ${style.rowGrp}`}>
              <div className='col-md-4'>
                <ul className={` ${style.productList}`}>
                  <li>Action</li>
                  <li className={style.breakLine}>{entry.action}</li>
                </ul>
              </div>
              <div className='col-md-4'>
                <ul className={` ${style.productList}`}>
                  <li>Modification Type</li>
                  <li>{entry.type ?? 'N/A'}</li>
                </ul>
              </div>
            </div>
            <div className={`row ${style.rowGrp}`}>
              <div className='col-md-8'>
                <ul className={style.noteList}>
                  <li>Note</li>
                  <li className={style.breakLine}>{entry.note.replace(/<br>/g, '\n')}</li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        entriesPerPage={entriesPerPage}
        totalEntries={historyFromStore.length}
        currentPage={currentPage}
        paginate={paginate}
      />
    </div>
  );
};

const Pagination = ({ entriesPerPage, totalEntries, paginate, currentPage }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // Calculate the page range to display
  const maxPagesToShow = 5; // Display a range of 5 pages
  let startPage, endPage;

  if (totalPages <= maxPagesToShow) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      startPage = 1;
      endPage = maxPagesToShow;
    } else if (currentPage + Math.floor(maxPagesToShow / 2) >= totalPages) {
      startPage = totalPages - maxPagesToShow + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - Math.floor(maxPagesToShow / 2);
      endPage = currentPage + Math.floor(maxPagesToShow / 2);
    }
  }

  const visiblePageNumbers = pageNumbers.slice(startPage - 1, endPage);

  return (
    <nav>
      <ul className={style.pagination}>
        {visiblePageNumbers.map(number => (
          <li key={number} className={`${style.pageItem} ${number === currentPage ? style.active : ''}`}>
            <button onClick={() => paginate(number)} className={style.pageLink}>
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const mapDispatchToProps = {
  profileHistory: profileHistory
}

export default connect(null, mapDispatchToProps)(History);