export const SET_USER = "SET_USER";
export const LOG_OUT = "LOG_OUT";
export const USER_MANAGE = "USER_MANAGE";
export const HTTP_404_ERROR = "HTTP_404_ERROR";
export const HTTP_500_ERROR = "HTTP_500_ERROR";
export const HTTP_OTHER_ERROR = "HTTP_OTHER_ERROR";

//My Network Page
export const MY_NETWORK_LIST_MPID = "MY_NETWORK_LIST_MPID";
export const NETWORK_LIST = "NETWORK_LIST";

//Trouble Ticket Page
export const TROUBLE_TICKET_MPID = "TROUBLE_TICKET_MPID";
export const TROUBLE_TICKETS = "TROUBLE_TICKETS";
export const TROUBLE_TICKET_DAY_FILTER = "TROUBLE_TICKET_DAY_FILTER";

//Circuit Health Page
export const CIRCUIT_HEALTH = "CIRCUIT_HEALTH";
export const CIRCUIT_HEALTH_MPID = "CIRCUIT_HEALTH_MPID";

export const ALERT_WINDOW_HOME = "ALERT_WINDOW_HOME";
export const MPLS_NNI_TERM2 = "MPLS_NNI_TERM2";
export const MPLS_NNI_TERM3 = "MPLS_NNI_TERM3";
export const MPLS_NNI_LOCATION = "MPLS_NNI_LOCATION";
export const MPLS_NNI_BW = "MPLS_NNI_BW";
export const MPLS_NNI_ADD = "MPLS_NNI_ADD";
export const MPLS_NNI_STRUCT = "MPLS_NNI_STRUCT";
export const MPLS_NNI_STRUCT_NUM = "MPLS_NNI_STRUCT_NUM"
export const MPLS_NNI_FLOOR_NUM = "MPLS_NNI_FLOOR_NUM";
export const MPLS_NNI_UNIT = "MPLS_NNI_UNIT";
export const MPLS_NNI_UNIT_NUM = "MPLS_NNI_UNIT_NUM";
export const MPLS_NNI_CITY = "MPLS_NNI_CITY";
export const MPLS_NNI_STATE = "MPLS_NNI_STATE";
export const MPLS_NNI_ZIP = "MPLS_NNI_ZIP";

export const QUOTE_CONTACT_NAME = "QUOTE_CONTACT_NAME";
export const QUOTE_NEW_CONTACT_NAME = "QUOTE_NEW_CONTACT_NAME"
export const QUOTE_CONTACT_PHONE = "QUOTE_CONTACT_PHONE";
export const QUOTE_CONTACT_EMAIL = "QUOTE_CONTACT_EMAIL";
export const QUOTE_BUS_NAME = "QUOTE_BUS_NAME";
export const QUOTE_CUST_REF_ID = "QUOTE_CUST_REF_ID";
export const QUOTE_PRICE_MRC = "QUOTE_PRICE_MRC";
export const QUOTE_MANUAL_ICB = "QUOTE_MANAUL_ICB";
export const QUOTE_MANUAL_ICB_COMMENTS = "QUOTE_MANUAL_ICB_COMMENTS";

export const LOC_A_ADDRESS = "LOC_A_ADDRESS";
export const LOC_A_STRUCT = "LOC_A_STRUCT";
export const LOC_A_STRUCT_NUM = "LOC_A_STRUCT_NUM";
export const LOC_A_FLOOR_NUM = "LOC_A_FLOOR_NUM";
export const LOC_A_UNIT = "LOC_A_UNIT";
export const LOC_A_UNIT_NUM = "LOC_A_UNIT_NUM";
export const LOC_A_CITY = "LOC_A_CITY";
export const LOC_A_STATE = "LOC_A_STATE";
export const LOC_A_ZIP = "LOC_A_ZIP";
export const LOC_A_VALID_ADDRESS = "LOC_A_VALID_ADDRESS";
export const LOC_A_EACCESS_VALUES = "LOC_A_EACCESS_VALUES";
export const LOC_A_OVERRIDE = "LOC_A_OVERRIDE";
export const LOC_A_LATITUDE = "LOC_A_LATITUDE";
export const LOC_A_LONGITUDE = "LOC_A_LONGITUDE";
export const LOC_A_LEC_TYPE = "LOC_A_LEC_TYPE";

export const LOC_Z_ADDRESS = "LOC_Z_ADDRESS";
export const LOC_Z_STRUCT = "LOC_Z_STRUCT";
export const LOC_Z_STRUCT_NUM = "LOC_Z_STRUCT_NUM";
export const LOC_Z_FLOOR_NUM = "LOC_Z_FLOOR_NUM";
export const LOC_Z_UNIT = "LOC_Z_UNIT";
export const LOC_Z_UNIT_NUM = "LOC_Z_UNIT_NUM";
export const LOC_Z_CITY = "LOC_Z_CITY";
export const LOC_Z_STATE = "LOC_Z_STATE";
export const LOC_Z_ZIP = "LOC_Z_ZIP";
export const LOC_Z_VALID_ADDRESS = "LOC_Z_VALID_ADDRESS";
export const LOC_Z_OVERRIDE = "LOC_Z_OVERRIDE";
export const LOC_Z_LATITUDE = "LOC_Z_LATITUDE";
export const LOC_Z_LONGITUDE = "LOC_Z_LONGITUDE";

export const QUOTE_CLONE = "QUOTE_CLONE";
export const QUOTE_PRODUCT = "QUOTE_PRODUCT";
export const QUOTE_PRODUCT_CODE = "QUOTE_PRODUCT_CODE";
export const QUOTE_CIRCUIT_TYPE = "QUOTE_CIRCUIT_TYPE";
export const QUOTE_STEP_CODE = "QUOTE_STEP_CODE";
export const ORDER_STEP_CODE = "ORDER_STEP_CODE";
export const QUOTE_TERM_1 = "QUOTE_TERM_1";
export const QUOTE_TERM_2 = "QUOTE_TERM_2";
export const QUOTE_TERM_3 = "QUOTE_TERM_3";
export const QUOTE_TERM_4 = "QUOTE_TERM_4";
export const QUOTE_TERM_5 = "QUOTE_TERM_5";
export const QUOTE_TERM_10 = "QUOTE_TERM_10";
export const QUOTE_NNI_LOCATION = "QUOTE_NNI_LOCATION";
export const QUOTE_ENNI_EXTENSION = "QUOTE_ENNI_EXTENSION";
export const QUOTE_BW = "QUOTE_BW";

//DIA POP
export const QUOTE_ADD_BW_1 = "QUOTE_ADD_BW_1";
export const QUOTE_ADD_BW_2 = "QUOTE_ADD_BW_2";
export const QUOTE_POP_LOCATION = "QUOTE_POP_LOCATION";
export const QUOTE_QTY = "QUOTE_QTY";
export const QUOTE_IPS = "QUOTE_IPS";
export const QUOTE_DDOS = "QUOTE_DDOS";
export const QUOTE_CAM_QTY = "QUOTE_CAM_QTY";
export const QUOTE_DDOS_TYPE = "QUOTE_DDOS_TYPE";
export const QUOTE_BURSTABLE_BILLING_1 = "QUOTE_BURSTABLE_BILLING_1";
export const QUOTE_BURSTABLE_BILLING_2 = "QUOTE_BURSTABLE_BILLING_2";
export const QUOTE_BURSTABLE_BILLING_3 = "QUOTE_BURSTABLE_BILLING_3";
export const QUOTE_COMMIT_BW_1 = "QUOTE_COMMIT_BW_1";
export const QUOTE_COMMIT_BW_2 = "QUOTE_COMMIT_BW_2";
export const QUOTE_COMMIT_BW_3 = "QUOTE_COMMIT_BW_3";

export const QUOTE_ERROR_MSG = "QUOTE_ERROR_MSG";

//COLOCATION
export const QUOTE_FIBER_CROSS_CONNECT = "QUOTE_FIBER_CROSS_CONNECT";
export const QUOTE_RACK_SPACE = "QUOTE_RACK_SPACE";
export const QUOTE_BREAKER_AMP = "QUOTE_BREAKER_AMP";
export const QUOTE_PRIMARY_POWER = "QUOTE_PRIMARY_POWER";
export const QUOTE_SECONDARY_POWER = "QUOTE_SECONDARY_POWER";
export const QUOTE_FUSE_PANEL = "QUOTE_FUSE_PANEL";

//MPLS AGG TAIL & MPLS + INTERNET
export const QUOTE_CPE_TYPE = "QUOTE_CPE_TYPE";
export const QUOTE_INTERNET_ROUTER = "QUOTE_INTERNET_ROUTER";
export const QUOTE_VENDOR_EXCLUSION = "QUOTE_VENDOR_EXCLUSION";
export const QUOTE_ATT_EXCLUSION = "QUOTE_ATT_EXCLUSION";
export const QUOTE_VERIZON_EXCLUSION = "QUOTE_VERIZON_EXCLUSION";
export const QUOTE_LUMEN_EXCLUSION = "QUOTE_LUMEN_EXCLUSION";
export const QUOTE_COMCAST_EXCLUSION = "QUOTE_COMCAST_EXCLUSION";
export const QUOTE_SPECTRUM_EXCLUSION = "QUOTE_SPECTRUM_EXCLUSION";

//DIA PREM Broadband
export const QUOTE_BROADBAND_CABLE = "QUOTE_BROADBAND_CABLE";
export const QUOTE_BROADBAND_ADSL = "QUOTE_BROADBAND_ADSL";
export const QUOTE_BROADBAND_BANDWIDTHS = "QUOTE_BROADBAND_BANDWIDTHS";
export const QUOTE_BAM_KEY = "QUOTE_BAM_KEY";

export const RESET_ACTION = "RESET_ACTION";

//WAVES
export const QUOTE_A_ADDRESS = "QUOTE_A_ADDRESS";
export const QUOTE_A_BW_DISPLAY = "QUOTE_A_BW_DISPLAY";
export const QUOTE_A_BW_DISPLAY_FLAG = "QUOTE_A_BW_DISPLAY_FLAG";
export const QUOTE_Z_ADDRESS = "QUOTE_Z_ADDRESS";
export const QUOTE_Z_BW_DISPLAY = "QUOTE_Z_BW_DISPLAY";
export const QUOTE_Z_BW_DISPLAY_FLAG = "QUOTE_Z_BW_DISPLAY_FLAG";
export const QUOTE_PROTOCOL_1 = "QUOTE_PROTOCOL_1";
export const QUOTE_PROTOCOL_2 = "QUOTE_PROTOCOL_2";
export const QUOTE_PROTOCOL_3 = "QUOTE_PROTOCOL_3";
export const QUOTE_ROUTE_TYPE = "QUOTE_ROUTE_TYPE";
export const QUOTE_DIVERSITY = "QUOTE_DIVERSITY";
export const QUOTE_DIVERSITY_NOTE = "QUOTE_DIVERSITY_NOTE";
export const QUOTE_ROUTE_LATENCY = "QUOTE_ROUTE_LATENCY";
export const QUOTE_KMZ_FILE = "QUOTE_KMZ_FILE";

//ROUTE CREATOR TOOL
export const QUOTE_AVAILABILITY_FLAG = "QUOTE_AVAILABILITY_FLAG";
export const QUOTE_AVAILABILITY_ICON_FLAG = "QUOTE_AVAILABILITY_ICON_FLAG";
export const QUOTE_ROUTE_DETAILS = "QUOTE_ROUTE_DETAILS";
export const QUOTE_PATH_DETAILS = "QUOTE_PATH_DETAILS";
export const QUOTE_PATH_NUMBER = "QUOTE_PATH_NUMBER";
export const QUOTE_KMZ_URL = "QUOTE_KMZ_URL";
export const QUOTE_ROUTE_CAPACITY = "QUOTE_ROUTE_CAPACITY";
export const QUOTE_NETWORK_TYPE_COUNTS = "QUOTE_NETWORK_TYPE_COUNTS";
export const QUOTE_AUTO_PRICES = "QUOTE_AUTO_PRICES";
export const QUOTE_ROUTE_HOPS = "QUOTE_ROUTE_HOPS";
export const QUOTE_ROUTE_FIBER_MILES = "QUOTE_ROUTE_FIBER_MILES";
export const QUOTE_PATH_LATENCY = "QUOTE_PATH_LATENCY";
export const QUOTE_ROUTE_BUILDINGS = "QUOTE_ROUTE_BUILDINGS";
export const QUOTE_NETWORK_EDGES = "QUOTE_NETWORK_EDGES";
export const QUOTE_OTUC_PATH = "QUOTE_OTUC_PATH";
export const QUOTE_NETWORK_PATH = "QUOTE_NETWORK_PATH";
export const QUOTE_ROUTE_REGENS = "QUOTE_ROUTE_REGENS";

//ELINE & EACCESS
export const QUOTE_COS = "QUOTE_COS";
export const QUOTE_LOCA_UNI = "QUOTE_LOCA_UNI";
export const QUOTE_LOCZ_UNI = "QUOTE_LOCZ_UNI";
export const QUOTE_A_VPN_PORT_1 = "QUOTE_A_VPN_PORT_1";
export const QUOTE_A_VPN_PORT_2 = "QUOTE_A_VPN_PORT_2";
export const QUOTE_A_VPN_PORT_3 = "QUOTE_A_VPN_PORT_3";
export const QUOTE_Z_VPN_PORT_1 = "QUOTE_Z_VPN_PORT_1";
export const QUOTE_Z_VPN_PORT_2 = "QUOTE_Z_VPN_PORT_2";
export const QUOTE_Z_VPN_PORT_3 = "QUOTE_Z_VPN_PORT_3";
export const QUOTE_COS_TYPE_1 = "QUOTE_COS_TYPE_1";
export const QUOTE_COS_TYPE_2 = "QUOTE_COS_TYPE_2";
export const QUOTE_COS_TYPE_3 = "QUOTE_COS_TYPE_3";
export const QUOTE_ACCESS_TYPE = "QUOTE_ACCESS_TYPE";

// DARKFIBER
export const QUOTE_DF_OPTIONS = "QUOTE_DF_OPTIONS";
export const QUOTE_DF_TERM = "QUOTE_DF_TERM";
export const QUOTE_DF_NUM_FIBERS = "QUOTE_DF_NUM_FIBERS";
export const QUOTE_DF_COMMENTS = "QUOTE_DF_COMMENTS";

//KINETIC BROADBAND
export const QUOTE_BROADBAND_OBJ = "QUOTE_BROADBAND_OBJ";
export const QUOTE_UQUAL_SEARCH_BY_FLAG = "QUOTE_UQUAL_SEARCH_BY_FLAG";
export const QUOTE_UQUAL_ID = "QUOTE_UQUAL_ID";
export const QUOTE_TYPE_OF_IPS = "QUOTE_TYPE_OF_IPS";
export const QUOTE_MODEM = "QUOTE_MODEM";
export const QUOTE_TERM_MONTH_TO_MONTH = "QUOTE_TERM_MONTH_TO_MONTH";

//OFFICESUITE
export const QUOTE_ASSOC_REF_ID = "QUOTE_ASSOC_REF_ID";
export const QUOTE_TLC_THIS_LOC = "QUOTE_TLC_THIS_LOC";
export const QUOTE_ENT_GROUP = "QUOTE_ENT_GROUP";
export const QUOTE_EXT_TO_EXT = "QUOTE_EXT_TO_EXT";
export const QUOTE_EXT_LENGTH = "QUOTE_EXT_LENGTH";
export const QUOTE_PORTING = "QUOTE_PORTING";
export const QUOTE_BTN = "QUOTE_BTN";
export const QUOTE_OFFICESUITE_QUANTITIES = "QUOTE_OFFICESUITE_QUANTITIES";
export const QUOTE_OFFICESUITE_FEATURES = "QUOTE_OFFICESUITE_FEATURES";
export const UPDATE_FEATURE_PRICING = "UPDATE_FEATURE_PRICING";
export const QUOTE_FEATURE_TOTAL_MRC = "QUOTE_FEATURE_TOTAL_MRC";
export const QUOTE_FEATURE_TOTAL_NRC = "QUOTE_FEATURE_TOTAL_NRC";
export const QUOTE_SUB_TOTAL_MRC = "QUOTE_SUB_TOTAL_MRC";
export const QUOTE_SUB_TOTAL_NRC = "QUOTE_SUB_TOTAL_NRC";
export const QUOTE_TOTAL_MRC = "QUOTE_TOTAL_MRC";
export const QUOTE_TOTAL_NRC = "QUOTE_TOTAL_NRC";

//Ticket create
export const TC_STEP_CODE = "TC_STEP_CODE";
export const TC_PRODUCTS = "TC_PRODUCTS";
export const TC_OPTIONS = "TC_OPTIONS";
export const TC_CIRCUIT_LIST_COUNT = "TC_CIRCUIT_LIST_COUNT";
export const TC_CIRCUIT_SEARCH = "TC_CIRCUIT_SEARCH";
export const TC_CIRCUIT_DETAILS = "TC_CIRCUIT_DETAILS";
export const TC_CIRCUIT_ADDRESS = "TC_CIRCUIT_ADDRESS";
export const TC_TROUBLE_DETAILS = "TC_TROUBLE_DETAILS";
export const TC_CONTACT_DETAILS = "TC_CONTACT_DETAILS";
export const TC_AUTHORIZATION = "TC_AUTHORIZATION";
export const TC_CONTACT_LOCAL = "TC_CONTACT_LOCAL";
export const TC_CONTACT_PRIMARY = "TC_CONTACT_PRIMARY";

export const TC_CUSTOMER_TICKET_NUMBER = "TC_CUSTOMER_TICKET_NUMBER";
export const TC_POWER_VERIFIED = "TC_POWER_VERIFIED";
export const TC_DESCRIPTION_OF_PROBLEM = "TC_DESCRIPTION_OF_PROBLEM";
export const TC_COMPANY_NAME = "TC_COMPANY_NAME";
export const TC_TIME_OF_BOUNCE = "TC_TIME_OF_BOUNCE";
export const TC_BOUNCE_TZ = "TC_BOUNCE_TZ";
export const TC_DISPATCH_AUTHORIZATION = "TC_DISPATCH_AUTHORIZATION";
export const TC_INTRUSIVE_TESTING_AUTHORIZED = "TC_INTRUSIVE_TESTING_AUTHORIZED";
export const TC_TURN_UP = "TC_TURN_UP";
export const TC_TIME_OF_BOUNCEHRS = "TC_TIME_OF_BOUNCEHRS";
export const TC_TYPE_OF_ERRORS = "TC_TYPE_OF_ERRORS";
export const TC_ERROR_RATE = "TC_ERROR_RATE";
export const TC_DATE_OF_CIRCUIT_DOWN = "TC_DATE_OF_CIRCUIT_DOWN";
export const TC_TIME_OF_CIRCUIT_DOWNHRS = "TC_TIME_OF_CIRCUIT_DOWNHRS";
export const TC_CIRCUIT_DOWN_TZ = "TC_CIRCUIT_DOWN_TZ";
export const TC_DATE_OF_DURATION_FROM = "TC_DATE_OF_DURATION_FROM";
export const TC_TIME_OF_DURATION_FROMHRS = "TC_TIME_OF_DURATION_FROMHRS";
export const TC_DATE_OF_DURATION_TO = "TC_DATE_OF_DURATION_TO";
export const TC_TIME_OF_DURATION_TOHRS = "TC_TIME_OF_DURATION_TOHRS";
export const TC_PULL_PERFORMANCE_MONITORING = "TC_PULL_PERFORMANCE_MONITORING";
export const TC_NUMBER_OF_SITES = "TC_NUMBER_OF_SITES";
export const TC_CUSTOMER_PERSONAL_ACCESS = "TC_CUSTOMER_PERSONAL_ACCESS";
export const TC_DATE_OF_PACKETLOSS = "TC_DATE_OF_PACKETLOSS";
export const TC_TIME_OF_PACKETLOSSHRS = "TC_TIME_OF_PACKETLOSSHRS";
export const TC_PERCENTAGE_LOSS = "TC_PERCENTAGE_LOSS";
export const TC_SITES_OUT = "TC_SITES_OUT";
export const TC_LOC_A = "TC_LOC_A";
export const TC_CITY_A = "TC_CITY_A";
export const TC_STATE_A = "TC_STATE_A";
export const TC_ZIP_A = "TC_ZIP_A";
export const TC_LOC_Z = "TC_LOC_Z";
export const TC_CITY_Z = "TC_CITY_Z";
export const TC_STATE_Z = "TC_STATE_Z";
export const TC_ZIP_Z = "TC_ZIP_Z";
export const TC_IRU_NUMBER = "TC_IRU_NUMBER";
export const TC_OTDR_RESULTS = "TC_OTDR_RESULTS";
export const TC_NUMBER_OF_FIBERS = "TC_NUMBER_OF_FIBERS";
export const TC_FIBERS_ASSOCIATED = "TC_FIBERS_ASSOCIATED";
export const TC_INTRUSIVE_FROM = "TC_INTRUSIVE_FROM";
export const TC_INTRUSIVE_FROM_HRS = "TC_INTRUSIVE_FROM_HRS";
export const TC_INTRUSIVE_TO = "TC_INTRUSIVE_TO";
export const TC_INTRUSIVE_TO_HRS = "TC_INTRUSIVE_TO_HRS";
export const TC_INTRUSIVE_TZ = "TC_INTRUSIVE_TZ";

export const TC_CONTACT_LIST = "TC_CONTACT_LIST";

export const TC_P_CONTACT = "TC_P_CONTACT";
export const TC_P_CONTACT_PHONE = "TC_P_CONTACT_PHONE";
export const TC_P_CONTACT_PHONE_EXT = "TC_P_CONTACT_PHONE_EXT";
export const TC_P_PHONE_TYPE = "TC_P_PHONE_TYPE";
export const TC_P_UPDATE_EMAIL = "TC_P_UPDATE_EMAIL";
export const TC_P_CONTACT_EMAIL = "TC_P_CONTACT_EMAIL";
export const TC_P_CONTACT_ID = "TC_P_CONTACT_ID";

export const TC_L_CONTACT = "TC_L_CONTACT";
export const TC_L_CONTACT_PHONE = "TC_L_CONTACT_PHONE";
export const TC_L_CONTACT_PHONE_EXT = "TC_L_CONTACT_PHONE_EXT";
export const TC_L_PHONE_TYPE = "TC_L_PHONE_TYPE";
export const TC_L_UPDATE_EMAIL = "TC_L_UPDATE_EMAIL";
export const TC_L_CONTACT_EMAIL = "TC_L_CONTACT_EMAIL";
export const TC_L_CONTACT_ID = "TC_L_CONTACT_ID";

export const TC_SELECT_DATE = "TC_SELECT_DATE";
export const TC_TIME_FROM = "TC_TIME_FROM";
export const TC_TIME_TO = "TC_TIME_TO";
export const TC_MON_TO_FRI = "TC_MON_TO_FRI";
export const TC_DAY_MON = "TC_DAY_MON";
export const TC_DAY_TUES = "TC_DAY_TUES";
export const TC_DAY_WED = "TC_DAY_WED";
export const TC_DAY_THUR = "TC_DAY_THUR";
export const TC_DAY_FRI = "TC_DAY_FRI";
export const TC_DAY_SAT = "TC_DAY_SAT";
export const TC_DAY_SUN = "TC_DAY_SUN";

export const TC_ERROR_MSG = "TC_ERROR_MSG";

//----------------Order Create, Summary & View-----------------//
export const QUOTED_DATA = "QUOTED_DATA"; // This is the quote as it is viewed from the UI
export const QUOTE_DATA = "QUOTE_DATA"; // This is the quote from the transport 'quote_data' table.
export const QUOTE_PROFILE = "QUOTE_PROFILE";
export const ORDER_DATA = "ORDER_DATA";
export const LOCATION_DATA = "LOCATION_DATA";
export const GET_UPLOADED_FILES = "GET_UPLOADED_FILES";

//Contacts
export const ORDER_PRIMARY_CONTACT = "ORDER_PRIMARY_CONTACT";
export const ORDER_NEW_PRIMARY_CONTACT = "ORDER_NEW_PRIMARY_CONTACT";
export const ORDER_PRIMARY_PHONE = "ORDER_PRIMARY_PHONE";
export const ORDER_PRIMARY_EMAIL = "ORDER_PRIMARY_EMAIL";
export const ORDER_DATA_TECH_CONTACT = "ORDER_DATA_TECH_CONTACT";
export const ORDER_NEW_DATA_TECH_CONTACT = "ORDER_NEW_DATA_TECH_CONTACT"
export const ORDER_DATA_TECH_PHONE = "ORDER_DATA_TECH_PHONE";
export const ORDER_DATA_TECH_EMAIL = "ORDER_DATA_TECH_EMAIL";

//General Order Information
export const ORDER_CUSTOMER_PON = "ORDER_CUSTOMER_PON";
export const ORDER_CUSTOMER_CIRCUIT = "ORDER_CUSTOMER_CIRCUIT";
export const ORDER_REQUESTED_SERVICE = "ORDER_REQUESTED_SERVICE";
export const ORDER_EXPEDITE_REQUEST = "ORDER_EXPEDITE_REQUEST";
export const ORDER_INITIAL_TERM = "ORDER_INITIAL_TERM";
export const ORDER_ACNA = "ORDER_ACNA"

//Service Information
export const ORDER_ACTIVITY_REQUESTED = "ORDER_ACTIVITY_REQUESTED";
export const ORDER_QTY = "ORDER_QTY";
export const ORDER_BW = "ORDER_BW";
export const ORDER_CIRCUIT_LIST_COUNT = "ORDER_CIRCUIT_LIST_COUNT";
export const ORDER_CIRCUIT_SEARCH = "ORDER_CIRCUIT_SEARCH";
export const ORDER_EXISTING_CIRCUIT = "ORDER_EXISTING_CIRCUIT";
export const ORDER_MANUAL_CIRCUIT = "ORDER_MANUAL_CIRCUIT";
export const ORDER_GAID = "ORDER_GAID";
export const ORDER_NUMBER_NNI_LOCATIONS = "ORDER_NUMBER_NNI_LOCATIONS";

//Site Information
export const ORDER_A_CUSTOMER_NAME = "ORDER_A_CUSTOMER_NAME";
export const ORDER_A_LCON_NAME = "ORDER_A_LCON_NAME";
export const ORDER_A_LCON_PHONE = "ORDER_A_LCON_PHONE";
export const ORDER_A_LCON_EMAIL = "ORDER_A_LCON_EMAIL";
export const ORDER_A_HANDOFF = "ORDER_A_HANDOFF";
export const ORDER_A_DEMARCATION = "ORDER_A_DEMARCATION";
export const ORDER_A_FIBER_TYPE = "ORDER_A_FIBER_TYPE";
export const ORDER_A_LOCATION_NOTES = "ORDER_A_LOCATION_NOTES";
export const ORDER_Z_CUSTOMER_NAME = "ORDER_Z_CUSTOMER_NAME";
export const ORDER_Z_LCON_NAME = "ORDER_Z_LCON_NAME";
export const ORDER_Z_LCON_PHONE = "ORDER_Z_LCON_PHONE";
export const ORDER_Z_LCON_EMAIL = "ORDER_Z_LCON_EMAIL";
export const ORDER_Z_HANDOFF = "ORDER_Z_HANDOFF";
export const ORDER_Z_DEMARCATION = "ORDER_Z_DEMARCATION";
export const ORDER_Z_LOCATION_NOTES = "ORDER_Z_LOCATION_NOTES";
export const ORDER_Z_FIBER_TYPE = "ORDER_Z_FIBER_TYPE";

//Product Details
export const ORDER_WAN_REQUIREMENTS = "ORDER_WAN_REQUIREMENTS";
export const ORDER_LAN_REQUIREMENTS = "ORDER_LAN_REQUIREMENTS";
export const ORDER_STATIC_IP = "ORDER_STATIC_IP";

export const ORDER_RAMP_UP = "ORDER_RAMP_UP";
export const ORDER_JURISDICTIONAL_TRAFFIC = "ORDER_JURISDICTIONAL_TRAFFIC";

export const ORDER_EVCS = "ORDER_EVCS";
export const ORDER_MTU_SIZE = "ORDER_MTU_SIZE";
export const ORDER_VLAN = "ORDER_VLAN";
export const ORDER_CUSTOMER_ASSIGNED = "ORDER_CUSTOMER_ASSIGNED";
export const ORDER_LAYER_2_PROTOCOL = "ORDER_LAYER_2_PROTOCOL";
export const ORDER_NNI_CIRCUIT_ID = "ORDER_NNI_CIRCUIT_ID";
export const ORDER_OTHER_FIELD = "ORDER_OTHER_FIELD";
export const ORDER_NNI_INTERCONNECTION_LOC = "ORDER_NNI_INTERCONNECTION_LOC";

export const ORDER_MOUNTING_EQUIPMENT = "ORDER_MOUNTING_EQUIPMENT";
export const ORDER_WAN_SFP = "ORDER_WAN_SFP";
//VSF
export const ORDER_NUMBER_OF_NNI = "ORDER_NUMBER_OF_NNI";
export const ORDER_NNI = "ORDER_NNI";
export const ORDER_MPLS_CIRCUIT = "ORDER_MPLS_CIRCUIT";
export const ORDER_NEW_VLAN = "ORDER_NEW_VLAN";
export const ORDER_REMOVE_VRF_OVERRIDE = "ORDER_REMOVE_VRF_OVERRIDE";
export const ORDER_BGP = "ORDER_BGP";
export const ORDER_PREFIX = "ORDER_PREFIX";
export const ORDER_ASN = "ORDER_ASM";
export const ORDER_NETWORK_NAME = " ORDER_NETWORK_NAME";

//MPLS ROUTING
export const ORDER_MPLS_ROUTE = "ORDER_MPLS_ROUTE";
export const ORDER_OWN_IP = "ORDER_OWN_IP";
export const ORDER_BGP_PREFIX = "ORDER_BGP_PREFIX";
export const ORDER_REMOVE_OVERRRIDE = "ORDER_REMOVE_OVERRRIDE";
export const ORDER_BGP_COMM = "ORDER_BGP_COMM";
export const ORDER_REMOTE_ASN = "ORDER_REMOTE_ASN";
export const ORDER_QOS = "ORDER_QOS";
export const ORDER_MPLS_STATIC_IP = "ORDER_MPLS_STATIC_IP";
export const ORDER_WAN_IP = "ORDER_WAN_IP";

//Review Order
export const ORDER_UPLOAD_TYPE = "ORDER_UPLOAD_TYPE";
export const ORDER_NOTES = "ORDER_NOTES";
export const ORDER_NOTES_INTERNAL = "ORDER_NOTES_INTERNAL";
export const ORDER_GENERAL_NOTES = "ORDER_GENERAL_NOTES";
export const ORDER_TERMS_CONDITIONS = "ORDER_TERMS_CONDITIONS";
export const ORDER_SERVICE_FORM = "ORDER_SERVICE_FORM";

// MAC-D Create
export const MACD_STEP_CODE = "MACD_STEP_CODE";
export const MACD_CIRCUIT_LIST_COUNT = "MACD_CIRCUIT_LIST_COUNT";
export const MACD_CIRCUIT_SEARCH = "MACD_CIRCUIT_SEARCH";
export const MACD_CIRCUIT_GAID = "MACD_CIRCUIT_GAID";
// Product Selection
export const MACD_PRODUCT = "MACD_PRODUCT";
export const ACTIVITY_REQUEST = "ACTIVITY_REQUEST";
export const ACTIVITY_TYPE = "ACTIVITY_TYPE";
export const MACD_CIRCUIT_DETAILS = "MACD_CIRCUIT_DETAILS";

//Contacts
export const MACD_PRIMARY_CONTACT = "MACD_PRIMARY_CONTACT";
export const MACD_NEW_PRIMARY_CONTACT = "MACD_NEW_PRIMARY_CONTACT";
export const MACD_PRIMARY_PHONE = "MACD_PRIMARY_PHONE";
export const MACD_PRIMARY_EMAIL = "MACD_PRIMARY_EMAIL";
export const MACD_DATA_TECH_CONTACT = "MACD_DATA_TECH_CONTACT";
export const MACD_NEW_DATA_TECH_CONTACT = "MACD_NEW_DATA_TECH_CONTACT";
export const MACD_DATA_TECH_PHONE = "MACD_DATA_TECH_PHONE";
export const MACD_DATA_TECH_EMAIL = "MACD_DATA_TECH_EMAIL";

//General Order Information
export const MACD_CUSTOMER_PON = "MACD_CUSTOMER_PON";
export const MACD_CUSTOMER_CIRCUIT = "MACD_CUSTOMER_CIRCUIT";
export const MACD_ACNA = "MACD_ACNA"
export const MACD_LEASENET_ID = "MACD_LEASENET_ID";
export const MACD_CIRCUIT_ID = "MACD_CIRCUIT_ID";

//Service Information
export const MACD_ESCORT_ACCESS_REQUEST = "MACD_ESCORT_ACCESS_REQUEST";
export const MACD_REQ_SER_DATE = "MACD_REQ_SER_DATE";
export const MACD_END_BILLING_DATE = "MACD_END_BILLING_DATE";
export const MACD_CIRCUIT_TYPE = "MACD_CIRCUIT_TYPE";
export const MACD_BW = "MACD_BW";
export const MACD_QTY = "MACD_QTY";
export const MACD_IPS = "MACD_IPS";
export const MACD_CABLE_FID = "MACD_CABLE_FID";
export const MACD_NUM_OF_FIBERS = "MACD_NUM_OF_FIBERS";
export const MACD_NUM_OF_ROUTES = "MACD_NUM_OF_ROUTES";
export const MACD_FIBER_ARRAY = "MACD_FIBER_ARRAY";
export const MACD_SPLICE_ARRAY = "MACD_SPLICE_ARRAY";
export const MACD_DISPATCH_OTSIDE_WIN_BUSINESS_HRS = "MACD_DISPATCH_OTSIDE_WIN_BUSINESS_HRS";
export const MACD_TEST_TYPE = "MACD_TEST_TYPE";
export const MACD_DIRECTION = "MACD_DIRECTION";
export const MACD_DESIGN_INQUIRY_NUM = "MACD_DESIGN_INQUIRY_NUM";
export const MACD_EXISTING_TN = "MACD_EXISTING_TN";

export const MACD_ACCOUNT_NUMBER = "MACD_ACCOUNT_NUMBER";
export const MACD_MANUAL_CIRCUIT_ID = "MACD_MANUAL_CIRCUIT_ID";
export const MACD_DISCO_NETWORK = "MACD_DISCO_NETWORK";
export const MACD_EXISTING_CIRCUIT_ID = "MACD_EXISTING_CIRCUIT";
export const MACD_EXISTING_CIRCUIT_ID1 = "MACD_EXISTING_CIRCUIT_ID1";
export const MACD_EXISTING_CIRCUIT_ID2 = "MACD_EXISTING_CIRCUIT_ID2";
export const MACD_EDGE_DEVICE = "MACD_EDGE_DEVICE";

//Site Information
export const MACD_A_LATITUDE = "MACD_A_LATITUDE";
export const MACD_A_LONGITUDE = "MACD_A_LONGITUDE";
export const MACD_A_ADDRESS = "MACD_A_ADDRESS";
export const MACD_A_ADDRESS2 = "MACD_A_ADDRESS2";
export const MACD_A_CITY = "MACD_A_CITY";
export const MACD_A_STATE = "MACD_A_STATE";
export const MACD_A_ZIP = "MACD_A_ZIP";
export const MACD_A_FLOOR = "MACD_A_FLOOR";
export const MACD_A_ONSITE_NAME = "MACD_A_ONSITE_NAME";
export const MACD_A_ONSITE_PHONE = "MACD_A_ONSITE_PHONE";
export const MACD_A_ONSITE_EMAIL = "MACD_A_ONSITE_EMAIL";
export const MACD_A_FRAMING = "MACD_A_FRAMING";
export const MACD_A_LINE_CODE = "MACD_A_LINE_CODE";
export const MACD_A_HANDOFF = "MACD_A_HANDOFF";
export const MACD_A_FIBER_CONNECTOR = "MACD_A_FIBER_CONNECTOR";
export const MACD_A_COLO_POP = "MACD_A_COLO_POP";

export const MACD_Z_LATITUDE = "MACD_Z_LATITUDE";
export const MACD_Z_LONGITUDE = "MACD_Z_LONGITUDE";
export const MACD_Z_ADDRESS = "MACD_Z_ADDRESS";
export const MACD_Z_ADDRESS2 = "MACD_Z_ADDRESS2";
export const MACD_Z_CITY = "MACD_Z_CITY";
export const MACD_Z_STATE = "MACD_Z_STATE";
export const MACD_Z_ZIP = "MACD_Z_ZIP";
export const MACD_Z_FLOOR = "MACD_Z_FLOOR";
export const MACD_Z_ONSITE_NAME = "MACD_Z_ONSITE_NAME";
export const MACD_Z_ONSITE_PHONE = "MACD_Z_ONSITE_PHONE";
export const MACD_Z_ONSITE_EMAIL = "MACD_Z_ONSITE_EMAIL";
export const MACD_Z_FRAMING = "MACD_Z_FRAMING";
export const MACD_Z_LINE_CODE = "MACD_Z_LINE_CODE";
export const MACD_Z_HANDOFF = "MACD_Z_HANDOFF";
export const MACD_Z_FIBER_CONNECTOR = "MACD_Z_FIBER_CONNECTOR";

export const MACD_CONNECTOR_TYPE = "MACD_CONNECTOR_TYPE";
export const MACD_LIVE_EQUIPMENT = "MACD_LIVE_EQUIPMENT";
export const MACD_RACK_INFO = "MACD_RACK_INFO";
export const MACD_PANEL_INFO = "MACD_PANEL_INFO";
export const MACD_PORT_FIBER_ASSIGNMENT = "MACD_PORT_FIBER_ASSIGNMENT";

//SD-WAN Equipment Shipping Address
export const MACD_SHIPPING_ADDRESS = "MACD_SHIPPING_ADDRESS";
export const MACD_SHIPPING_LD1 = "MACD_SHIPPING_LD1";
export const MACD_SHIPPING_LV1 = "MACD_SHIPPING_LV1";
export const MACD_SHIPPING_LV2 = "MACD_SHIPPING_LV2";
export const MACD_SHIPPING_LD3 = "MACD_SHIPPING_LD3";
export const MACD_SHIPPING_LV3 = "MACD_SHIPPING_LV3";
export const MACD_SHIPPING_CITY = "MACD_SHIPPING_CITY";
export const MACD_SHIPPING_STATE = "MACD_SHIPPING_STATE";
export const MACD_SHIPPING_ZIP = "MACD_SHIPPING_ZIP";
export const MACD_SHIPPING_CONTACT = "MACD_SHIPPING_CONTACT";
export const MACD_SHIPPING_CONTACT_NEW = "MACD_SHIPPING_CONTACT_NEW";
export const MACD_SHIPPING_PHONE = "MACD_SHIPPING_PHONE";
export const MACD_SHIPPING_EMAIL = "MACD_SHIPPING_EMAIL";

//Review Order
export const MACD_UPLOAD_TYPE = "MACD_UPLOAD_TYPE";
export const MACD_UPLOAD_FILENAMES = "MACD_UPLOAD_FILENAMES";
export const MACD_NUMBER_OF_UPLOADS = "MACD_NUMBER_OF_UPLOADS";
export const MACD_INSERT_NOTES = "MACD_INSERT_NOTES";
export const MACD_ORDER_NOTES = "MACD_ORDER_NOTES";

export const MACD_RESET = "MACD_RESET";

export const SESSION_ERROR_MESSAGE = "SESSION_ERROR_MESSAGE";

//Employee Profile
export const SELECTED_PROFILE = "SELECTED_PROFILE";
export const COMPANY = "COMPANY";
export const WF_COMPANY = "WF_COMPANY";
export const COMPANY_ROLLUP = "COMPANY_ROLLUP";
export const F_NAME = "F_NAME";
export const L_NAME = "L_NAME";
export const EMAIL = "EMAIL";
export const PHONE = "PHONE";
export const EXT = "EXT";
export const JURISDICTION = "JURISDICTION";
export const ACCOUNT_VERTICAL = "ACCOUNT_VERTICAL";
export const AGENT_FLAG = "AGENT_FLAG";
export const API_ADDRESS_FLAG = "API_ADDRESS_FLAG";
export const AE = "AE";
export const CAM = "CAM";
export const SE = "SE";
export const FIXED_WIRELESS_LAYER3 = "FIXED_WIRELESS_LAYER3";
export const FIXED_WIRELESS_EACCESS = "FIXED_WIRELESS_EACCESS";
export const ONNET_ONLY = "ONNET_ONLY";
export const NCTC = "NCTC";
export const EIS = "EIS";
export const OFFNET_VENDOR_VISIBLE = "OFFNET_VENDOR_VISIBLE";
export const BILLING_PROFILE_GLOBAL_ID = "BILLING_PROFILE_GLOBAL_ID";
export const COMMS_ACCOUNT = "COMMS_ACCOUNT";
export const BAN = "BAN";
export const ACNA = "ACNA";
export const PROFILE_RESET = "PROFILE_RESET";
export const PROFILE_CHANGE = "PROFILE_CHANGE";
export const UNIQUE_ID = "UNIQUE_ID";
export const NEW_PROFILE = "NEW_PROFILE";
export const PROFILE_USER_TABLE = "PROFILE_USER_TABLE";
export const PROFILE_HISTORY = "PROFILE_HISTORY"

//CAMP Accounts
export const ACNAS = "ACNAS";
export const CAMP_ACCOUNTS = "CAMP_ACCOUNTS";
export const CAMP_ACCOUNTS_DOWNLOAD = "CAMP_ACCOUNTS_DOWNLOAD";
//Profile Products & Discounts
export const PRIVATE_LINE = "PRIVATE_LINE";
export const MPLS_AGG_TAIL = "MPLS_AGG_TAIL";
export const MPLS_INTERNET = "MPLS_INTERNET";
export const MPLS_AGG_NNI = "MPLS_AGG_NNI";
export const DIA_POP = "DIA_POP";
export const DIA_PREM = "DIA_PREM";
export const COLOCATION = "COLOCATION";
export const ELINE = "ELINE";
export const E_ACCESS = "E_ACCESS";
export const E_ACCESS_NNI = "E_ACCESS_NNI";
export const DARK_FIBER = "DARK_FIBER";
export const BROADBAND = "BROADBAND";
export const MANAGED_SPECTRUM = "MANAGED_SPECTRUM_DISCOUNT";
export const PRIVATE_LINE_DISCOUNT = "PRIVATE_LINE_DISCOUNT";
export const MPLS_AGG_TAIL_DISCOUNT = "MPLS_AGG_TAIL_DISCOUNT";
export const MPLS_INTERNET_DISCOUNT = "MPLS_INTERNET_DISCOUNT";
export const MPLS_AGG_NNI_DISCOUNT = "MPLS_AGG_NNI_DISCOUNT";
export const DIA_POP_DISCOUNT = "DIA_POP_DISCOUNT";
export const DIA_PREM_DISCOUNT = "DIA_PREM_DISCOUNT";
export const COLOCATION_DISCOUNT = "COLOCATION_DISCOUNT";
export const ELINE_DISCOUNT = "ELINE_DISCOUNT";
export const E_ACCESS_DISCOUNT = "E_ACCESS_DISCOUNT";
export const E_ACCESS_NNI_DISCOUNT = "E_ACCESS_NNI_DISCOUNT";
export const DARK_FIBER_DISCOUNT = "DARK_FIBER_DISCOUNT";
export const MANAGED_SPECTRUM_DISCOUNT = "MANAGED_SPECTRUM_DISCOUNT";
export const BROADBAND_DISCOUNT = "BROADBAND_DISCOUNT";
// NNIs
export const NNIS = "NNIS";
// API
export const API_ENABLED = "API_ENABLED";
export const API_EMAIL = "API_EMAIL";

//INTERNAL ICONNNECT
export const COMPANY_MENU = "COMPANY_MENU";
export const COMPANY_MPID = "COMPANY_MPID";
export const COMPANY_MPID_RESET = "COMPANY_MPID_RESET";
export const AGENCY = "AGENCY";
export const AUTO_NOBID = "AUTO_NOBID";
export const DISABLE_CUST_EMAIL = "DISABLE_CUST_EMAIL";
export const RENEWAL_QUOTE = "RENEWAL_QUOTE";
export const PREV_QUOTE_NUM = "PREV_QUOTE_NUM";
export const EXISTING_QUOTE_CIRCUIT_ID = "EXISTING_QUOTE_CIRCUIT_ID";
export const MANUAL_QUOTE_CIRCUIT_ID = "MANUAL_QUOTE_CIRCUIT_ID";
export const END_TERM_DATE = "END_TERM_DATE";
export const LEGACY_NETWORK = "LEGACY_NETWORK";
export const BILLING_SYSTEM = "BILLING_SYSTEM";
export const OFFNET = "OFFNET";
export const NON_COMMISSIONABLE = "NON_COMMISSIONABLE";
export const MARKUP = "MARKUP";
export const WHOLESALE_OPPORTUNITY = "WHOLESALE_OPPORTUNITY";
export const PRICING_OVERRIDE = "PRICING_OVERRIDE";
export const AGENT = "AGENT";
export const AGENT_NAME = "AGENT_NAME";
export const AGENT_PHONE = "AGENT_PHONE";
export const AGENT_FEE = "AGENT_FEE";
export const AGENT_EMAIL = "AGENT_EMAIL";
export const AGENT_ADDRESS = "AGENT_ADDRESS";
export const AGENT_CITY = "AGENT_CITY";
export const AGENT_STATE = "AGENT_STATE";
export const AGENT_ZIP = "AGENT_ZIP";
export const ENTERPRISE_REP = "ENTERPRISE_REP";
export const ENTERPRISE_REP_OBJ = "ENTERPRISE_REP_OBJ";
export const RESET_INTERNAL_QUOTE = "RESET_INTERNAL_QUOTE";
export const QUICKBASE_TASKS = "QUICKBASE_TASKS";


// POP Editor
// Administration
export const POP_UID = "POP_UID";
export const POP_MPID = "POP_MPID";
export const POP_TIER = "POP_TIER";
export const POP_CSWC = "POP_CSWC";
export const POP_LOCATION_ID = "POP_LOCATION_ID";

// Address
export const POP_CITY = "POP_CITY";
export const POP_STATE = "POP_STATE";
export const POP_COUNTRY = "POP_COUNTRY";
export const POP_ZIP = "POP_ZIP";
export const POP_NPANXX = "POP_NPANXX";
export const POP_VCOORD = "POP_VCOORD";
export const POP_AV_CSWC = "POP_AV_CSWC";
export const POP_ADDRESS = "POP_ADDRESS";
export const POP_OCN = "POP_OCN";
export const POP_LATA = "POP_LATA";
export const POP_SWITCH_CLLI = "POP_SWITCH_CLLI";
export const POP_HCOORD = "POP_HCOORD";
export const POP_VALIDATED = "POP_VALIDATED";
export const POP_COUNTY = "POP_COUNTY";
export const POP_GEOCODE = "POP_GEOCODE";
export const POP_LATITUDE = "POP_LATITUDE";
export const POP_LONGITUDE = "POP_LONGITUDE";
export const POP_RATECENTER = "POP_RATECENTER";
export const POP_SANO = "POP_SANO";
export const POP_SAPR = "POP_SAPR";
export const POP_SASD = "POP_SASD";
export const POP_SASF = "POP_SASF";
export const POP_SASN = "POP_SASN";
export const POP_SASS = "POP_SASS";
export const POP_SATH = "POP_SATH";

// POP Detail
export const POP_NOTE = "POP_NOTE";
export const POP_LEC_TYPE = "POP_LEC_TYPE";
export const POP_UNITI_MLA = "POP_UNITI_MLA";
export const POP_VENDOR = "POP_VENDOR";
export const POP_NEW_LOC_TYPE = "POP_NEW_LOC_TYPE";
export const POP_LD1 = "POP_LD1";
export const POP_LD2 = "POP_LD2";
export const POP_LD3 = "POP_LD3";
export const POP_LV1 = "POP_LV1";
export const POP_LV2 = "POP_LV2";
export const POP_LV3 = "POP_LV3";
export const POP_PROVIDER = "POP_PROVIDER";
export const POP_ADDRESS2 = "POP_ADDRESS2";

// Wave Details
export const POP_ICON = "POP_ICON";
export const POP_WAVE = "POP_WAVE";
export const POP_WAVE_XC = "POP_WAVE_XC";
export const POP_LANDLORD = "POP_LANDLORD";
export const POP_DCI = "POP_DCI";
export const POP_WAVE_TIER = "POP_WAVE_TIER";
export const POP_WAVE_CLASS = "POP_WAVE_CLASS";
export const POP_WAVE_TYPE = "POP_WAVE_TYPE";
export const POP_WAVE_METRO_AREA = "POP_WAVE_METRO_AREA";
export const POP_WAVE_LCN = "POP_WAVE_LCN";
export const POP_G10 = "POP_G10";
export const POP_G100 = "POP_G100";
export const POP_G400 = "POP_G400";
export const POP_G800 = "POP_G800";

// Ethernet Details
export const POP_ELINE_ENET = "POP_ELINE_ENET";
export const POP_ETHERNET = "POP_ETHERNET";

// Colo Details
export const POP_COLO = "POP_COLO";
export const POP_COLO_TIER = "POP_COLO_TIER";

export const CLEAR_REDUX_STORE = "CLEAR_REDUX_STORE";
