import { useSelector } from 'react-redux';

const TabsEmployee = (props) => {
    const profileChangeTabFromStore = useSelector(state => state.employeeProfile?.profile?.selectedProfileTab);
    const selectedMpidFromStore = useSelector(state => state.internal.company);
    const agentFlag = useSelector(state => state.employeeProfile?.profile?.agent_flag);

    const navClick = (link) => {
        props.handleNav(link);
    }

    return (
        <div className="tab-nav cf">
            <a href className={`tab-opt ${profileChangeTabFromStore === 'Profile' ? 'active' : ''} `} onClick={() => { navClick('Profile') }}>
                <span>Profile</span>
            </a>
            {selectedMpidFromStore?.value && selectedMpidFromStore?.value !== 'new' ?
                <>
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'Accounts' ? 'active' : ''}`} onClick={() => { navClick('Accounts') }}>
                        <span>Accounts</span>
                    </a>
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'Products' ? 'active' : ''}`} onClick={() => { navClick('Products') }}>
                        <span>Products</span>
                    </a>
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'NNIs' ? 'active' : ''}`} onClick={() => { navClick('NNIs') }}>
                        <span>NNIs</span>
                    </a>
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'API' ? 'active' : ''}`} onClick={() => { navClick('API') }}>
                        <span>API</span>
                    </a>
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'ADD_USER' ? 'active' : ''}`} onClick={() => { navClick('ADD_USER') }}>
                        <span>Add User</span>
                    </a>
                    {/*Only want to display this tab when employee clicks the user id link on the profile page*/}
                    {profileChangeTabFromStore === "EDIT_USER" ?
                        <a href className={`tab-opt ${profileChangeTabFromStore === 'EDIT_USER' ? 'active' : ''}`}>
                            <span>Edit User</span>
                        </a> : null
                    }
                    {agentFlag === '1' ?
                        <a href className={`tab-opt ${profileChangeTabFromStore === 'AGENT' ? 'active' : ''}`}>
                            <span>Agent</span>
                        </a> : null
                    }
                    {agentFlag === '2' ?
                        <a href className={`tab-opt ${profileChangeTabFromStore === 'AGENT_CUSTOMER' ? 'active' : ''}`}>
                            <span>Agent Customer</span>
                        </a> : null
                    }
                    <a href className={`tab-opt ${profileChangeTabFromStore === 'History' ? 'active' : ''}`} onClick={() => { navClick('History') }}>
                        <span>History</span>
                    </a>
                </>
                : null}
        </div>
    );
}

export default TabsEmployee;