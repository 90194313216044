import style from './NetworkViewer.module.css';
import CompanyDropdown from '../shared/CompanyDropdown';
import L from "leaflet";
import NetworkViewerHelper from '../../services/NetworkViewerService';
import FiberMappingHelper from '../../services/FiberMappingService';
import hopIcon from 'leaflet/dist/images/marker-icon.png';
import popIcon from '../../images/marker-icon-violet.png';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tippy';
import { ConsoleLogger } from "../../logger/ConsoleLogger";


const NetworkViewer = () => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [loader, setLoader] = useState(false);
	const [allCircuitsFlag, setAllCircuitsFlag] = useState(true);
	const [circuitList, setCircuitList] = useState([]);
	const [commsId, setCommsId] = useState("");
	const [searchText, setSearchText] = useState('');
	const [circuitListForSearch, setCircuitListForSearch] = useState([]);
	const [noCircuitsFound, setNoCircuitsFound] = useState(false);
	const [leafletJsonData, setLeafletJsonData] = useState([]);
	const [relatedCircuits, setRelatedCircuits] = useState({});
	const [allCircuitsCheckbox, setAllCircuitCheckbox] = useState(true);
	const [loadingMessage, setLoadingMessage] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [showDemoPopup, setShowDemoPopup] = useState(false);

	//Redux store variables
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);

	//Local storage variables
	const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
	const user = localStorage.getItem("user");

	//Leaflet map variables - reference variables
	//Used to draw inital map and rerender the map with additional layers
	const mapRef = useRef(null); //Initial Map reference
	const hopsLayerRef = useRef(null); //Group of hops
	const markerLayerRef = useRef(null); //Group of markers
	const polylineLayerRef = useRef(null); //Group of polylines
	const iconNetworkLayerRef = useRef(null); //icon network
	const longHaulNetworkLayerRef = useRef(null); //longhaul
	const regionalNetworkLayerRef = useRef(null); //regional
	const ilecExchangeLayerRef = useRef(null); //ilec exchanges

	useEffect(() => {
		initializeOverallMap();
		fetchIconNetwork();
		fetchLongHaulNetwork();
		fetchRegionalNetwork();
		fetchIlecExchanges();
	}, [])

	useEffect(() => {
		if (isEmployee) {
			if (selectedInternalMpid) {
				setErrorMsg("");
				clearMapLayers();
				setCircuitList([]);
				getCircuitRouteIds();
			}
		} else {
			getCircuitRouteIds();
		}
	}, [selectedInternalMpid])

	//Initializes map, icon, and layers
	const initializeOverallMap = () => {
		mapRef.current = L.map(`map`, {
			center: [39.0997, -94.5786],
			zoom: 5,
			layers: [
				L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
					attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
					maxZoom: 19,
					className: `${style.leafletTileLayerGreyScale}`
				}),
			]
		});

		hopsLayerRef.current = L.layerGroup().addTo(mapRef.current); //Adding empty group of hops to the map so we can clear all hops
		markerLayerRef.current = L.layerGroup().addTo(mapRef.current); //Adding empty group of markers to the map so we can clear all markers
		polylineLayerRef.current = L.layerGroup().addTo(mapRef.current); //Adding empty group of polylines to the map so we can clear all polylines
		iconNetworkLayerRef.current = L.layerGroup().addTo(mapRef.current); //Adding empty group of the icon network to the map
		longHaulNetworkLayerRef.current = L.layerGroup().addTo(mapRef.current);//Adding empty group of the longhaul network to the map
		regionalNetworkLayerRef.current = L.layerGroup().addTo(mapRef.current);//Adding empty group of the regional network to the map
		ilecExchangeLayerRef.current = L.layerGroup().addTo(mapRef.current); //Adding empty group of the ilec exchange network to the map

		new L.Control.Layers(null, {
			'Hop Locations': hopsLayerRef.current,
			'Customer Route <span style="background-color:#ffb81c; width:10px; height:10px; display:inline-block;"></span>': polylineLayerRef.current,
			'Icon Network <span style="background-color:purple; width:10px; height:10px; display:inline-block;"></span>': iconNetworkLayerRef.current,
			'Longhaul Network <span style="background-color:blue; width:10px; height:10px; display:inline-block;"></span>': longHaulNetworkLayerRef.current,
			'Regional Network <span style="background-color:black; width:10px; height:10px; display:inline-block;"></span>': regionalNetworkLayerRef.current,
			'Ilec Exchanges <span style="background-color: #59cbe8; width:10px; height:10px; display:inline-block;"></span>': ilecExchangeLayerRef.current
		}, { collapsed: false }).addTo(mapRef.current);

		var click_elements = document.getElementsByClassName("leaflet-control-layers-selector");
		// set all layers off in addtion to the first layer
		for (let i = 0; i < click_elements.length; i++) {
			logger.info("click_elements: " + JSON.stringify(click_elements));
			click_elements[i].checked = false;
		}

		click_elements[1].click();

	}

	//Removing all layers for map to be able to redraw
	const clearMapLayers = () => {
		if (markerLayerRef.current) {
			markerLayerRef.current.clearLayers();
		}

		if (hopsLayerRef.current) {
			hopsLayerRef.current.clearLayers();
		}

		if (polylineLayerRef.current) {
			polylineLayerRef.current.clearLayers();
		}
	}

	//Fetching icon network data to display static layer of our overall icon network
	const fetchIconNetwork = () => {
		NetworkViewerHelper.getIconNetworkJson().then(response => {
			L.geoJSON(response.data, {
				style: function () {
					return { color: "purple" };
				},
				onEachFeature: function (feature, layer) {
					layer.on({
						'add': function () {
							layer.bringToBack()
						}
					})
				}
			}).addTo(iconNetworkLayerRef.current);
		}).catch(error => {
			logger.info(error);
		})
	}

	//Fetching longhaul network data to display static layer of our longhaul network
	const fetchLongHaulNetwork = () => {
		NetworkViewerHelper.getLongHaulNetworkJson().then(response => {
			L.geoJSON(response.data, {
				onEachFeature: function (feature, layer) {
					layer.on({
						'add': function () {
							layer.bringToBack()
						}
					})
				}
			}).addTo(longHaulNetworkLayerRef.current);
		}).catch(error => {
			logger.info(error);
		})
	}

	//Fetching regional network data to display static layer of our regional network
	const fetchRegionalNetwork = () => {
		NetworkViewerHelper.getRegionalNetworkJson().then(response => {
			L.geoJSON(response.data, {
				style: function () {
					return {
						color: "black",
						weight: 1
					};
				},
				onEachFeature: function (feature, layer) {
					layer.on({
						'add': function () {
							layer.bringToBack()
						}
					})
				}
			}).addTo(regionalNetworkLayerRef.current);
		}).catch(error => {
			logger.info(error);
		})
	}

	//Fetching ilec exchange data to display static layer of our ilec exchanges from a shape file that was converted to geojson
	const fetchIlecExchanges = () => {
		NetworkViewerHelper.getIlecExchangesJson().then(response => {
			L.geoJSON(response?.data, {
				style: function () {
					return {
						color: "#59cbe8",
						fill: true,
						fillColor: "#59cbe8",
						fillRule: "nonzero"
					}
				},
				onEachFeature: function (feature, layer) {
					layer.on({
						'add': function () {
							layer.bringToBack()
						}
					})
				}
			}).addTo(ilecExchangeLayerRef.current);
		})
	}

	//Looping through the data and excluded an circuits that are not selected
	const drawLeafletMap = (data, excludedCircuits) => {
		var defaultIcon = L.icon({
			iconUrl: hopIcon,
			iconSize: [20, 36],
			className: `${style.leafletSmallCustomMarker}`
		});

		var locA_ZIcon = L.icon({
			iconUrl: popIcon,
			iconSize: [25, 41],
			className: `${style.leafletCustomMarker}`
		});
		var path;
		if (!excludedCircuits) {
			excludedCircuits = [];
		}

		//We need to clear the map layer everytime we render the map
		clearMapLayers();

		data.forEach((obj) => {
			//logger.info(obj);
			Object.entries(obj).forEach(([designId, value]) => {
				if (!((excludedCircuits).includes(designId))) {
					//looping through all the markers
					value.nodes.forEach((node) => {
						node.forEach((point) => {
							//Adding markers to markerLayer group and displaying them on map
							if (point.type === "a_z") {
								var marker = L.marker([point.coordinates[0], point.coordinates[1]], { icon: locA_ZIcon }).addTo(markerLayerRef.current);
								//clli tooltip
								if (point.location) {
									marker.on('mouseover', function () {
										marker.bindTooltip(point.location).openTooltip();
									})
								}
							} else {
								var hops = L.marker([point.coordinates[0], point.coordinates[1]], { icon: defaultIcon }).addTo(hopsLayerRef.current);
								//clli tooltip
								if (point.location) {
									hops.on('mouseover', function () {
										hops.bindTooltip(point.location).openTooltip();
									})
								}
							}
						})

					})

					let pathCoordinates = [];

					//looping through all the fiber lines
					value.edges.forEach((item) => {
						for (var key1 in item) {
							for (var key2 in item[key1]) {
								var coordinates = item[key1][key2].coordinates;
								var latLngs = coordinates.map(function (coord) {

									return L.latLng(coord[0], coord[1]);
								});
								pathCoordinates.push(latLngs);
							}
						}
					});

					//Drawing the polyline and adding it to polyline layer group
					path = L.polyline(pathCoordinates, {
						color: "#ffb81c",
						weight: 5
					}).addTo(polylineLayerRef.current);

					var originalTargetPopup;
					//Highlights the path that is being hovered over and changing color to the Windstream yellow/orange color
					path.on('mouseover', function (event) {
						var layer = event.target;
						layer.setStyle({
							color: "#ffb81c",
							opacity: 1,
							weight: 10
						});

					})

					//Adding click event to the path to trigger popup
					path.on('click', function (event) {
						originalTargetPopup = event.target;
						let designIdLookup = [];
						//Checking to see if there are multiple circuits on the same path
						if (relatedCircuits) {
							logger.info("Inside of related Circuits");
							logger.info("Related Circuits: " + JSON.stringify(relatedCircuits));
							Object.entries(relatedCircuits).forEach(([id, value]) => {
								logger.info("id: " + id);
								logger.info("key: " + designId);
								if (id === designId) {
									logger.info("Pushing: " + id);
									designIdLookup = value;
								}
							})
						}
						//Preventing duplicate design ids from getting into the designIdLookup Array
						if (!designIdLookup.includes(designId)) {
							designIdLookup.push(designId);
						}
						setLoader(true);
						setLoadingMessage("Pulling circuit details. Thank you for your patience and for choosing Windstream!");
						//Get additional circuit data and bind the popup to the location that was clicked
						NetworkViewerHelper.getAdditionalCircuitData(designIdLookup).then(response => {
							logger.info(response.data);
							let content = `
								<p><b style="font-weight: bolder;">Circuit Id(s): </b>${response.data?.circuitIds}</p>
								<p style="margin: 0px;"><b style="font-weight: bolder;">Name: </b>${response.data?.customerName}</p>
								<p style="margin: 0px;"><b style="font-weight: bolder;">Location A Address: <br></b>${response.data?.a_address}, ${response.data?.a_city_state_zip}</p>
								<p style="margin: 0px;"><b style="font-weight: bolder;">Location A Clli: </b>${response.data?.a_clli}</p><br>
								<p style="margin: 0px;"><b style="font-weight: bolder;">Location Z Address:<br></b>${response.data?.z_address}, ${response.data?.z_city_state_zip}</p>
								<p style="margin: 0px;"><b style="font-weight: bolder;">Location Z Clli: </b>${response.data?.a_clli}</p><br>
								<p style="margin: 0px;"><b style="font-weight: bolder;">Rate Code: </b>${response.data?.rateCode}</p><br>
							`;
							//Hide get full ciruit path button for demo account
							if(user !== "Wholesale_Demo" && selectedInternalMpid !== 8683){
								content += `<button id="btnGetFullCircuitPath" style="color:purple; text-decoration: underline; padding-left: 0px;">Click to get Full Circuit Path <br></br> </button>`;
							}
							originalTargetPopup.bindPopup(content).openPopup();
							setLoader(false);
						}).catch(error => {
							logger.info(error);
							setLoader(false);
						})
					});

					//Get full circuit path if button is clicked in the original popup
					path.on("popupopen", function () {
						document.getElementById("btnGetFullCircuitPath").addEventListener("click", function () {
							logger.info("Full circuit path button was clicked");
							setLoadingMessage("Pulling full circuit path. Thank you for your patience and for choosing Windstream!");
							setLoader(true);
							NetworkViewerHelper.getFullCircuitPath(designId).then(response => {
								logger.info(response.data);
								let existingContent = originalTargetPopup.getPopup().getContent();
								let newContent = existingContent + response.data;
								originalTargetPopup.setPopupContent(newContent).openPopup();
								setLoader(false);
							}).catch(error => {
								logger.info(error);
								setLoader(false);
							})
						});
					})



					//Removes the highlight from the path when the mouse is out and changes the path color back to the Windstream purple
					path.on('mouseout popupclose', function (event) {
						var layer = event.target;
						layer.setStyle({
							color: "#ffb81c",
							opacity: 1,
							weight: 5
						});
					})

				} else {
					logger.info("excluded circuit: " + designId);
				}

			});
		});
	}


	//Function to get leaflet json data for all circuits on the commsId
	const fetchLeafletJsonData = (commsId, designIds) => {
		setLoader(true);
		setLoadingMessage("Pulling all circuit details may take a minute. Thank you for your patience and for choosing Windstream!");

		if(selectedInternalMpid === 8683 || user === "Wholesale_Demo") {
			logger.info("Pulling static akamai demo json data");
			NetworkViewerHelper.getLeafletDemoJson().then(response => {
				logger.info(response.data);
				setLeafletJsonData(response.data.leaflet_json);
				setRelatedCircuits(response.data.related_ckt_group_lkp);
				drawLeafletMap(response.data.leaflet_json);
				setLoader(false);
			}).catch(error => {
				logger.info(`Error getting akamai demo json: ${error}`);
				setLoader(false);
			})
		} else {
			NetworkViewerHelper.getLeafletJsonData(commsId, designIds).then(response => {
				if (response.data[0] === "No Mongo Data Found") {
					setErrorMsg("No circuit data found!");
					setLoader(false);
				} else {
					logger.info("Leaflet Json: " + response.data.leaflet_json);
					logger.info("Related Circuits: " + JSON.stringify(response.data.related_ckt_group_lkp));
					setLeafletJsonData(response.data.leaflet_json);
					setRelatedCircuits(response.data.related_ckt_group_lkp);
					drawLeafletMap(response.data.leaflet_json);
					setLoader(false);
				}
			}).catch(error => {
				logger.info(error);
				setLoader(false);
			})
		}
	}

	//Function to pull all circuit ids for the selected company
	const getCircuitRouteIds = () => {
		if(Number(selectedInternalMpid) === 8683 || user === "Wholesale_Demo") {
			logger.info("Pulling static akamai circuit id list");
			NetworkViewerHelper.getAllCircuitDemoIds().then(response => {
				logger.info(response.data);
				setCircuitList(response.data);
				setCircuitListForSearch(response.data);
				setCommsId(response.gaid);
				let allDesignIds = [];
				Object.entries(response.data).forEach(([key, value]) => {
					allDesignIds.push(key);
				})
				fetchLeafletJsonData(response.gaid, allDesignIds);
			}).catch(error => {
				logger.info(`Error getting static akamai demo circuit id list: ${error}`);
				setLoader(false);
			})
		} else {
			NetworkViewerHelper.getAllCircuitIds().then(response => {
				logger.info(response.data);
				setCircuitList(response.data);
				setCircuitListForSearch(response.data);
				setCommsId(response.gaid);
				if (response.data?.length === 0) {
					setNoCircuitsFound(true);
				} else {
					setNoCircuitsFound(false);
				}
				let allDesignIds = [];
				Object.entries(response.data).forEach(([key, value]) => {
					allDesignIds.push(key);
				})

				fetchLeafletJsonData(response.gaid, allDesignIds);
			}).catch(error => {
				logger.error(`getCircuitOptions Error: ${error}`);
			})
		}
	}


	//Function to search circuit list for a specific circuit
	const searchHandle = (event) => {
		let searchValue = event.target.value;
		setSearchText(searchValue);
		let filterCircuitList = Object.entries(circuitList).filter(([, val]) => {
			searchValue = searchValue.toLocaleLowerCase();
			let santizedText = searchValue.replace(/[/\s]/g, "");
			let circuitToMatch = val.label.toLocaleLowerCase().replace(/[/\s]/g, "");
			return circuitToMatch.includes(santizedText);
		});
		filterCircuitList = Object.fromEntries(filterCircuitList);
		setCircuitListForSearch(filterCircuitList);
	}

	//Handler to check or uncheck a circuit and then add it to the excluded it circuits to hide it from the leaflet map
	const circuitHandle = (event, circuit) => {
		let isChecked = event.target.checked;
		let updatedCircuitListData = [];
		let updatedCircuitListDataForSearch = [];
		let excludedCircuits = []

		if (circuit === "allCircuits") {
			updatedCircuitListData = { ...circuitList };
			updatedCircuitListDataForSearch = { ...circuitListForSearch };
			Object.entries(updatedCircuitListData).forEach(([key]) => {
				//logger.info("key: " + key);
				updatedCircuitListData[key].checked = isChecked
			});
			Object.entries(updatedCircuitListDataForSearch).forEach(([key]) => {
				//logger.info("key: " + key);
				updatedCircuitListDataForSearch[key].checked = isChecked
			});
			setAllCircuitCheckbox(isChecked);
		} else {
			updatedCircuitListData = { ...circuitList, [event.target.id]: { ...circuitList[event.target.id] } }
			updatedCircuitListData[event.target.id].checked = isChecked

			updatedCircuitListDataForSearch = { ...circuitListForSearch, [event.target.id]: { ...circuitListForSearch[event.target.id] } }
			updatedCircuitListDataForSearch[event.target.id].checked = isChecked
		}

		setCircuitListForSearch(updatedCircuitListDataForSearch);
		setCircuitList(updatedCircuitListData);

		//Exclude any circuit id that is not checked
		Object.entries(updatedCircuitListData).forEach(([, val]) => {
			if (!val.checked) {
				excludedCircuits.push(val.designId);
			}
		});

		logger.info("Circuits to be removed from leaflet map: " + excludedCircuits);
		drawLeafletMap(leafletJsonData, excludedCircuits);
	}

	//Handles the individual circuit checkbox
	const handleInvidividualCircuit = () => {
		if (allCircuitsFlag) {
			let updatedCircuitListData = [];
			updatedCircuitListData = { ...circuitList }
			Object.entries(updatedCircuitListData).forEach(([key]) => {
				logger.info("key: " + key);
				updatedCircuitListData[key].checked = true
			});
			setAllCircuitCheckbox(true);
			setCircuitListForSearch(updatedCircuitListData);
			setCircuitList(updatedCircuitListData);
		} else {
			setAllCircuitsFlag(true);
			// draw the entire map with no exclusions
			drawLeafletMap(leafletJsonData, []);
		}
		setAllCircuitsFlag(!allCircuitsFlag);
	}

	//Download kmz for all selected circuits
	const downloadMultiKMZ = (circuits) => {
		if(selectedInternalMpid === 8683 || user === "Wholesale_Demo") {
			logger.info("Disabling KMZ download for demo experience");
			setShowDemoPopup(true);
			return;
		}
		let selectedCircuits = [];
		Object.entries(circuits).forEach(([, val]) => {
			if (val.checked) {
				selectedCircuits.push(val.designId);
			}
		})

		setLoader(true);
		setLoadingMessage("Downloading KMZ for all selected circuits may take a minute. Thank you for your patience and for choosing Windstream!");
		FiberMappingHelper.getMultiKmz(commsId, selectedCircuits).then(response => {
			logger.info(`getMultiKmz response: ${response.data}`);
			if (response.data.result === "SUCCESS") {
				console.info("Successfully got a token for the kmz that contains all selected circuits");
				//downloading excel file
				let selectedCircuitsKmzlink = document.createElement('a');
				selectedCircuitsKmzlink.href = response.data.url;
				selectedCircuitsKmzlink.download = response.data.filename;
				selectedCircuitsKmzlink.click();
				setLoader(false);
			} else {
				console.info("Error getting token for selected circuit kmz download file");
				setLoader(false);
			}
		}).catch(error => {
			logger.info(`getMultiKmz error: ${error}`);
			setLoader(false);
		})
	}

	//Download kmz for all circuits
	const downloadAllKMZ = () => {
		logger.info("Download All KMZ Action");
		if(selectedInternalMpid === 8683 || user === "Wholesale_Demo") {
			logger.info("Disabling KMZ download for demo experience");
			setShowDemoPopup(true);
			return;
		}
		setLoader(true);
		setLoadingMessage("Downloading KMZ for all circuits may take a minute. Thank you for your patience and for choosing Windstream!");
		FiberMappingHelper.getAllCircuitsKmz(commsId).then(response => {
			logger.info(`getAllCircuitKmz response: ${response.data}`);
			if (response.data.result === "SUCCESS") {
				console.info("Successfully got a token for the kmz that contains all circuits");
				let allCircuitKmzlink = document.createElement('a');
				allCircuitKmzlink.href = response.data.url;
				allCircuitKmzlink.download = response.data.filename;
				allCircuitKmzlink.click();
				setLoader(false);
			} else {
				console.info("Error getting token for all circuit kmz download file");
				setLoader(false);
			}
		}).catch(error => {
			logger.info(`getAllCircuitKmz error: ${error}`);
			setLoader(false);
		})
	}

	return (
		<>
			<div className="midsection employee abs z1">
				<div className="rel">
					<div className="wrap">
						<h1 className="page-title mb-0">Network Viewer</h1>
						{isEmployee ? <CompanyDropdown /> : null}
						<h6 className="page-title mt-0 mb-5">Select existing Wave, Dark Fiber, or Managed Spectrum circuits below to show their fiber routes and download the KMZ file.</h6>
						<div className="section">
							<div className="content">
								<div className="d-flex">
									<div className="w-25 collapse" id="individualCircuitMenu">
										<div class={`dropdown-menu show px-2 ${style.dropdownMenu}`}>
											<div class="form-group">
												<input type="text" className={`form-control ${style.searchByCircuitText}`} id="circuitIdSearch" placeholder="Search By Circuit" value={searchText} onChange={(event) => { searchHandle(event) }} disabled={noCircuitsFound ? "disabled" : ""} />
											</div>
											<div class="form-check">
												<input type="checkbox" class={`form-check-input ${style.checkbox}`} id="allCircuits" checked={allCircuitsCheckbox ? "checked" : ""} onChange={(event) => { circuitHandle(event, "allCircuits") }} />
												<label class={`form-check-label ${style.checkboxLabel}`} for="allCircuits">
													All Circuits
												</label>
											</div>
											{Object.entries(circuitListForSearch).map(([key, val]) => (
												<>
													<div key={key} class="form-check">
														<input type="checkbox" class={`form-check-input ${style.checkbox}`} id={val.designId} checked={val.checked} onChange={(event) => { circuitHandle(event, val) }} />
														<label class={`form-check-label ${style.checkboxLabel}`} for={val.designId}>
															{val.label}
														</label>
													</div>
												</>
											))}
											{noCircuitsFound ? <p className="text-danger">No Available Circuits</p> : null}
										</div>
									</div>
									<div className={`${allCircuitsFlag ? "w-100" : "w-75"}`}>
										{errorMsg?.length > 0 ? <p className="text-danger text-center">{errorMsg}</p> :
											<>
												<div className="d-flex mb-3">
													<div className="row ml-2 w-50">
														<Tooltip html={<span>{allCircuitsFlag ? "Show individual circuits" : "Show full circuit view"}</span>} size="small">
															<button className="light-button py-1 px-3" type="button" data-toggle="collapse" data-target="#individualCircuitMenu" aria-expanded="false" aria-controls="individualCircuitMenu" onClick={() => { handleInvidividualCircuit() }}>
																{allCircuitsFlag ? "Individual Circuits" : "Full Circuit View"}
															</button>
														</Tooltip>
													</div>
													<div className="row ml-2 w-50 justify-content-end">
														{!allCircuitsFlag ?
															<Tooltip html={<span>Download KMZ of selected circuits</span>} size="small">
																<button className='light-button py-1 px-3' onClick={() => downloadMultiKMZ(circuitList)}>Download KMZ</button>
															</Tooltip> : null
														}
														<Tooltip html={<span>Download all circuits listed below without the need to select them.</span>} size="small">
															<button className='light-button py-1 px-3 ml-2' onClick={() => downloadAllKMZ()}>Download All Circuits KMZ</button>
														</Tooltip>
													</div>
												</div>
											</>
										}
										<div className={`${style.leafletMap}`} id="map" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{loader ?
				<>
					<div className={`${style.overlay}`}></div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-md}`}>
							<div className="modal-content text-center">
								<div className="modal-body">
									<span>
										{loadingMessage}
									</span>
									<div className="row mt-2">
										<div className="col-12 text-center">
											<div className='spinner-border spinner-border-lg ml-2' role='status' style={{ borderColor: '#753bbd', borderRightColor: 'transparent' }} />
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</> : null
			}
			{showDemoPopup ?
				<>
					<div className={`${style.overlay}`}></div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-md}`}>
							<div className="modal-content text-center">
								<div className="modal-content">
									<div className="modal-body">
										Kmz download is disabled for the demo experience.
									</div>
									<div className="modal-footer d-block text-center">
										<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => setShowDemoPopup(false)}>Ok</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</> : null
			}
		</>
	)
}

export default NetworkViewer;
