import { useState, useEffect } from "react";
import Select from "react-select";
import style from "../Employee.module.css";
import StaffManagementService from "../../../services/StaffManagementService";
import { ConsoleLogger } from "../../../logger/ConsoleLogger";
import CancelModal from "./CancelModal";
import { SelectComponent } from "../../components/SelectComponent";
import { InputComponent } from "../../components/InputComponent";
import Loader from "../../shared/Loader";

const EditUser = ({ formValues, setFormValues }) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const [loader, setLoader] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [cancelWindow, setCancelWindow] = useState(false);
    const [employeeID, setEmployeeID] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [employeeList, setEmployeeList] = useState([]);

    const webAccessOptions = [
        { label: 'No Access', value: 0 },
        { label: 'Read Only', value: 1 },
        { label: 'Write Access', value: 2 },
        { label: 'Full Access', value: 3 }
    ];

    useEffect(() => {
        // Load employee data only if it hasn't been loaded yet
        if (employeeList.length === 0) {
            setLoader(true);
            const staffListJSON = {
                "action": "list",
            };
            StaffManagementService.getUserData(staffListJSON)
                .then((resp) => {
                    logger.info("UserData returned: " + JSON.stringify(resp.data.data));
                    const formattedData = Array.isArray(resp.data.data) ? resp.data.data.map(employee => ({
                        value: employee.userId,
                        label: `${employee.userId} - ${employee.lastName}, ${employee.firstName}`,
                        ...employee
                    })) : [resp.data.data].map(employee => ({
                        value: employee.userId,
                        label: `${employee.userId} - ${employee.lastName}, ${employee.firstName}`,
                        ...employee
                    }));
                    setEmployeeList(formattedData); // Set state with employee data
                    setLoader(false);

                    // Set initial employeeID based on formValues.userId
                    const selectedEmployee = formattedData.find(emp => emp.value === formValues.userId);
                    setEmployeeID(selectedEmployee || null);
                })
                .catch((error) => {
                    logger.error("StaffManagementService ERROR: " + error);
                    setLoader(false);
                    setErrorMsg(error.message || 'An error occurred');
                });
        } else {
            // If data is already loaded, set the employeeID directly
            const selectedEmployee = employeeList.find(emp => emp.value === formValues.userId);
            setEmployeeID(selectedEmployee || null);
        }
    }, [formValues.userId]);

    const employeIDHandle = (selectedOption) => {
        if (selectedOption) {
            setEmployeeID(selectedOption);

            const mapWebAccess = (accessLevels) => {
                const accessMap = {
                    'No Access': 0,
                    'Read Only': 1,
                    'Write Only': 2,
                    'Full Access': 3
                };

                return {
                    webUserAdmin: accessMap[accessLevels['Web User Admin']] || 0,
                    troubleTickets: accessMap[accessLevels['Trouble Tickets']] || 0,
                    quote: accessMap[accessLevels['Transport Quote']] || 0,
                    order: accessMap[accessLevels['Transport Order']] || 0,
                    invoiceSystem: accessMap[accessLevels['Invoice System']] || 0,
                    generalTools: accessMap[accessLevels['Profile Administration']] || 0,
                    staffOrder: accessMap[accessLevels['Bulk Address Processing']] || 0,
                    bulkAV: accessMap[accessLevels['Deprecated Profile/Bulk AV']] || 0,
                    profile: accessMap[accessLevels['Profile Administration']] || 0,
                };
            };

            setFormValues({
                userId: selectedOption.userId,
                firstName: selectedOption.firstName,
                lastName: selectedOption.lastName,
                email: selectedOption.email,
                status: selectedOption.status,
                webAccess: mapWebAccess(selectedOption.webAccess),
            });

            setFormErrors({});  // Reset form errors
        } else {
            setEmployeeID(null);
            setFormValues({}); // Reset form values if no employee is selected
            setFormErrors({});  // Reset form errors if no employee is selected
        }
    };

    const editCallback = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const radioBtnAction = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const submitJSON = {
        action: "update",
        uid: formValues.userId,
        fname: formValues.firstName,
        lname: formValues.lastName,
        email: formValues.email,
        webaccess: {
            generalTools: formValues.webAccess?.generalTools || 0,
            webUserAdmin: formValues.webAccess?.webUserAdmin || 0,
            quote: formValues.webAccess?.quote || 0,
            order: formValues.webAccess?.order || 0,
            invoiceSystem: formValues.webAccess?.invoiceSystem || 0,
            troubleTickets: formValues.webAccess?.troubleTickets || 0,
            staffOrder: formValues.webAccess?.staffOrder || 0,
            bulkAV: formValues.webAccess?.bulkAV || 0,
            profile: formValues.webAccess?.profile || 0
        }
    };

    const submitAction = () => {
        const validation = ValidationRule();
        if (Object.keys(validation).length === 0) {
            console.log("Submitting JSON: ", JSON.stringify(submitJSON, null, 2));
            setLoader(true);

            StaffManagementService.getUserData(submitJSON)
                .then((response) => {
                    if (response.data.result === "FAIL") {
                        const errorMessage = response.data.error || 'Failed to update user information.';
                        logger.error("Update failed: " + errorMessage);
                        setErrorMsg(errorMessage);
                    } else {
                        logger.info("Update successful: " + JSON.stringify(response.data));
                        const successMessage = response.data.update || 'User updated successfully!';
                        setSuccessMsg(successMessage);
                    }
                    setLoader(false);
                })
                .catch((error) => {
                    logger.error("Update failed: " + error);
                    setErrorMsg(error.message || 'Failed to update user information.');
                    setLoader(false);
                });
        } else {
            logger.error("Validation errors: " + JSON.stringify(validation));
        }
    };

    const ValidationRule = () => {
        const error = {};
        if (!formValues.userId) {
            error.userId = "Employee ID is Required";
        }
        if (!formValues.firstName) {
            error.firstName = "First Name is Required";
        }
        if (!formValues.lastName) {
            error.lastName = "Last Name is Required";
        }
        if (!formValues.email) {
            error.email = "Email is Required";
        }
        //if (!formValues.phoneNumber) {
        //    error.phoneNumber = "Phone Number is Required";
        //}
        if (!formValues.webAccess.webUserAdmin) {
            error.webUserAdmin = "Web User Administration is Required";
        }
        if (!formValues.webAccess.troubleTickets) {
            error.troubleTickets = "Trouble Tickets are Required";
        }
        if (!formValues.webAccess.quote) {
            error.quote = "Transport Quote is Required";
        }
        if (!formValues.webAccess.order) {
            error.order = "Transport Order is Required";
        }
        if (!formValues.webAccess.invoiceSystem) {
            error.invoiceSystem = "Invoice System is Required";
        }
        //if (!formValues.webAccess.roleType) {
        //    error.roleType = "Role Type is Required";
        //}
        if (!formValues.status) {
            error.status = "Status is Required";
        }
        setFormErrors(error);
        return error;
    };

    const cancelAction = () => {
        setCancelWindow(true);
    };

    const cancelEmit = (action) => {
        if (action === 'Ok') {
            setCancelWindow(false);
            setFormValues({});  // Reset form values
            setFormErrors({});
            setEmployeeID(null);
        } else {
            setCancelWindow(false);
        }
    };

    return (
        <div className="EditUserPage">
            {loader ? <Loader /> : null}
            {errorMsg?.length > 0 ? <p className="text-danger text-center">{errorMsg}</p> : null}
            {successMsg.length ? <p className={`text-center text-success`}>{successMsg}</p> : null}
            <p className="text-secondary text-right mb-5"><span className={style.mandatory}>*</span> Denotes a required field</p>
            <div className="row">
                <div className="col-md-5 col-sm-12">
                    <div className={`${style.selectWrap} w-100`}>
                        <label className='labelText'> Employee Id <span className={` ${style.mandatory} `}>*</span></label>
                        <div className="rel">
                            <Select
                                value={employeeID}
                                onChange={employeIDHandle}
                                options={employeeList}
                                isSearchable
                                isClearable
                                className={`autoCompleteStyle`}
                                classNamePrefix='profile-autoComplete'
                                noOptionsMessage={() => 'No Profile Found'} />
                            <p className={style.errorMsg}>{formErrors.userId}</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <InputComponent label='First Name' name="firstName" className={style.w45} value={formValues.firstName} error={formErrors.firstName} callback={editCallback} />
                        <InputComponent label='Last Name' name="lastName" className={style.w45} value={formValues.lastName} error={formErrors.lastName} callback={editCallback} />
                    </div>
                    <InputComponent label='Email' name="email" className="w-100" value={formValues.email} error={formErrors.email} callback={editCallback} />
                    <InputComponent label='Phone Number' name="phoneNumber" className="w-100" value={formValues.phoneNumber} error={formErrors.phoneNumber} callback={editCallback} />

                    <div className="my-5">
                        <label className="mr-5">Status <span className={` ${style.mandatory} `}>*</span></label>
                        <input type="radio" name="status" id="active" value='Active' checked={formValues.status === 'Active'} onChange={radioBtnAction}></input>
                        <label className="mr-4 text-secondary" htmlFor="active">Active</label>

                        <input type="radio" name="status" id="inActive" value='InActive' checked={formValues.status === 'InActive'} onChange={radioBtnAction}></input>
                        <label className="text-secondary" htmlFor="inActive">InActive</label>
                        <p className={style.errorMsg}>{formErrors.status}</p>
                    </div>
                </div>

                <div className="col-md-7 col-sm-12">
                    <label className="d-block w-100 text-center mb-5">Web Access</label>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Web User Administration" name="webUserAdmin" className={style.w45} value={formValues.webAccess.webUserAdmin} options={webAccessOptions} error={formErrors.webUserAdmin} callback={editCallback} />
                        <SelectComponent label="Trouble Tickets" name="troubleTickets" className={style.w45} value={formValues.webAccess.troubleTickets} options={webAccessOptions} error={formErrors.troubleTickets} callback={editCallback} />
                    </div>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Transport Quote" name="quote" className={style.w45} value={formValues.webAccess.quote} options={webAccessOptions} error={formErrors.quote} callback={editCallback} />
                        <SelectComponent label="Transport Order" name="order" className={style.w45} value={formValues.webAccess.order} options={webAccessOptions} error={formErrors.order} callback={editCallback} />
                    </div>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Invoice System" name="invoiceSystem" className={style.w45} value={formValues.webAccess.invoiceSystem} options={webAccessOptions} error={formErrors.invoiceSystem} callback={editCallback} />
                        <SelectComponent label="General Tools" name="generalTools" className={style.w45} value={formValues.webAccess.generalTools} options={webAccessOptions} error={formErrors.generalTools} callback={editCallback} />
                    </div>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Staff Order" name="staffOrder" className={style.w45} value={formValues.webAccess.staffOrder} options={webAccessOptions} error={formErrors.staffOrder} callback={editCallback} />
                        <SelectComponent label="Bulk AV" name="bulkAV" className={style.w45} value={formValues.webAccess.bulkAV} options={webAccessOptions} error={formErrors.bulkAV} callback={editCallback} />
                    </div>
                    <div className="d-flex justify-content-between ml-md-5 ml-sm-0">
                        <SelectComponent label="Profile" name="profile" className={style.w45} value={formValues.webAccess.profile} options={webAccessOptions} error={formErrors.profile} callback={editCallback} />
                        <SelectComponent label="Role Type" name="roleType" className={style.w45} value={formValues.roleType} options={webAccessOptions} error={formErrors.roleType} callback={editCallback} />
                    </div>
                </div>
            </div>

            <div className="text-center my-5">
                <button type="button" className={`ico-button lhs light-button ml-3 ${style.cancelBtn}`} onClick={cancelAction}>
                    Cancel
                </button>

                <button type="button" className={`ico-button lhs light-button ml-3 ${style.nextBtn}`} onClick={submitAction}>
                    Submit
                </button>
            </div>
            {cancelWindow ? <CancelModal cancelEmit={cancelEmit} /> : null}{cancelWindow ? <CancelModal cancelEmit={cancelEmit} /> : null}
        </div>
    );
};

export default EditUser;