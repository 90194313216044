import style from "./Component.module.css";
import {useEffect} from "react";

export const SelectComponent = ({callback, label, value, name, className, options, error}) => {
    const handleCallback = (event) => {
        callback(event)
    }
    return (
        <div className={`my-3 ${className}`}>
            <label className='labelText'> {label} <span className={` ${style.mandatory} `}>*</span></label>
            <select name={name} className={`nice-select dark-select wide ${style.select}`} value={value} onChange={(e) => { handleCallback(e) }}>
                <option>Select</option>
                {
                    options?.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))
                }
            </select>
            <p className={style.errorMsg}>{error}</p>
        </div>
    )
}