
import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const ClonePOP = ({ selectedPopDetails, clearErrorMsg, resetToInitialValues }) => {
    const history = useHistory();

    const clonePop = () => {
        clearErrorMsg();
        resetToInitialValues(); // Reset fields to initial values

        const newPopDetails = {
            ...selectedPopDetails,
            uid: 'newPOP', // Assign a new temporary UID
        };

        // Redirect to the POP editor page with the new UID
        history.push(`/POPEditor/${newPopDetails.uid}`, {
            isClone: true,
            originalUid: selectedPopDetails.uid,
        });
    };

    return (
        <button className="action ico-link light-ico tipped" data-tip data-for='clone-POP' onClick={clonePop}>
            <svg className="ico">
                <path d="M33,21V35H15V8h9l9,9v2H22V13h2v4h7l-7-7H17V33H31V21ZM10,12V6h2v4h2v2H10Zm2-9H5V26h8v2H3V1h9l6,6H16Z"></path>
            </svg>
            <ReactTooltip id='clone-POP' aria-haspopup='true' className='tooltipCustom'>
                <p className='m-0'>Clone POP</p>
            </ReactTooltip>
        </button>
    );
};

export default ClonePOP;