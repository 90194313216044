import BootstrapTable from 'react-bootstrap-table-next';
import { useEffect } from 'react';
import style from './RouteCreator.module.css';

const columns = [{
    dataField: 'property',
    text: 'Property'
}, {
    dataField: 'value',
    text: 'Value'
}];

const PathDetails = (props) => {
    var map;
    const google = window.google = window.google ? window.google : {};
    const defaultLatlng = { 'lat': 39, 'lng': -98 };

    var styles = [
        {
            "stylers": [
                { "hue": "#EEEEEE" },
                { "saturation": -300 },
                { "lightness": 20 }
            ]
        }
    ]

    useEffect(() => {
        kmlInit(props.kmzUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //Initializes the map
    function initMap() {
        map = new google.maps.Map(document.getElementById("map"+props.path), {
            center: defaultLatlng,
            zoom: 5,
            mapTypeId: "terrain"
        });
        map.setOptions({ styles: styles });
    }

    const kmlInit = async (src) => {
        await initMap();
        var kmlLayer = new google.maps.KmlLayer(src, {
            suppressInfoWindows: true,
            preserveViewport: false,
            map: map
        });
        kmlLayer.addListener('click', function (event) {
            var content = event.featureData.description;
            console.log(JSON.stringify(event.featureData));
            if (content !== "" && content !== "description") {
                let infoWindow = new google.maps.InfoWindow({
                    position: event.latLng,
                });
                infoWindow.setContent(content);
                infoWindow.open(map);
            }
        });

        //Creating legend for map
        let legend = document.createElement("div");
        legend.id = "legend" + props.path;
        legend.className = `${style.availabilityLegend}`;
        legend.innerHTML = '<h5>Legend</h5>';

        var colors = {
            red: 'Buried Fiber',
            blue: 'Aerial Fiber'
        };

        for (var key in colors) {
            var color = colors[key];
            var name = key;
            var div = document.createElement('div');
            div.innerHTML = '<span style="background-color:' + name + '; width:20px; height:20px; display:inline-block;"></span> ' + color;
            legend.appendChild(div);
        }
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legend);
    }

    return (
        <>
        <div className="d-flex">
            <div className="w-50 table">
                {props.details?.length > 0 ?
                    <BootstrapTable
                        bootstrap4
                        keyField='property'
                        data={props.details}
                        columns={columns}
                        condensed
                        bordered
                        striped hover
                        rowClasses={`${style.routeCreatorDataTable}`}
                    /> : null
                }
            </div>
            <div className="w-50" id={`map${props.path}`}></div>
        </div>
        </>
    )
}

export default PathDetails;
