import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import { connect, useSelector } from 'react-redux';
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import ProfileService from "../../services/ProfileServices";
import Loader from "../shared/Loader";
import { acnas, camp_accounts, camp_accounts_download } from '../../redux';
import { ExportXLSX } from '../../utils/ExportXLSX';
import axios from 'axios';
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import { Typography } from "@mui/material";
import style from "./Accounts.module.css";

const Accounts = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
    const profileFromStore = useSelector(state => state.employeeProfile.profile);
    const accountsFromStore = useSelector(state => state.employeeProfile.accounts || {});
    const [addACNA, setAddACNA] = useState(false);
    const [addCAMPAccount, setAddCAMPAccount] = useState(false);
    const [editCAMPAccount, setEditCAMPAccount] = useState(false);
    const [ErrorMSG, setErrorMSG] = useState('');
    const [SuccessMSG, setSuccessMSG] = useState('');
    const [loader, setLoader] = useState(false);
    const [rowData, setRowData] = useState({});
    const [pageSize, setPageSize] = useState(25);
    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    const columns = [
        {
            field: 'ban',
            headerName: 'Billing Account',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        },
        {
            field: 'gaid',
            headerName: 'Global Account',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        },
        {
            field: 'name',
            headerName: 'Account Name',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        },
        {
            field: 'system',
            headerName: 'Billing System',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        },
        {
            field: 'Action',
            headerName: 'Action',
            renderCell: (params) => formatAction(params, "Edit"),
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.25,
        }
    ];

    const acnaColumns = [
        {
            field: 'acna',
            headerName: 'ACNA',
            flex: 1,
        },
        {
            field: 'Action',
            headerName: 'Action',
            renderCell: (params) => formatAction(params, "Delete"),
            flex: 0.5,
        }
    ];

    function formatSearchHeader(params) {
        return <div>
            <span style={{ marginLeft: 8, color: "#753BBD" }}>{params.colDef.headerName}</span>
        </div>;
    }

    const formatAction = (params, action) => {
        return (
            <Typography>
                <button type="button" className={`light-button mb-3 mt-1 mx-3`} onClick={() => {
                    if (action === "Edit") {
                        editAccount(params.row)
                    } else if (action === "Delete") {
                        deleteAcna(params.row);
                    }
                }}>
                    <span>{action}</span>
                </button>
            </Typography>
        )
    }

    const deleteAcna = (acna) => {
        setLoader(true);
        let submitJSON = {
            "selectedProfile": profileFromStore?.selectedProfile?.value,
            "action": "delete",
            "acna": acna.acna
        }
        logger.info("Delete ACNA Data: " + JSON.stringify(submitJSON));
        ProfileService.submitAcna(submitJSON).then(resp => {
            logger.info('Delete ACNA Submit Response: ' + encodeURIComponent(resp.data.result).replace(/%20/g, " "));
            if (resp.data.result === "SUCCESS") {
                ProfileService.getACNAS(profileFromStore?.selectedProfile?.value).then(res => {
                    props.acnas(res.data);
                    logger.info("Reload ACNAS: " + encodeURIComponent(JSON.stringify(res.data)).replace(/%20/g, " "));
                }).catch(er => {
                    setErrorMSG(er);
                    logger.error("Error Getting ACNAs: " + er);
                })
                setSuccessMSG("ACNA " + acna.acna + " was successfully deleted.");
                setLoader(false);
            } else {
                logger.error("ACNA Submit Error: " + encodeURIComponent(resp.data.error).replace(/%20/g, " "));
                if (resp?.data?.error?.length > 0) {
                    setErrorMSG(resp.data.error);
                } else {
                    setErrorMSG("An error occurred and the ACNA was not deleted.")
                }
                setLoader(false);
            }
        })
    }

    const addACNAAction = () => {
        setAddACNA(!addACNA);
    }

    const addCAMP = () => {
        setAddCAMPAccount(!addCAMPAccount);
    }

    const editAccount = (data) => {
        setRowData(data);
        setEditCAMPAccount(!editCAMPAccount);
    }

    const modalAction = () => {
        setAddACNA(false);
        setAddCAMPAccount(false);
        setEditCAMPAccount(false);
    }

    const refreshAccountData = () => {
        setLoader(true);
        ProfileService.getCampAcansAccount().then(axios.spread(({ data: camp }, { data: acnas }) => {
            props.camp_accounts(camp.campAccounts || []);
            props.camp_accounts_download(camp.campAccountsDownload || []);
            props.acnas(acnas || []);
            setLoader(false);
        })).catch(error => {
            logger.error("Error getting camp and acnas : " + error);
            setLoader(false);
        });
    }

    useEffect(() => {
        if (!accountsFromStore?.camp_accounts || accountsFromStore.camp_accounts.length === 0) {
            refreshAccountData();
        }
    }, []);

    return (
        <div className="AccountsPage mt-5">
            {loader ? <Loader /> : null}
            <div className={style.accountButtons}>
                <button type="button" className={`light-button mr-3 px-3`} onClick={addACNAAction}>
                    Add ACNA
                </button>
                <button type="button" className={`light-button mr-3 px-3`} onClick={addCAMP}>
                    Add CAMP Account
                </button>
                <button type="button" className={`light-button mr-3 px-3`}>
                    Download Accounts
                    <ExportXLSX csvData={accountsFromStore?.camp_accounts_download || []}
                        fileName={`Camp_Accounts_${new Date().toISOString().slice(0, 10)}`} />
                </button>
            </div>
            <div className={`sub-hdr alt0 mt-10 pt-10`}>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2 className={`mt-10 pt-10 `}>CAMP Accounts</h2>
            </div>
            {accountsFromStore?.camp_accounts?.length === 0 ? (
                <div style={{ textAlign: 'center' }}>No accounts available.</div>
            ) : (
                <div style={{ width: "100%" }}>
                    <StripedDataGrid
                        autoHeight
                        theme={CustomTheme}
                        rows={accountsFromStore?.camp_accounts || []}
                        getRowId={(row) => row.ban}
                        columns={columns}
                        filterModel={filterModel}
                        onFilterModelChange={(model) => setFilterModel(model)}
                        pageSize={pageSize}
                        rowsPerPageOptions={[]}
                        resultsPerPageOptions={[]}
                        pageSizeOptions={[]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        slots={{
                            pagination: TablePagination,
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 20
                                },
                            },
                        }}
                    />
                </div>
            )}
            {ErrorMSG.length ? (
                <div>
                    <p className={`text-center my-4 text-danger`}>{ErrorMSG}</p>
                </div>
            ) : null}
            {SuccessMSG.length ? (
                <div>
                    <p className={`text-center my-4 text-success`}>{SuccessMSG}</p>
                </div>
            ) : null}
            <div className={`sub-hdr alt0 mt-10 pt-10`}>
                <div className="nib">
                    <div className="the-notch">
                        <div className="notch-fg">
                            <svg>
                                <path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <h2 className={`mt-10 pt-10 `}>ACNA</h2>
            </div>
            <div style={{ width: "45%" }}>
                <StripedDataGrid
                    autoHeight
                    theme={CustomTheme}
                    rows={accountsFromStore?.acnas || []}
                    rowSelection={false}
                    getRowId={(row) => row.acna}
                    columns={acnaColumns}
                    hideFooterPagination={true}
                    hideFooterSelectedRowCount={true}
                />
            </div>
            {addACNA ? <Modal title="Add ACNA" action="ACNA" emitAction={modalAction} /> : null}
            {addCAMPAccount ? <Modal title="Add CAMP Account" action="SEARCHCAMP" emitAction={modalAction} onSuccessfulAdd={refreshAccountData} /> : null}
            {editCAMPAccount ? <Modal title="Edit CAMP Account" action="EDITCAMP" rowData={rowData} emitAction={modalAction} onSuccessfulEdit={refreshAccountData} /> : null}
        </div>
    );
}

const mapDispatchToProps = {
    camp_accounts: camp_accounts,
    camp_accounts_download: camp_accounts_download,
    acnas: acnas
}

export default connect(null, mapDispatchToProps)(Accounts);