import {QUOTE_DATA, GET_UPLOADED_FILES} from "./types/actionTypes";

export const setQuoteData = (quoteData) => {
	return {
		type: QUOTE_DATA,
		payload: quoteData
	}
}

export const setGetUploadedFiles = (flag) => {
	return {
		type: GET_UPLOADED_FILES,
		payload: flag
	}
}