import style from "./QuoteCreate.module.css";
import { useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import PremAddress from "./PremAddress";
import QuoteLocZ from "./QuoteLocZ";
import WaveHelper from "../../services/WaveService";
import Select from 'react-select';
import Loader from "../shared/Loader";
import Term from './Term';
import {
	setBwLocADisplay,
	setBwLocAFlag,
	setBwLocZDisplay,
	setBwLocZFlag,
	setQuoteAddBW1,
	setQuoteAddBW2,
	setQuoteAddressA,
	setQuoteAddressZ,
	setQuoteBW,
	setQuoteDiversity,
	setQuoteDiversityNote,
	setQuoteKmzFile,
	setQuoteProtocol1,
	setQuoteProtocol2,
	setQuoteProtocol3,
	setQuoteQty,
	setQuoteRouteLatency,
	setQuoteRouteType
} from "../../redux/actions/quoteActions";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import PathDetails from "./routeCreator/PathDetails";

const Wave = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [hasError, setHasError] = useState(null);
	const [popLocAOptions, setPopLocAOptions] = useState([]);
	const [popLocZOptions, setPopLocZOptions] = useState([]);
	const [bandwidthAdded, setBandwidthAdded] = useState(false);
	const [bandwidthCount, setBandwidthCount] = useState(0);
	const [protocolOptions1, setProtocalOptions1] = useState([]);
	const [protocolOptions2, setProtocalOptions2] = useState([]);
	const [protocolOptions3, setProtocalOptions3] = useState([]);
	const [waveBWOptions, setWaveBWOptions] = useState([]);
	const [loader, setLoader] = useState(false);
	const [popUpMessage, setPopUpMessage] = useState("");
	const [popUpShow, setPopUpShow] = useState(false);
	const selectedWaveValues = useSelector(state => state.quote.quoteValues);
	const selectedLocAWaveBw = useSelector(state => state.quoteLocA.locAWaveBw);
	const selectedLocZWaveBw = useSelector(state => state.quoteLocZ.locZWaveBw);
	const selectedLocAWaveBwFlag = useSelector(state => state.quoteLocA.locAWaveBwFlag);
	const selectedLocZWaveBwFlag = useSelector(state => state.quoteLocZ.locZWaveBwFlag);
	const selectedInternalMpid = useSelector(state => state.internal.company?.value);
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const configurationRef = useRef(null);

	useEffect(() => {
		getWavePOPLocation();
		getWaveBWOptions();
	}, []);

	useEffect(() => {
		if (selectedWaveValues.bw !== "") {
			getWaveProtocalOptions(selectedWaveValues.bw, 1);
		}

		if (selectedWaveValues.bw1 !== "") {
			getWaveProtocalOptions(selectedWaveValues.bw1, 2);
			setBandwidthCount(1);
		}

		if (selectedWaveValues.bw2 !== "") {
			getWaveProtocalOptions(selectedWaveValues.bw2, 3);
			setBandwidthCount(2);
		}
	}, [])

	useEffect(() => {
		if (Number.isInteger(selectedWaveValues.coAddA?.value) && selectedWaveValues.step === 2) {
			if (selectedWaveValues.coAddA.value !== "Other") {
				checkLandlordExclusion(selectedWaveValues.coAddA.value);
				getAvailableWaveBW(selectedWaveValues.coAddA.value, "locA");
			}
		}
	}, [selectedWaveValues.coAddA])

	useEffect(() => {
		if (Number.isInteger(selectedWaveValues.coAddZ?.value) && selectedWaveValues.step === 2) {
			if (selectedWaveValues.coAddZ.value !== "Other") {
				checkLandlordExclusion(selectedWaveValues.coAddZ.value);
				getAvailableWaveBW(selectedWaveValues.coAddZ.value, "locZ");
			}

		}
	}, [selectedWaveValues.coAddZ])

	useEffect(() => {
		if (props.stepCode === 3 && props.section === "productConfig" && selectedWaveValues?.availability) {
			configurationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}
	}, [props.stepCode, props.section, selectedWaveValues?.availability]);

	const getWavePOPLocation = () => {
		if (props.stepCode === 2) {
			let popListA = null;
			let popListZ = null;
			let str = '';
			setLoader(true);
			WaveHelper.getPOPLocations().then(resp => {
				logger.info(resp.data);
				popListA = resp.data.locA;
				popListA = popListA.map(item => {
					str = `${item.name}`;
					return { value: item.id, label: str }
				});
				setPopLocAOptions(popListA);
				popListZ = resp.data.locZ;
				popListZ = popListZ.map(item => {
					str = `${item.name}`;
					return { value: item.id, label: str }
				});
				setPopLocZOptions(popListZ);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting Wave Pop Locations: " + error);
				setHasError(error);
			})
		}
	}

	const getWaveBWOptions = () => {
		if (props.stepCode === 3 && props.section === "bandwidth") {
			setLoader(true);
			WaveHelper.getWaveBW().then(resp => {
				logger.info(resp.data);
				setWaveBWOptions(resp.data);
				setLoader(false);
			}).catch(error => {
				logger.error("Getting Wave Bandwidths: " + error);
				setHasError(error);
				setLoader(false);
			})
		}
	}

	const getWaveProtocalOptions = (bandwidthValue, id) => {
		setLoader(true);
		WaveHelper.getWaveProtocols(bandwidthValue).then(resp => {
			logger.info(resp.data);
			switch (id) {
				case 1:
					setProtocalOptions1(resp.data);
					break;
				case 2:
					setProtocalOptions2(resp.data);
					break;
				case 3:
					setProtocalOptions3(resp.data);
					break;
				default:
					break;
			}
			setLoader(false);
		}).catch(error => {
			logger.error("Getting Wave Protocols" + error)
			setHasError(error);
		})

	}

	const findPopLoc = (e, type) => {
		logger.info(e);
		let popVal = '';
		if (!e) {
			popVal = ''
		} else {
			popVal = e;
		}

		if (type === 'locA') {
			props.updateWavePopAddressA(popVal);
		} else {
			props.updateWavePopAddressZ(popVal);
		}
	}

	/*ADO-593576 Function to check the landlord tag for the pop uid selected*/
	const checkLandlordExclusion = (uid) => {
		setLoader(true);
		WaveHelper.getLandlordNotices(uid).then(resp => {
			logger.info(resp.data);
			if (resp.data?.quote_notice) {
				setPopUpMessage(resp.data.quote_notice);
				setPopUpShow(true);
			}
			setLoader(false);
		}).catch(error => {
			logger.error("Error checking landlord exclusion " + error);
			setLoader(false);
		})
	}

	/*Helper function determine what bandwidth is available*/
	const getAvailableWaveBW = (value, type) => {
		setLoader(true);
		WaveHelper.getWaveAvailBW(value).then(resp => {
			logger.info(resp.data);
			if (type === "locA") {
				props.updateWavePopLocADisplay(resp.data)
				props.updateWavePopLocAFlag(true);
			} else {
				props.updateWavePopLocZDisplay(resp.data)
				props.updateWavePopLocZFlag(true);
			}
			setLoader(false);
		}).catch(error => {
			logger.error("Getting Wave Available Bandwidths: " + error);
			setHasError(error);
			setLoader(false);
		})
	}


	const addBandwidth = () => {
		setBandwidthAdded(!bandwidthAdded);
		setBandwidthCount(bandwidthCount < 3 ? bandwidthCount + 1 : bandwidthCount);
	}

	const removeBandwidth = () => {
		let counter = bandwidthCount - 1;
		setBandwidthCount(bandwidthCount - 1);
		if (counter === 0) {
			props.updateWaveAddBw1("");
			props.updateWaveProtocol2("");
		}
		if (counter === 1) {
			props.updateWaveAddBw2("");
			props.updateWaveProtocol3("");
		}
	}

	const bandwidthHandler = (event) => {
		//Show a pop up message if user 10G or 400G Bandwidth for a quote started from the route creator
		if (event.target.value !== "600" && selectedWaveValues?.availability && isEmployee) {
			setPopUpShow(true);
			setPopUpMessage("Pre-approved pricing available for 100G only. ICB  required");
		}
		switch (event.target.id) {
			case "bw":
				props.updateWaveBw(event.target.value);
				getWaveProtocalOptions(event.target.value, 1);
				break;
			case "bw1":
				props.updateWaveAddBw1(event.target.value);
				getWaveProtocalOptions(event.target.value, 2);
				break;
			case "bw2":
				props.updateWaveAddBw2(event.target.value);
				getWaveProtocalOptions(event.target.value, 3);
				break;
			default:
				break;
		}
	}

	const optionHandler = (event) => {
		props.updateWaveRouteLatency(event.target.checked);
		props.updateWaveKmzFile(event.target.checked);
	}

	const inputChange = (event) => {
		switch (event.target.id) {
			case "protocol1":
				props.updateWaveProtocol1(event.target.value);
				break;
			case "protocol2":
				props.updateWaveProtocol2(event.target.value);
				break;
			case "protocol3":
				props.updateWaveProtocol3(event.target.value);
				break;
			case "qty":
				//Show a pop up message if user selects a quantity more than 2 and the quote is started from the route creator
				if(Number(event.target.value) > 2 && selectedWaveValues?.availability && isEmployee) {
					setPopUpShow(true);
					setPopUpMessage("Pre-approved pricing available up to 2. ICB required");
				}
				props.updateWaveQty(event.target.value);
				break;
			case "route_type":
				props.updateWaveRouteType(event.target.value);
				if (event.target.value === "1") {
					//make none required as selected value if single route in chosen
					props.updateWaveDiversity(0);
				} else {
					//Show a pop up message if user selects protected route type and the quote is started from the route creator
					if(selectedWaveValues?.availability && isEmployee) {
						setPopUpShow(true);
						setPopUpMessage("Pre-approved pricing available for Single.  ICB required");
					}
					props.updateWaveDiversity("");
				}
				break;
			case "diversity":
				//Show a pop up message if user selects Network or Custom Diversity and the quote is started from the route creator
				if((event.target.value === "3" || event.target.value === "5") && selectedWaveValues?.availability && isEmployee) {
					setPopUpShow(true);
					setPopUpMessage("Diversity will require ICB review");
				}
				props.updateWaveDiversity(event.target.value);
				break;
			case "div_note":
				props.updateWaveDiversityNote(event.target.value);
				break;
			default:
				break;
		}
	}


	// ADO-649371 Keep "Other Location" as option in search list
	const customFilter = (option, searchText) => {
		if (option.value.toString().toLowerCase().includes("other") ||
			option.label.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
			option.value.toString().toLowerCase().includes(searchText.toString().toLowerCase())
		) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			{hasError && <HTTPErrorHandler error={hasError}></HTTPErrorHandler>}
			{loader ? <Loader /> : null}
			{!hasError && (<>{props.stepCode === 2 ?
				<>
					<div className={`row ${style.rowGrp}`}>
						<div className="col-sm-6">
							<label className={style.labelSelectRequiredFld}>POP Location<span className={style.mandatory}>*</span> </label>
							<Select
								value={{ value: selectedWaveValues.coAddA.value, label: selectedWaveValues.coAddA.label }}
								onChange={(e) => { findPopLoc(e, 'locA') }}
								options={popLocAOptions.map((item) => {
									return { value: item.value, label: item.label }
								})}
								filterOption={customFilter}
								isSearchable
								isClearable
								isDisabled={selectedWaveValues?.availability ? true : false} //if quote creation process starts from the wave availability tool we need to disable the dropdowns
								noOptionsMessage={() => 'Loading POP Locations'}
							/>
						</div>
						{selectedLocAWaveBwFlag ?
							<>
								<div className="col-sm-3">
									<table className={`table ${style.waveBwTable} `}>
										<tbody>
											<tr>
												<th>10 GB</th>
												<td>{selectedLocAWaveBw.g10}</td>
											</tr>
											<tr>
												<th>100 GB</th>
												<td>{selectedLocAWaveBw.g100}</td>
											</tr>
											<tr>
												<th>400 GBe</th>
												<td>{selectedLocAWaveBw.g400}</td>
											</tr>
											{selectedLocAWaveBw.wave_xc !== '' ?
												<tr>
													<td colSpan="2" className="text-center">{selectedLocAWaveBw.wave_xc}</td>
												</tr>
												: null}
										</tbody>
									</table>
								</div>
								<div className={`col-sm-3`}>
									<div className={` ${style.infoTip}  ${style.infoWaveBw} `}>
										<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
										<p className={style.contentHover}>
											<p>
												<b>Available:</b> Quote will be returned once approved by your Windstream Sales Executive.
											</p>
											<p>
												<b>Not Available:</b> Windstream can not offer a price for service if either A or Z is "Not Available".
											</p>
											<p>
												<b>Custom Quote:</b> If one or both locations are "Custom" the price will be available after Windstream cost analysis. May take up to 48 hours.
											</p>
											<p>
												<b>Cross Connect Required:</b> This selected location requires a Cross-Connect (XC) which will be included in the price of the circuit as an additional line item.
											</p>
										</p>
									</div>
								</div>
							</>
							: null}
					</div>

					{/*if other is selected than take the user input*/}
					{selectedWaveValues.coAddA.value === "Other" ?
						<PremAddress />
						: null}


					<div className={style.dividers}></div>

					<div className={` sub-hdr alt1 ${style.subHeader} `}>
						<div className="nib">
							<div className="the-notch">
								<div className="notch-fg"><svg><path d="M35.81,26.28l-13,10v-5C-10.47,28.68-1,9.16,11.79,0c0,7.28,0,9.13,0,13.28s3.18,7.92,11,9v-5Z"></path></svg></div>
							</div>
						</div>
						<h2 className={style.sectionTitle}>Location Z</h2>
					</div>

					<div className={`row ${style.rowGrp}`}>
						<div className="col-sm-6">
							<label className={style.labelSelectRequiredFld}>POP Location<span className={style.mandatory}>*</span> </label>
							<Select
								value={{ value: selectedWaveValues.coAddZ.value, label: selectedWaveValues.coAddZ.label }}
								onChange={(e) => { findPopLoc(e, 'locZ') }}
								options={popLocZOptions.map((item) => {
									return { value: item.value, label: item.label }
								})}
								filterOption={customFilter}
								maxMenuHeight={150}
								isSearchable
								isClearable
								isDisabled={selectedWaveValues?.availability ? true : false} //if quote creation process starts from the wave availability tool we need to disable the dropdowns
								noOptionsMessage={() => 'Loading POP Locations'}
							/>
						</div>
						{selectedLocZWaveBwFlag ?
							<>
								<div className="col-sm-3">
									<table className={`table ${style.waveBwTable} `}>
										<tbody>
											<tr>
												<th>10 GB</th>
												<td>{selectedLocZWaveBw.g10}</td>
											</tr>
											<tr>
												<th>100 GB</th>
												<td>{selectedLocZWaveBw.g100}</td>
											</tr>
											<tr>
												<th>400 GBe</th>
												<td>{selectedLocZWaveBw.g400}</td>
											</tr>
											{selectedLocZWaveBw.wave_xc !== '' ?
												<tr>
													<td colspan="2" className="text-center">{selectedLocZWaveBw.wave_xc}</td>
												</tr>
												: null}
										</tbody>
									</table>
								</div>
								<div className={`col-sm-3`}>
									<div className={` ${style.infoTip}  ${style.infoWaveBw} `}>
										<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
										<p className={style.contentHover}>
											<p>
												<b>Available:</b> Quote will be returned once approved by your Windstream Sales Executive.
											</p>
											<p>
												<b>Not Available:</b> Windstream can not offer a price for service if either A or Z is "Not Available".
											</p>
											<p>
												<b>Custom Quote:</b> If one or both locations are "Custom" the price will be available after Windstream cost analysis. May take up to 48 hours.
											</p>
											<p>
												<b>Cross Connect Required:</b> This selected location requires a Cross-Connect (XC) which will be included in the price of the circuit as an additional line item.
											</p>
										</p>
									</div>
								</div>
							</>
							: null}
					</div>
					{/*if other is selected than take the user input*/}
					{selectedWaveValues.coAddZ.value === "Other" ?
						<QuoteLocZ />
						: null}

				</>
				: null}

				{(props.stepCode === 3) && (props.section === "term") ?
					<Term product={"WAVE"} />
					: null}

				{(props.stepCode === 3) && (props.section === "bandwidth") ?
					<>
						<div className={style.bandwidthWrap}>
							<div className={`row ${style.rowGrp} `}>
								<div className="col-6 col-sm-4 col-md-3">
									<label className={style.labelSelectRequiredFld}>Bandwidth <span
										className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw"
										onChange={bandwidthHandler} value={selectedWaveValues.bw}>
										{waveBWOptions.map((obj) => {
											return <option value={obj.id}>{obj.name}</option>
										})}
									</select>
								</div>
								{selectedWaveValues.bw ?
									<div className="col-5 col-sm-4 col-md-3 rel">
										<div className="d-flex">
											<div className={style.w_90}>
												<label className={style.labelSelectRequiredFld}>Protocol <span className={style.mandatory}>*</span></label>
												<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="protocol1" onChange={inputChange} value={selectedWaveValues.protocol1} >
													<option disabled>-</option>
													{protocolOptions1.map((obj) => {
														return <option value={obj.id}>{obj.name}</option>
													})}
												</select>
											</div>
											<div className={` ${style.w_10} align-self-center`}>
												<div className={` ${style.infoTip}`}>
													<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
													<p className={style.contentHover}>
														Protocol indicates signal protocol that the customer will be handing off to Windstream to transport across its WDM network. Windstream will encapsulate this signal and transport it transparently across its network in an OUT wrapper then hand the signal back to the customer at the ends of the service. OTU4 and SDH signals will require Engineering review prior to quote.
													</p>
												</div>
											</div>
										</div>
									</div>
									: null}


								<div className="col-2 col-sm-2 col-md-2 align-self-center clearfix">
									<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `} onClick={addBandwidth}>
										<svg className="ico-plus"><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
									</div>
								</div>
							</div>
						</div>
						{bandwidthCount >= 1 ?
							<div className={style.bandwidthWrap}>
								<div className={`row ${style.rowGrp} `}>
									<div className="col-6 col-sm-4 col-md-3">
										<label className={style.labelSelectRequiredFld}>Additional Bandwidth</label>
										<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw1"
											onChange={bandwidthHandler} value={selectedWaveValues.bw1}>
											{waveBWOptions.map((obj) => {
												return <option value={obj.id}>{obj.name}</option>
											})}
										</select>
									</div>
									{selectedWaveValues.bw1 ?
										<div className="col-5 col-sm-4 col-md-3 rel">
											<div className="d-flex">
												<div className={style.w_90}>
													<label className={style.labelSelectRequiredFld}>Protocol <span className={style.mandatory}>*</span></label>
													<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="protocol2" onChange={inputChange} value={selectedWaveValues.protocol2}>
														<option disabled>-</option>
														{protocolOptions2.map((obj) => {
															return <option value={obj.id}>{obj.name}</option>
														})}
													</select>
												</div>
												<div className={` ${style.w_10} align-self-center`}>
													<div className={` ${style.infoTip}`}>
														<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
														<p className={style.contentHover}>
															Protocol indicates signal protocol that the customer will be handing off to Windstream to transport across its WDM network. Windstream will encapsulate this signal and transport it transparently across its network in an OUT wrapper then hand the signal back to the customer at the ends of the service. OTU4 and SDH signals will require Engineering review prior to quote.
														</p>
													</div>
												</div>
											</div>
										</div>
										: null}
									<div className={`col-2 col-sm-4 col-md-4 align-self-center clearfix ${style.svgWarp} `}>
										<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>
											<svg className="ico-plus" onClick={addBandwidth}><path d="M13.1,24H10.89V13.1H0V10.89H10.89V0H13.1V10.89H24V13.1H13.1Z"></path></svg>
											<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
												<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
      C8.27,265.1,8.27,243.76,8.27,222.43z"/>
											</svg>
										</div>
									</div> </div> </div> : null}
						{bandwidthCount >= 2 ?
							<div className={style.bandwidthWrap}>
								<div className={`row ${style.rowGrp} `}>
									<div className="col-6 col-sm-4 col-md-3">
										<label className={style.labelSelectRequiredFld}>Additional Bandwidth</label>
										<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="bw2"
											onChange={bandwidthHandler} value={selectedWaveValues.bw2}>
											{waveBWOptions.map((obj) => {
												return <option value={obj.id}>{obj.name}</option>
											})}
										</select>
									</div>
									{selectedWaveValues.bw2 ?
										<div className="col-5 col-sm-4 col-md-3 rel">
											<div className="d-flex">
												<div className={style.w_90}>
													<label className={style.labelSelectRequiredFld}>Protocol <span className={style.mandatory}>*</span></label>
													<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="protocol3" onChange={inputChange} value={selectedWaveValues.protocol3}>
														<option disabled>-</option>
														{protocolOptions3.map((obj) => {
															return <option value={obj.id}>{obj.name}</option>
														})}
													</select>
												</div>
												<div className={` ${style.w_10} align-self-center`}>
													<div className={` ${style.infoTip}`}>
														<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
														<p className={style.contentHover}>
															Protocol indicates signal protocol that the customer will be handing off to Windstream to transport across its WDM network. Windstream will encapsulate this signal and transport it transparently across its network in an OUT wrapper then hand the signal back to the customer at the ends of the service. OTU4 and SDH signals will require Engineering review prior to quote.
														</p>
													</div>
												</div>
											</div>
										</div>
										: null}
									<div className={`col-2 col-sm-4 col-md-4 align-self-center clearfix ${style.svgWarp} `}>
										<div className={`add-toggle ${style.actionBtn} ${bandwidthAdded ? '' : ''} `}>

											<svg version="1.1" id="Layer_1" className="ico-plus" viewBox="0 0 511.99 511.99" onClick={removeBandwidth}>
												<path d="M8.27,222.43c165.13,0,330.25,0,495.73,0c0,21.3,0,42.35,0,64.01c-165.25,0-330.49,0-495.73,0
C8.27,265.1,8.27,243.76,8.27,222.43z"/>
											</svg>
										</div>
									</div> </div> </div> : null}
					</> : null}
				{(props.stepCode === 3) && (props.section === "productConfig") ?
					<>
						<div ref={configurationRef}/>
						<div className="col-12 col-sm-4 col-md-3 mb-5">
							<label className={style.labelSelectRequiredFld}>Quantity <span className={style.mandatory}>*</span></label>
							<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id='qty' onChange={inputChange} value={selectedWaveValues.qty}>
								<option disabled>-</option>
								{[...Array(50)].map((x, i) =>
									<option key={i}>{i + 1}</option>
								)}
							</select>
						</div>
						<div className="col-6 col-sm-4 col-md-3 rel mb-5">
							<div className="d-flex">
								<div className={style.w_90}>
									<label className={style.labelSelectRequiredFld}>Route Type <span className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="route_type" onChange={inputChange} value={selectedWaveValues.route_type}>
										<option value="" disabled>-</option>
										<option value="1">Single (1 tx, 1 rx)</option>
										<option value="2">Protected (2 tx, 2 rx)</option>
									</select>
								</div>
								<div className={` ${style.w_10} align-self-center`}>
									<div className={style.infoTip}>
										<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
										<p className={style.contentHover}>
											<b>Protected Wave:   </b> circuits will consist of two circuit paths, Primary and Protect, and includes electronic card and fiber route diversity.
											<br></br>
											Windstream will provide a single circuit hand-off at the A and Z location with network auto- switching from the primary path to the protect path if an outage occurs.
											<br></br>
											<br></br>
											<b>Unprotected Wave:   </b> circuits will have only a single path from A-Z location. No protection or auto-switching is provided at the Core, Edge or Access network.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-5 col-sm-4 col-md-3 rel mb-5">
							<div className="d-flex">
								<div className={style.w_90}>
									<label className={style.labelSelectRequiredFld}>Diversity Options <span className={style.mandatory}>*</span></label>
									<select className={` form-control  ${style.selectFld} ${style.requiredFld} `} id="diversity" onChange={inputChange} value={selectedWaveValues.diversity}>
										<option value=""></option>
										{selectedWaveValues.route_type === "1" ?
											<>
												<option value="0" selected>None Required</option>
												<option value="3">Network Diversity</option>
											</>
											: null}
										{selectedWaveValues.route_type === "2" ?
											<>
												<option value="1">Route and Card Diversity</option>
												<option value="4">Site Diversity</option>
											</> :
											null}
										<option value="5">Custom Diversity</option>
									</select>
								</div>

								<div className={` ${style.w_10} align-self-center`} >
									<div className={style.infoTip}>
										<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
										<p className={style.contentHover}>
											<b>None Required:   </b> No diversity requirements needed by customer.
											<br></br>
											<b>Network Diveristy: </b> Protected is on Windstream Network, Single handoff and/or last-mile route.
											<br></br>
											<b>Route and Card Diversity:   </b> Diverse fiber routes and cards that may share a common POP as the only single point of failure.
											<br></br>
											<b>Site Diveristy: </b> No single point of failure with separate POPs and fiber routes.
											<br></br>
											<b>Custom Diversity:   </b> Customer instructions to avoid a specific site, city, or fiber route specified by customer.
										</p>
									</div>
								</div>

							</div>
						</div>
						{/*Custom Diversity Selected Enter Diversity Note*/}
						{selectedWaveValues.diversity === "5" ?
							<div className='col-12 col-sm-8 col-md-7'>
								<label className={style.labelSelectRequiredFld}> Diversity Note</label>
								<textarea className={style.textareaFld} id="div_note" onChange={inputChange}></textarea>
							</div>
							: null}
					</>
					: null}
				{(props.stepCode === 3) && (props.section === "Options") && selectedWaveValues.iconFlag !== "ICON" ?
					<>
						<div className={`row ${style.rowGrp} d-flex`}>
							<div className="ml-3">
								<input type="checkbox" id="kmz_file" name="kmz_file" disabled={selectedWaveValues.iconFlag === "NOT ICON" ? "disabled" : ""} onChange={optionHandler} checked={selectedWaveValues.kmz_file ? "checked" : ""} />
								<label className={` ${style.checkboxText} ${style.textTransformNone} ml-2`}>Request Route KMZ file and Latency Estimate</label>
							</div>
							<div className={style.infoTip}>
								<img src="../../images/svg/ico-info-filled-dark-off.svg" alt="info tip" className={style.infoImg} />
								<p className={style.contentHover}>
									Provides details via Google Earth for requested wave locations.  Response times may vary.
								</p>
							</div>
						</div>
					</>
					: null}</>)}
			{/*Shows the Selected Route from the Wave Availability and Diversity Tool*/}
			{selectedWaveValues.availability && (props.stepCode === 3) && (props.section === "Options") && selectedWaveValues.iconFlag === "ICON" ?
				<>
					<div className={`row ${style.rowGrp} `}>
						<h6><b>Route Selected</b></h6>
						<PathDetails details={selectedWaveValues.pathDetails} kmzUrl={selectedWaveValues.kmzUrl} path={selectedWaveValues.pathNumber} />
					</div>
				</> : null
			}

			{popUpShow ?
				<div>
					<div className={`${style.overly}`}> </div>
					<div className="modal fade show d-block">
						<div className={` modal-dialog modal-dialog-centered modal-md`}>
							<div className="modal-content">
								<div className="modal-body">
									{popUpMessage}
								</div>
								<div className="modal-footer d-block text-center">
									<button type="button" className="btn-style btn-theme" data-dismiss="modal" onClick={() => setPopUpShow(false)}>Ok</button>
								</div>
							</div>
						</div>
					</div>
				</div> : null
			}
		</>
	)

}

const mapDispatchToProps = {
	updateWavePopAddressA: setQuoteAddressA,
	updateWavePopAddressZ: setQuoteAddressZ,
	updateWavePopLocADisplay: setBwLocADisplay,
	updateWavePopLocZDisplay: setBwLocZDisplay,
	updateWavePopLocAFlag: setBwLocAFlag,
	updateWavePopLocZFlag: setBwLocZFlag,
	updateWaveBw: setQuoteBW,
	updateWaveAddBw1: setQuoteAddBW1,
	updateWaveAddBw2: setQuoteAddBW2,
	updateWaveProtocol1: setQuoteProtocol1,
	updateWaveProtocol2: setQuoteProtocol2,
	updateWaveProtocol3: setQuoteProtocol3,
	updateWaveRouteType: setQuoteRouteType,
	updateWaveDiversity: setQuoteDiversity,
	updateWaveDiversityNote: setQuoteDiversityNote,
	updateWaveRouteLatency: setQuoteRouteLatency,
	updateWaveKmzFile: setQuoteKmzFile,
	updateWaveQty: setQuoteQty
}

export default connect(null, mapDispatchToProps)(Wave);
