import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; // Import useSelector
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import BillingHelper from "../../services/AccountListService";
import Loader from "../shared/Loader";
import HTTPErrorHandler from "../errorPages/HTTPErrorHandler";
import ViewportWarning from "../shared/ViewportWarning";
import XLSXExport from "../../utils/XLSXExport";
import StripedDataGrid from "../components/tables/StripedDataGrid";
import CustomTheme from "../components/tables/CustomTheme";
import TablePagination from "../components/tables/TablePagination";
import CustomToolbar from '../components/tables/CustomToolbar';
import CompanyDropdown from '../shared/CompanyDropdown';

const BillingDisputeList = (props) => {
    const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL });
    const [hasError, setHasError] = useState('');
    const [loader, setLoader] = useState(false);
    const [billingData, setBillingData] = useState([]);

    const selectedInternalMpid = useSelector(state => state.internal.company?.value);

    const isEmployee = localStorage.getItem("isEmployeeLoggedIn");
    const [statusUpdateSuccessMsg, setStatusUpdateSuccessMsg] = useState("");
    const [statusUpdateErrorMsg, setStatusUpdateErrorMsg] = useState("");

    const XLSXHeaders = ["Case Number", "Account", "Contact Email", "Dispute Reason", "Description", "Dispute Amount", "Case Status"];
    const [pageSize, setPageSize] = useState(25);
    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    const columns = [
        {
            field: 'caseNumber',
            headerName: 'Case Number',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        },
        {
            field: 'account',
            headerName: 'Account',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        },
        {
            field: 'contactEmail',
            headerName: 'Contact Email',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        },
        {
            field: 'disputeReason',
            headerName: 'Dispute Reason',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
        },
        {
            field: 'description',
            headerName: 'Description',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 1.5,
        },
        {
            field: 'disputeAmount',
            headerName: 'Dispute Amount',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.3,
            renderCell: (params) => (
                <div style={{ textAlign: 'right', width: '100%' }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: 'caseStatus',
            headerName: 'Case Status',
            headerAlign: 'center',
            renderHeader: (params) => formatSearchHeader(params),
            flex: 0.5,
            renderCell: (params) => (
                <div style={{ textAlign: 'center', width: '100%' }}>
                    {params.value}
                </div>
            ),
        }
    ];

    function formatSearchHeader(params) {
        // Determine if the current column is "Case Status"
        const isCaseStatus = params.colDef.field === 'caseStatus';

        // Set the style based on whether it's "Case Status" or another field
        const headerStyle = {
            marginLeft: isCaseStatus ? 0 : 8, // Remove left margin for centered headers
            textAlign: isCaseStatus ? 'center' : 'left', // Center align "Case Status"
            color: "#753BBD",
            width: '100%',
            display: 'block',
        };

        return (
            <div style={{ display: 'flex', justifyContent: isCaseStatus ? 'center' : 'flex-start' }}>
                <span style={headerStyle}>{params.colDef.headerName}</span>
            </div>
        );
    }

    const fetchBillingData = () => {
        setLoader(true);
        BillingHelper.getBillingDisputeList().then(resp => {
            logger.info("Received Response from getBillingDisputeList");
            logger.info(JSON.stringify(resp.data));
            setBillingData(resp.data);
            setLoader(false);
        }).catch(error => {
            logger.info("DID NOT Receive Response from getBillingDisputeList");
            setLoader(false);
            setHasError(error);
        });
    };

    useEffect(() => {
        if (selectedInternalMpid) {
            fetchBillingData();
        }
    }, [selectedInternalMpid]); // Fetch data on selectedInternalMpid change or initial load


    return (
        <>
            {loader ? <Loader /> : null}
            {hasError && (
                <HTTPErrorHandler error={hasError}></HTTPErrorHandler>
            )}
            {!hasError && (
                <div className="midsection qm-upload abs z1">
                    <div className="rel">
                        <ViewportWarning />
                        <div className="wrap">
                            <h1 className="page-title">{isEmployee ? "Employee Billing Support Summary" : "Billing Support Summary"}</h1>
                            {isEmployee ? <CompanyDropdown /> : null}
                            <div className="section">
                                <div className="content">
                                    <div className="header-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <h2 className={`m-0`} style={{ margin: 0 }}>
                                            <span>Billing Disputes</span>
                                        </h2>
                                        <div>
                                            <button className="saveButton ico-button light-button" onClick={() => XLSXExport.exportToExcel(XLSXHeaders, billingData, `BillingDisputes_${Date.now()}`)}>
                                                <span><strong>Download</strong></span>
                                                <svg viewBox="0 0 34 34" className={`ico light-ico ico-arrow downloadArrow`}>
                                                    <path className="cls-1" d="M1,18V16L17,31V1h2V31L35,16v2L20,33H35v2H1V33H16Z"
                                                        transform="translate(-1 -1)">
                                                    </path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '20px' }}></div>
                                    <div style={{ width: "100%" }}>
                                        {billingData.length > 0 ? (
                                            <StripedDataGrid
                                                autoHeight
                                                theme={CustomTheme}
                                                rows={billingData}
                                                rowHeight={50}
                                                getRowId={(row) => row.caseNumber}
                                                columns={columns}
                                                filterModel={filterModel}
                                                onFilterModelChange={(model) => setFilterModel(model)}
                                                pageSize={pageSize}
                                                rowsPerPageOptions={[]}
                                                resultsPerPageOptions={[]}
                                                pageSizeOptions={[]}
                                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                slots={{
                                                    pagination: TablePagination,
                                                    toolbar: CustomToolbar
                                                }}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: {
                                                            pageSize: 25
                                                        },
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <div>No Cases</div> // Display No Case when billingData is empty
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default BillingDisputeList;