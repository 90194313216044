import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import style from '../quote/QuoteView.module.css';
import InternalQuoteHelper from '../../services/InternalQuoteService';
import Loader from '../shared/Loader';

const OfficeSuitePricing = ({ quoteData, refId, icbPricing, orderExist }) => {
	const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
	const [originalLineItems, setOriginalLineItems] = useState({});
	const isEmployee = localStorage.getItem('isEmployeeLoggedIn');
	const [syncKey, setSyncKey] = useState("");
	const [tempSyncId, setTempSyncId] = useState("");
	const [updateMsg, setUpdateMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [loader, setLoader] = useState(false);
	const [pricingPermissionFlag, setPricingPermissionFlag] = useState(false);
	const [icbPricingPermissionFlag, setIcbPricingPermissionFlag] = useState(false);
	var regex = /[0-9]/;

	useEffect(() => {
		setOriginalLineItems(quoteData.pricing.lineitems);
	}, [quoteData, quoteData.pricing.lineitems])

	useEffect(() => {
		setErrorMsg("");
	}, [originalLineItems])

	useEffect(() => {
		setLoader(true);
		InternalQuoteHelper.permissionCheck().then(response => {
			logger.info(`Response from permissionCheck: ` + JSON.stringify(response.data));
			if (Number(response.data.pricing) === 1) {
				setPricingPermissionFlag(true);
			} else {
				setPricingPermissionFlag(false);
			}
			if (Number(response.data.icb) === 1) {
				setIcbPricingPermissionFlag(true);
			} else {
				setIcbPricingPermissionFlag(false);
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`Error getting permissionCheck: ${error}`);
			setLoader(false);
		})
	}, [logger])

	/*Updating pricing in the UI and saving it to the lineItems state variable to send it to internalQuoteUpdates.php*/
	/* 12/12/24 commenting out, phpstorm says not used
	const priceUpdateHandler = (value, field, option, term, line) => {
		logger.info(`ORIGINAL LINEITEM STATE VARIABLE THAT GETS UPDATED: ${JSON.stringify(originalLineItems)}`);
		logger.info(`Value updated: ${value}`);
		logger.info(`Field updated: ${field}`);
		logger.info(`Option: ${option}`);
		logger.info(`Term: ${term}`);
		logger.info(`Line: ${line}`);
		//Preventing the deletion of the input field
		if (value === "") {
			value = " ";
		}
		//copying existing nested object so it can be updated with the value entered
		let updatedLineItems = { ...originalLineItems, [option]: { ...originalLineItems[option], [term]: { ...originalLineItems[option][term], [line]: { ...originalLineItems[option][term][line] } } } };
		updatedLineItems[option][term][line][field] = value;
		logger.info(`UPDATED LINE ITEMS THAT GET SENT TO THE PRICING UPDATE SERVICE: ${JSON.stringify(updatedLineItems)}`);
		setOriginalLineItems(updatedLineItems);
	}
	*/

	/*Sending updated pricing / sync quote to backend service call*/
	const updatePricingHandler = () => {
		/*Only try to sync a quote if a sync checkbox is selected*/
		if (syncKey) {
			setLoader(true);
			InternalQuoteHelper.syncQuote(syncKey, refId).then(response => {
				logger.info("Syncing Quote");
				setLoader(false);
				if (response.data?.result === "SUCCESS") {
					setUpdateMsg(`${updateMsg} ${response.data.message}`);
				} else {
					setErrorMsg(`${errorMsg} ${response.data.message}`);
				}
			}).catch(error => {
				logger.error(`Error syncing quote: ${error}`);
				setLoader(false);
			})
		}


		setLoader(true);
		InternalQuoteHelper.updatePricing(originalLineItems, refId).then(response => {
			logger.info(`Passed updated pricing to php`);
			if (response.data?.result === "SUCCESS") {
				setUpdateMsg(`${response.data.message}`);
				localStorage.setItem('generateNewPdf', 'true');
				window.location.reload(false);
			} else {
				setErrorMsg(`${response.data.message}`);
			}
			setLoader(false);
		}).catch(error => {
			logger.error(`Error with the pricing update: ${error}`);
			setLoader(false);
		})
	}

	const syncQuoteHandler = (event) => {
		if (tempSyncId) {
			document.getElementById(`${tempSyncId}`).checked = false;
		}
		setTempSyncId(event.target.id);
		setSyncKey(event.target.value);
		logger.info(`Sync selected: ${event.target.id}`);
		logger.info(`Sync value selected: ${event.target.value}`);
	}

	return (
		<>
			<div className='col-12 col-sm-12 col-md-12 col-lg-12'>
				{loader ? <Loader /> : null}
				{Object.keys(originalLineItems).map((option) => {
					return (
						<>
							{Object.keys(originalLineItems[option]).map((term, col) => {
								if (term.match(regex)) {
									return (
										<>
											<table className={`table table-bordered ${style.tableAlt}  `}>
												<thead>
													{col === 0 ? <th>Product</th> : ''}

													<>
														<th>{term > 0 ? `${term} Year` : "Month to Month"}</th>
														{isEmployee && quoteData.product_id !== "23" && quoteData.product_id !== "29" ?
															<>
																<input type="checkbox" class="syncQuote" id={`sync${option}Term${term}`} name="syncQuote" value={`${originalLineItems[option].bwId}:${term}`} onClick={syncQuoteHandler} />
																<label htmlFor="syncQuote" className={`m-2 ${style.pricingCheckboxLabel}`}>Sync Quote</label>
															</> : null
														}
													</>

												</thead>
												<tbody>
													<tr>
														{col === 0 ? <td>&nbsp;</td> : ''}
														<td><b>Qty</b></td>
														<td><b>MRC</b></td>
														<td><b>NRC</b></td>
														<td><b>Total MRC</b></td>
														<td><b>Total NRC</b></td>
													</tr>
													{Object.keys(originalLineItems[option][term]).map((line, row) => {

														return (
															<>
																{line.match(regex) ?
																	<tr key={row}>
																		{col === 0 ? <td>{originalLineItems[option][term][line].line_desc} </td> : ''}
																		<td>{originalLineItems[option][term][line].qty}</td>
																		<td>{originalLineItems[option][term][line].price_mrc}</td>
																		<td>{originalLineItems[option][term][line].price_nrc}</td>
																		<td>{originalLineItems[option][term][line].total_price_mrc}</td>
																		<td>{originalLineItems[option][term][line].total_price_nrc}</td>
																	</tr> : null
																}
															</>
														)
													})}
													<tr>
														{col === 0 ?
															<>
																<td>Overall Total</td>
																<td colspan="3"></td>
																<td>{originalLineItems[option][term].total_mrc}</td>
																<td>{originalLineItems[option][term].total_nrc}</td>
															</>
															: ''}
														{col !== 0 ?
															<>
																<td>{originalLineItems[option][term].total_mrc}</td>
																<td>{originalLineItems[option][term].total_nrc}</td>
															</>
															: ''}
													</tr>

													{originalLineItems[option][term].a_vendor?.length > 0 ?
														<tr>
															{col === 0 ? <td>Loc A Vendor</td> : ''}
															<td colspan="2">{originalLineItems[option][term].a_vendor}</td>
														</tr> : null
													}
													{isEmployee ?
														<>
															{originalLineItems[option][term].z_vendor?.length > 0 ?
																<tr>
																	{col === 0 ? <td>Loc Z Vendor</td> : ''}
																	<td colspan="5">{originalLineItems[option][term].z_vendor}</td>
																</tr> : null
															}
															{originalLineItems[option][term].a_network_prov?.length > 0 ?
																<tr>
																	{col === 0 ? <td>Loc A Network Provider</td> : ''}
																	<td colspan="5">{originalLineItems[option][term].a_network_prov}</td>
																</tr> : null
															}
															{originalLineItems[option][term].z_network_prov?.length > 0 ?
																<tr>
																	{col === 0 ? <td>Loc Z Network Provider</td> : ''}
																	<td colspan="5">{originalLineItems[option][term].z_network_prov}</td>
																</tr> : null
															}
															{originalLineItems[option][term].priceType?.length > 0 ?
																<tr>
																	{col === 0 ? <td>Price Type</td> : ''}
																	<td colspan="5">{originalLineItems[option][term].priceType}</td>
																</tr> : null
															}
															{originalLineItems[option][term].priceSource?.length > 0 ?
																<tr>
																	{col === 0 ? <td>Price Source</td> : ''}
																	<td colspan="5">{originalLineItems[option][term].priceSource}</td>
																</tr> : null
															}
														</> : null
													}
												</tbody>
											</table>
										</>
									)
								} else {
									return null
								}
							})}
							{(originalLineItems[option]?.icb_price_type === '2' || originalLineItems[option]?.icb_price_type === '3' || originalLineItems[option]?.icb_price_type === '4') ?
								<>
									<div className={`row ${style.rowGrp}`}>
										<div className={`${style.smallText}`}>Average MRC is the cumulative MRC over the length of the service contract. Average is calculated using monthly recurring charges and any one-time supplemental charges for the length of the term.</div>
									</div>
									<div className={`row ${style.rowGrp}`}>
										<div className={`${style.smallText}`}>Upon expiration of the term, the billing will revert to Average Equivalent MRC as defined, unless otherwise noted.</div>
									</div>
								</>
								: null}
						</>
					)
				})}
				{isEmployee ?
					<div className={`row ${style.rowGrp}`}>
						{!orderExist && icbPricingPermissionFlag && icbPricing && quoteData.product_id !== "29" ?
							<>
								{quoteData.icb_floor_set === '1' ?
									<>
										<div className='col-md-4'>
											<div className={`qm-summary mt-2`}>
												<button className="ico-button lhs light-button" onClick={updatePricingHandler}>
													<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
													{pricingPermissionFlag ? "Update Pricing / Sync Quote" : "Sync"}
												</button>
											</div>
										</div>
									</>
									:
									<>
										<div className='col-md-4'>
											<div className={`qm-summary mt-2`}>
												Pricing Update Pending ICB Pricing
											</div>
										</div>
									</>
								}
								<div className='col-md-4'>
									<div className={`qm-summary mt-2`}>
										<Link to={{ pathname: `../../Quote/IcbPricing/${refId}`, state: { pricingPermissionFlag: pricingPermissionFlag, quoteData: quoteData } }} target="_self">
											<button className="ico-button lhs light-button">
												<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
												Update ICB Pricing
											</button>
										</Link>
									</div>
								</div>
							</>
							:
							<>
								{!orderExist && quoteData.product_id !== "23" && quoteData.product_id !== "29" ?
									<div className='col-md-4'>
										<div className={`qm-summary mt-2`}>
											<button className="ico-button lhs light-button" onClick={updatePricingHandler}>
												<svg className="ico light-ico ico-arrow"><path d="M0,17H29L15,31l1,1L32,16,16,0,15,1,29,15H0Z"></path></svg>
												{pricingPermissionFlag ? "Update Pricing / Sync Quote" : "Sync"}
											</button>
										</div>
									</div> : null
								}
							</>
						}

						<div className='col-md-4'>
							{updateMsg ? <p className="text-success">{updateMsg}</p> : null}
							{errorMsg ? <p className="text-danger">{errorMsg}</p> : null}
						</div>
					</div> : null
				}
			</div>

		</>
	)
}

export default OfficeSuitePricing;
