import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ExportXLSX } from '../../utils/ExportXLSX';
import { NavLink } from 'react-router-dom';
import style from "../order/OrderSummary.module.css";
import ReportHelper from '../../services/ReportService';
import Loader from "../shared/Loader";
import { ConsoleLogger } from "../../logger/ConsoleLogger";
import { useParams } from "react-router-dom";
import CustomTableComponent from '../components/tables/CustomTableComponent';

const AeNamOrderReport = ({ selectedId }) => {
    const logger = useMemo(() => new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, }), []);
    const [loader, setLoader] = useState(false);
    const [orderedData, setOrderedData] = useState([]);
    const [orderedDownloadData, setOrdereDownloadData] = useState([]);
    const [dateFrom, setDateFrom] = useState(new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().substring(0, 10));
    const [dateTo, setDateTo] = useState(new Date().toISOString().substring(0, 10));
    const [limit, setLimit] = useState(1000);  // Set default limit to 1000
    const [product, setProduct] = useState('all');
    const [activityType, setActivityType] = useState('all');
    const [reportOptionOpened, setReportOptionOpened] = useState(true);
    const [errorMSG, setErrorMSG] = useState('');
    const { custPon } = useParams();
    const [pageSize, setPageSize] = useState(25); // Default page size
    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    const columns = [
        {
            field: 'Company',
            headerName: 'Company',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'Product',
            headerName: 'Product',
            flex: 1,
            renderCell: (params) => quoteFormater(params),
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'PON',
            headerName: 'Customer PON',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'OrderName',
            headerName: 'Order Name',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'orderType',
            headerName: 'Order Type',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'Requested',
            headerName: 'Requested Date',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'Ordered',
            headerName: 'Ordered Date',
            flex: 1,
            sortingOrder: ['desc', 'asc']
        }
    ];

    function quoteFormater(params) {
        const row = params.row;
        let URL = row.Status === "Saved" ? `/Order/Create/${row.RefId}` : `/Order/View/${row.RefId}`;
        return (
            <>
                <NavLink className={style.quoteLink} to={URL} activeClassName="active">{row.Product}</NavLink>
                <div>
                    {row.filterList.map((i, e) =>
                        <span className={style.filterList} key={i}>{i}</span>
                    )}
                </div>
            </>
        );
    }

    const reportOption = () => {
        setReportOptionOpened(!reportOptionOpened);
    }

    const reloadSummary = () => {
        logger.info(`dateFrom: ${dateFrom}, dateTo: ${dateTo}, limit: ${limit}, product: ${product}, activityType: ${activityType}, custPon: ${custPon}, selectedId: ${selectedId}`);
        fetchOrderSummary(dateFrom, dateTo, limit, product, activityType, custPon, selectedId);
    }

    const fetchOrderSummary = useCallback((dateFrom, dateTo, limit, product, activityType, custPon, selectedId) => {
        setErrorMSG('');
        setLoader(true);
        setOrderedData([]);
        setOrdereDownloadData([]);
        if (custPon) { setReportOptionOpened(false); }
        ReportHelper.getAeNamReports('aeNamOrderSummary', selectedId, dateFrom, dateTo, limit, product, activityType).then((response) => {
            logger.info(`getOrderData response: ${JSON.stringify(response)}`);
            if (response?.data?.result === "SUCCESS") {
                setOrdereDownloadData(response?.data?.data);
                const dataWithDefaults = response.data.data.map(item => ({
                    ...item,
                    filterList: item.filterList || []  // Ensure filterList is always an array
                }));
                setOrderedData(dataWithDefaults);
            } else {
                logger.error('getOrderData Error: ' + response.data?.error);
                setErrorMSG(response.data?.error);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            logger.error('getOrderData Error: ' + error);
            setErrorMSG(error);
        });
    }, []);

    function handleActivityUpdate(event) {
        let activity_type = event.target.value;
        setActivityType(activity_type);
    }

    function handleLimitUpdate(ev) {
        let limit = ev.target.value;
        setLimit(limit);
    }

    function handleProductUpdate(ev) {
        let product = ev.target.value;
        setProduct(product);
    }

    const handleDateUpdate = (event) => {
        let id = event.target.id;
        let value = event.target.value;
        switch (id) {
            case "dateFrom":
                setDateFrom(value);
                break;
            case "dateTo":
                setDateTo(value);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (selectedId) {
            fetchOrderSummary(dateFrom, dateTo, limit, product, activityType, custPon, selectedId);
        }
    }, [selectedId]);

    return (
        <div className={`content ${style.contentQueryPage} `}>
            {loader ? <Loader /> : null}
            {errorMSG ? <p className={`text-center my-4 text-danger`}>{errorMSG}</p>
                :
                <>
                    <div className={style.filterWrap}>
                        <div>
                            <button className={`ico-button light-button`}>
                                <ExportXLSX
                                    label="Download Summary"
                                    csvData={orderedDownloadData}
                                    fileName={`Order_Summary_${(() => {
                                        const currentDate = new Date();
                                        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
                                        const day = String(currentDate.getDate()).padStart(2, '0');
                                        const year = currentDate.getFullYear();
                                        return `${month}_${day}_${year}`;
                                    })()}`}
                                />
                            </button>
                        </div>
                        <div className="expander" collapsed-id="icon-content">
                            <svg className="ico-link light-ico ico-add" onClick={reportOption}>
                                <path d="M24,48A24,24,0,1,1,48,24,24,24,0,0,1,24,48ZM24,1.89A22.1,22.1,0,1,0,46.08,24,22.09,22.09,0,0,0,24,1.89Zm1,32.69H23.05V25h-9.6V23.05h9.6v-9.6H25v9.6h9.61V25H25Z"></path>
                            </svg>
                        </div>
                    </div>
                    {reportOptionOpened ?
                        <div className={`row ${style.reportOptionWrap} `}>
                            <div className={` col-md-4 ${style.reportFilter} `}>
                                <h4>Results</h4>
                                <label className={style.labelText}>Date From</label>
                                <input type='date' min="2015-01-01" className={style.dateFld} id='dateFrom' name='dateFrom' onChange={handleDateUpdate} value={dateFrom}></input>
                                <label className={style.labelText}>Date To</label>
                                <input type='date' min="2015-01-01" className={style.dateFld} id='dateTo' name='dateTo' onChange={handleDateUpdate} value={dateTo}></input>
                            </div>
                            <div className={` col-md-4 ${style.reportFilter} `}>
                                <h4>Product(s)</h4>
                                <div className={style.paramsList}>
                                    <select className={`form-control  ${style.selectFld}`} name="product" value={product} onChange={handleProductUpdate}>
                                        <option value='all'>All Products</option>
                                        <option value='1'>Wave</option>
                                        <option value='10'>DIA POP</option>
                                        <option value='11'>DIA Prem</option>
                                        <option value='23'>Wholesale Broadband</option>
                                        <option value='6'>MPLS AGG Tail</option>
                                        <option value='13'>MPLS + Internet</option>
                                        <option value='7'>MPLS NNI</option>
                                        <option value='19'>E-Line</option>
                                        <option value='20'>E-Access</option>
                                        <option value='22'>E-Access NNI</option>
                                        <option value='15'>Colocation</option>
                                        <option value='29'>OfficeSuite White Label</option>
                                        <option value='32'>Dark Fiber</option>
                                    </select>
                                </div>
                                <h4>Limit</h4>
                                <div className={style.paramsList}>
                                    <select className={`form-control  ${style.selectFld}`} name="limit" value={limit} onChange={handleLimitUpdate}>
                                        <option value='100'>100 Orders</option>
                                        <option value='500'>500 Orders</option>
                                        <option value='1000'>1000 Orders</option>
                                        <option value='5000'>5000 Orders</option>
                                        <option value='all'>All Orders</option>
                                    </select>
                                </div>
                            </div>
                            <div className={` col-md-4 ${style.reportFilter} `}>
                                <h4>Activity Requested(s)</h4>
                                <div className={style.paramsList}>
                                    <select className={`form-control  ${style.selectFld}`} name="activity_type" value={activityType} onChange={handleActivityUpdate}>
                                        <option value='all'>All Activity Types</option>
                                        <option value='N'>New</option>
                                        <option value='A'>Add</option>
                                        <option value='C'>Change</option>
                                        <option value='D'>Disconnect</option>
                                        <option value='R'>Renewals</option>
                                    </select>
                                </div>
                                <div className={style.paramsList}>
                                    <button className={`light-button ${style.applyBtn}`} onClick={reloadSummary}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                        : null}
                    <div className="rel b mt-3"></div>
                    <div className="quoteSummaryTableWrap">
                        <div className="rel pagination-table page-list my-3">
                            <CustomTableComponent
                                rows={orderedData}
                                columns={columns}
                                pageSize={pageSize}
                                onPageSizeChange={(newSize) => setPageSize(newSize)}
                                filterModel={filterModel}
                                onFilterModelChange={(model) => setFilterModel(model)}
                                rowIdField="RefId"
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default AeNamOrderReport;