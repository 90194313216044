import React, { useState } from 'react';
import StripedDataGrid from './StripedDataGrid'; // Ensure this component supports sorting
import CustomTheme from './CustomTheme';
import TablePagination from './TablePagination';
import CustomToolbar from './CustomToolbar';

const CustomTableComponent = ({ rows, columns, pageSize, onPageSizeChange, filterModel, onFilterModelChange, rowIdField }) => {
    // Initialize sort model state
    const [sortModel, setSortModel] = useState([
        { field: columns[0]?.field || '', sort: 'asc' }
    ]);

    const handleSortModelChange = (newSortModel) => {
        console.log('Sort model changed:', newSortModel);
        setSortModel(newSortModel);
    };

    return (
        <StripedDataGrid
            autoHeight
            theme={CustomTheme}
            rows={rows}
            getRowId={(row) => row[rowIdField]}
            columns={columns}
            filterModel={filterModel}
            onFilterModelChange={onFilterModelChange}
            pageSize={pageSize}
            rowsPerPageOptions={[]}
            resultsPerPageOptions={[]}
            pageSizeOptions={[]}
            onPageSizeChange={onPageSizeChange}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 25
                    },
                },
                sorting: {
                    sortModel: sortModel
                },
            }}
            slots={{
                pagination: TablePagination,
                toolbar: CustomToolbar
            }}
        />
    );
};

export default CustomTableComponent;