import ViewportWarning from "../shared/ViewportWarning";
import { useEffect, useRef, useState } from "react";
import TabsEmployee from "./TabsEmployee";
import Profile from "./Profile";
import Accounts from "./Accounts";
import Products from "./Products";
import UserInfoSection from "../login/UserInfoSection";
import APIProfile from "./APIProfile";
import Nnis from "./Nnis";
import History from "./History";
import { ConsoleLogger } from '../../logger/ConsoleLogger';
import CompanyDropdown from "../shared/CompanyDropdown";
import { connect, useSelector } from 'react-redux';
import { profileChange } from "../../redux";

const Employee = (props) => {
	const logger = new ConsoleLogger({ level: process.env.REACT_APP_LOGGER_LEVEL, });
	const [userId, setUserId] = useState("");
	const profileChangeTabFromStore = useSelector(state => state.employeeProfile?.profile?.selectedProfileTab);
	const [newProfileCreatedFlag, setNewProfileCreatedFlag] = useState(false);
	const [accountsLoaded, setAccountsLoaded] = useState(false);
	const [productsLoaded, setProductsLoaded] = useState(false);
	const [nnisLoaded, setNNIsLoaded] = useState(false);
	const [apiLoaded, setAPILoaded] = useState(false);
	const [historyLoaded, setHistoryLoaded] = useState(false);
	const topOfPageRef = useRef(null);
	const changeTab = (e, userIdFromProfile) => {
		logger.info('changeTab function inside of Employee.js');
		logger.info(e)
		//tracking selected tab in redux store
		props.updateProfileSelectedTab(e);
		if (e === "EDIT_USER") {
			setUserId(userIdFromProfile);
			logger.info(`User ID to be update: ${userIdFromProfile}`);
		}
	}

	//Scroll to the top of the Profile page after a new profile is created
	useEffect(() => {
		topOfPageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
	}, [newProfileCreatedFlag]);

	return (
		<div className="midsection employee abs z1">
			<div className="rel">
				<ViewportWarning />
				<div className="wrap">
					<h1 className="page-title"> Customer Profile</h1>
					<div ref={topOfPageRef} />
					<CompanyDropdown area={"Profile"} tab={profileChangeTabFromStore}></CompanyDropdown>
					<div className="section">
						<TabsEmployee handleNav={(e) => changeTab(e)} tab={profileChangeTabFromStore} />						<div className="content">
							{profileChangeTabFromStore === "Profile"   ? <Profile handleNav={(e, userInfoRow) => changeTab(e, userInfoRow)} newProfileCreated={() => setNewProfileCreatedFlag(true)} /> : null}
							{profileChangeTabFromStore === "Accounts"  ? <Accounts /> : null}
							{profileChangeTabFromStore === "Products"  ? <Products /> : null}
							{profileChangeTabFromStore === "NNIs"      ? <Nnis /> : null}
							{profileChangeTabFromStore === "API"       ? <APIProfile /> : null}
							{profileChangeTabFromStore === "ADD_USER"  ? <UserInfoSection handleNav={(e) => changeTab(e)} /> : null}
							{profileChangeTabFromStore === "EDIT_USER" ? <UserInfoSection userIdFromProfile={userId} handleNav={(e) => changeTab(e)} /> : null}
							{profileChangeTabFromStore === "History"   ? <History /> : null}
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

const mapDispatchToProps = {
	updateProfileSelectedTab: profileChange
}
export default connect(null, mapDispatchToProps)(Employee);
