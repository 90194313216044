import React, {useEffect} from 'react';
import {ConsoleLogger} from "../../logger/ConsoleLogger";

const EmployeeLogin = (props) => {
    const logger = new ConsoleLogger({level: process.env.REACT_APP_LOGGER_LEVEL,});
    const employeeLoginAuthenticationURL = `${process.env.REACT_APP_LOGIN_URL}/employee.php`;
    const logoutAuthenticationURL = `${process.env.REACT_APP_LOGIN_URL}/login.php?action=logout`;

    useEffect(() => {
        logger.info("useEffect--EmployeeLogin.js");
        logger.info("loginAttempt: " + localStorage.getItem("loginAttempt"));
        localStorage.setItem("employeeLoginAttempt", "employee");
        logger.info("We've set employeeLoginAttempt");
		if (localStorage.getItem("isLoggedIn")) {
			logger.info("Inside if statement in EmployeeLogin.js where isLoggedIn is set and we redirect back to /");
            window.location = "/";
        } else if (localStorage.getItem("isEmployeeLoggedIn")){
            window.location = "/Employee/Home";
        } else {
            /*To prevent an aborted customer session from breaking employee login*/
			if (localStorage.getItem("loginAttempt") === "customer") {
				logger.info("Inside if statement in EmployeeLogin.js where we abort a customer session");
                localStorage.removeItem("loginAttempt");
                window.location.href = logoutAuthenticationURL;
			} else {
				logger.info("Inside else statement in EmployeeLogin.js");
                /*if we don't get a success from the employee.php we want to force an onclick before attempting again*/
                if(!localStorage.getItem("employeeLoginFailed")){
                    window.location.href = employeeLoginAuthenticationURL;
                }
            }

        }

    }, []);

    function handleOnClick() {
        logger.info("handleOnClick");
        if(localStorage.getItem("retry")){
            localStorage.removeItem("retry");
        }
        window.location.href = employeeLoginAuthenticationURL;
    }

    return (
        <>
        {localStorage.getItem("employeeLoginFailed") ?
            <div className="page login-page">
                <div className="page-title text-center my-5 rel z9">
                    <img alt="iconnect-logo" src="../../images/iconnect-logo-light-tag.png"/>
                </div>
                <div className="login-form rel z9">
                    <form>
                        <div className="my-3 text-center">
                            {localStorage.getItem("retry") ?
                            <>
                                <p className="text-danger text-center">Session has timed out.</p>
                                <p className="text-danger text-center">Please sign in again.</p>
                            </>    : null
                            }
                            <button type="button" className="login-btn" onClick={handleOnClick}>
                                <span>Sign In</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div> :  null
            }
        </>

    )
}

export default EmployeeLogin;
