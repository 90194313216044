import { useEffect } from 'react';
import '../../utils/CustomBotpress';
import { useSelector } from 'react-redux';
const Chatbot = () => {
	const jwt = useSelector(state => state.userReducer?.user?.jwt);

	useEffect(() => {
		const employeeName = localStorage.getItem("employeeName").replace(/\+/g, ' ');
		if(jwt){
			window.botpressWebChat.init({
				botName: "iConnect Digital Assistant",
				botId: "36205362-3409-48d9-9038-822f8d183f63",
				hostUrl: "https://cdn.botpress.cloud/webchat/v1",
				messagingUrl: "https://messaging.botpress.cloud",
				clientId: "36205362-3409-48d9-9038-822f8d183f63",
				stylesheet: "https://webchat-styler-css.botpress.app/prod/code/bf021ac1-4aca-41ef-9a8e-e492a058e9bc/v21354/style.css",
				avatarUrl: "https://files.bpcontent.cloud/2024/10/09/19/20241009191949-9TAT6EN0.png",
				showTimestamp: true,
				userData: {
					name: employeeName,
					jwt: jwt
				}
			})
		}
  }, [jwt])

  return <div id="webchat" />;
}

export default Chatbot
